import { Outlet } from 'react-router-dom'

import { useNoInternet } from '@shared/hooks'

import { LayoutForErrorTechnicalPages } from './components'

export function TechnicalLayout() {
  useNoInternet()

  return (
    <LayoutForErrorTechnicalPages>
      <Outlet />
    </LayoutForErrorTechnicalPages>
  )
}
