import { Paper, styled } from '@mui/material'

interface IProps {
  error?: boolean
}

export const StyledDragNDropAreaWrapTiny = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'error',
})<IProps>(({ theme, error }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  padding: '8px 16px',
  width: '100%',
  gap: 8,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    gap: 12,
    backgroundColor: error
      ? theme.palette.error.main
      : theme.palette.primary.dark,
    padding: '6px 20px',
    borderRadius: 10,
    border: '1px solid rgba(75, 116, 196, 0.5)',
  },
  [theme.breakpoints.up('lg')]: {
    width: '80%',
  },
  [theme.breakpoints.between(1248, 1360)]: {
    width: '100%',
  },
}))
