import { styled, Toolbar } from '@mui/material'

import { appBarHeight } from '@shared/constants'

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  justifyContent: 'space-between',
  height: appBarHeight.xs,
  minHeight: appBarHeight.xs,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.08)',
  backdropFilter: 'blur(14px)',
  gap: 8,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    height: appBarHeight.lg,
    minHeight: appBarHeight.lg,
    borderRadius: `0 0 ${theme.spacing(2)} ${theme.spacing(2)}`,
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.08)',
  },
}))
