import { ListItemIcon, styled } from '@mui/material'

export const StyledListItemIcon = styled(ListItemIcon)(() => ({
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
}))
