import { create } from 'zustand'
import {
  persist,
  createJSONStorage,
  devtools,
  StateStorage,
} from 'zustand/middleware'

import { deleteCookie, getCookie, setCookie } from '@shared/utils'

import { IPlacement } from '../types'

interface IProjectsSwitcherState {
  placement: IPlacement
  setPlacement: (payload: IPlacement) => void

  anchorEl: HTMLElement | null
  setAnchorEl: (payload: HTMLElement | null) => void
}

const cookiesStorage: StateStorage = {
  getItem: (name: string): string | null => {
    return getCookie(name) || null
  },
  setItem: (name: string, value: string): void => {
    setCookie(name, value, 365)
  },
  removeItem: (name: string): void => {
    deleteCookie(name)
  },
}

export const useProjectsSwitcherStore = create<IProjectsSwitcherState>()(
  persist(
    devtools((set) => ({
      placement: 'right',
      setPlacement: (placement) => set({ placement }),

      anchorEl: null,
      setAnchorEl: (anchorEl) => set({ anchorEl }),
    })),
    {
      name: 'projects-switcher-storage',
      storage: createJSONStorage(() => cookiesStorage),
    }
  )
)
