import { Typography, styled } from '@mui/material'

interface IProps {
  isActive?: boolean
}

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<IProps>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.common.black : theme.palette.text.secondary,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))
