export function displayTimerCount(time: number, isSecond: boolean = false) {
  const initialTime = isSecond ? time : Math.floor(time / 1000)

  let seconds = initialTime % 60
  const minutes = (initialTime - seconds) / 60

  if (seconds < 0) {
    seconds = 0
  }

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}
