import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const SendRoute: RouteObject = {
  path: routeNamesPaths.send,
  lazy: async () => {
    const { Send } = await import('./ui')

    return { Component: Send }
  },
}
