import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Autocomplete, Box, useTheme } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'

import { ICountry, countries } from '@shared/constants'
import { IInputProps } from '@shared/types'

import { optionsToGetCountryByNameOrAbrev } from '../../model'
import { TextFieldStyledLabel, StyledTextField } from '../../styled'

interface IProps extends IInputProps<unknown[]> {
  onChange?: (...event: unknown[]) => void
  variant?: 'filled' | 'outlined' | 'standard'
  value?: Array<ICountry['abbreviation']>
}

export const MultipleCountrySelectInput = forwardRef(function Named(
  props: IProps,
  ref
) {
  const {
    placeholder,
    error,
    helperText,
    label,
    onChange,
    sx,
    variant,
    name,
    disabled,
    fullWidth,
    value,
  } = props

  const theme = useTheme()

  const [autocompleteValues, setAutocompleteValues] = useState<
    ICountry[] | undefined
  >(countries.filter((item) => value?.includes(item.abbreviation)) || undefined)

  useEffect(() => {
    setAutocompleteValues(
      countries.filter((item) => value?.includes(item.abbreviation)) ||
        undefined
    )
  }, [value])

  return (
    <Box sx={sx}>
      <TextFieldStyledLabel className="label">{label}</TextFieldStyledLabel>

      <Autocomplete
        multiple
        disabled={disabled}
        fullWidth={fullWidth}
        defaultValue={undefined}
        value={autocompleteValues}
        clearOnBlur
        disablePortal
        filterOptions={optionsToGetCountryByNameOrAbrev}
        options={countries}
        getOptionLabel={(option) => option.name}
        popupIcon={!disabled && <ExpandMoreIcon sx={{ color: '#009EE0' }} />}
        onChange={(_, option) => {
          if (onChange) {
            onChange(option.map((item) => item.abbreviation))
          }
        }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant={variant}
            fullWidth={fullWidth}
            placeholder={placeholder}
            error={error}
            name={name}
            inputRef={ref}
            helperText={helperText || ''}
            sx={{
              '& .MuiChip-root': {
                color: theme.palette.primary.dark,
                borderRadius: 1,
                backgroundColor: theme.palette.common.white,
                '& .MuiChip-deleteIcon': {
                  fill: theme.palette.primary.dark,
                  ':hover': {
                    fill: theme.palette.primary.dark,
                  },
                },
              },
            }}
          />
        )}
      />
    </Box>
  )
})
