import { Avatar } from '@mui/material'

import { useVirtualAccountStore } from '@entities/virtual-account'

import { useAuthStore } from '@shared/auth'
import { stringToColor } from '@shared/utils'

export function UserAvatar() {
  const userInitials = useAuthStore((state) => state.getUserInitials)()
  const userFullName = useAuthStore((state) => state.getUserFullName)()
  const currentAccount = useVirtualAccountStore((state) => state.virtualAccount)

  return (
    <Avatar
      variant="rounded"
      sx={{
        position: currentAccount ? 'absolute' : 'initial',
        right: 0,
        backgroundColor: stringToColor(userFullName),
      }}
      alt="Remy Sharp"
    >
      {userInitials}
    </Avatar>
  )
}
