type TStage = 'dev' | 'stg' | 'ptv' | 'production'

export type THostService = 'workspace' | 'landing' | 'crypto' | 'idv'
export type TAuthHostService = 'workspace' | 'crypto' | 'idv'

export function configs() {
  const stages: TStage[] = ['dev', 'stg', 'ptv', 'production']

  const { host, protocol } = window.location
  const prefixHost = host.split('.')[0]
  const hostElements = host.split('.')

  const getHostService = (): THostService => {
    if (prefixHost === 'my' || prefixHost === 'banking') {
      return 'workspace'
    }

    if (prefixHost === 'crypto') {
      return 'crypto'
    }

    if (prefixHost === 'idv') {
      return 'idv'
    }

    return 'landing'
  }

  const getStage = (): 'dev' | 'loc' | 'prod' => {
    if (host.search('dev-px') > 0) {
      return 'dev'
    }

    if (host.search('loc') > 0 || process.env.NODE_ENV === 'development') {
      return 'loc'
    }

    return 'prod'
  }

  const currentStage = getStage()

  const getLandingHost = (): string => {
    const service = getHostService()

    if (service === 'landing') {
      return host
    }

    return host.replace(/^(my\.|crypto\.|idv\.|banking\.)/g, '')
  }

  const getWorkspaceHost = (): string => {
    const service = getHostService()

    if (service === 'workspace') {
      return host
    }

    return `banking.${host.replace(/(idv\.|crypto\.)/g, '')}`
  }

  const getCryptoHost = () => {
    const service = getHostService()

    if (service === 'crypto') {
      return host
    }

    return `crypto.${host.replace(/(my\.|banking\.|idv\.)/g, '')}`
  }

  const getIdvHost = () => {
    const service = getHostService()

    if (service === 'idv') {
      return host
    }

    return `idv.${host.replace(/(my\.|banking\.|crypto\.)/g, '')}`
  }

  const landingHost = getLandingHost()
  const workspaceHost = getCryptoHost()
  const cryptoHost = getCryptoHost()
  const idvHost = getIdvHost()

  const currentPath = `${protocol}//${host}`
  const pathToLanding = `${protocol}//${landingHost}`
  const pathToWorkspace = `${protocol}//${workspaceHost}`
  const pathToCrypto = `${protocol}//${cryptoHost}`
  const pathToIdv = `${protocol}//${idvHost}`
  const domainToCookies = `.${landingHost.replace(/:\d+$/, '')}`

  const getServiceFromURLSearchParams = (): TAuthHostService | undefined => {
    const urlParams = new URLSearchParams(window.location.search)
    const serviceParam = urlParams.get('service')

    switch (serviceParam) {
      case 'workspace':
      case 'crypto':
      case 'idv':
        return serviceParam
      default:
        return
    }
  }

  return {
    host,
    hostElements,
    workspaceHost,
    landingHost,
    idvHost,
    protocol,
    prefixHost,
    stages,
    currentStage,
    currentPath,
    pathToLanding,
    pathToWorkspace,
    pathToCrypto,
    pathToIdv,
    getHostService,
    getLandingHost,
    getWorkspaceHost,
    getCryptoHost,
    getIdvHost,
    getStage,
    getServiceFromURLSearchParams,
    domainToCookies,
  }
}
