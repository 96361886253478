import { Box } from '@mui/material'

import {
  useVirtualAccountList,
  useVirtualAccountStore,
} from '@entities/virtual-account'

import { tabIndexKeyUpCallback } from '@shared/utils'

import { AmountAndType } from './amount-and-type'
import { ChooseAccountButton, CustomCheckBox } from './styled'

export function AccountChooser() {
  const { data: virtualAccounts } = useVirtualAccountList()
  const virtualAccount = useVirtualAccountStore((state) => state.virtualAccount)
  const setVirtualAccount = useVirtualAccountStore((s) => s.setVirtualAccount)

  return (
    <Box component="ul">
      {virtualAccounts?.map((va) => (
        <ChooseAccountButton
          variant="outlined"
          component="li"
          disableFocusRipple
          key={va.id}
          onClick={() => setVirtualAccount(va)}
          fullWidth
          onKeyUp={tabIndexKeyUpCallback(() => setVirtualAccount(va))}
        >
          <AmountAndType type={va?.type} amount={va?.balance} />
          <CustomCheckBox selected={virtualAccount?.id === va.id} />
        </ChooseAccountButton>
      ))}
    </Box>
  )
}
