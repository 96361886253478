import { styled } from '@mui/material'

export const ListHeader = styled('div')(() => ({
  fontFamily: 'Simplon Norm Trial',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '17px',

  display: 'flex',
  alignItems: 'center',
  fontFeatureSettings: "'zero' on",

  /* Text/Light grey */
  color: '#717070',
  paddingTop: 10,
  paddingBottom: 10,
}))
