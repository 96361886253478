import { SxProps, Typography } from '@mui/material'
import { memo } from 'react'

import { useVirtualAccountStore } from '@entities/virtual-account'

import { firstElementUp } from '@shared/utils'

interface IProps {
  sx?: SxProps
}

export const AccountName = memo(function AccountName({ sx }: IProps) {
  const getAccountName = useVirtualAccountStore((state) => state.getAccountName)

  const accountName = getAccountName()
    .toLowerCase()
    .split(' ')
    .map((name) => firstElementUp(name))
    .join(' ')

  return (
    <Typography variant="h4" sx={sx}>
      {accountName}
    </Typography>
  )
})
