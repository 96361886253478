import { styled } from '@mui/material'

export const Title = styled('p')(({ theme }) => ({
  fontSize: 28,
  fontWeight: 500,
  lineHeight: '120%',
  margin: 0,
  [theme.breakpoints.up('md')]: {
    letterSpacing: '-0.84px',
  },
}))
