import { useNavigate } from 'react-router-dom'

import { IS_DEV_MODE, routeNamesPaths } from '@shared/constants'

import { errorHandler } from './error-handler'

type TErrorLevel = 'low' | 'medium' | 'high'

interface ICustomError {
  level?: TErrorLevel
  message: string | string[]
}

/**
 * This hook works under the Router provider.
 */

export function useErrorHandler() {
  const navigate = useNavigate()

  const redirectToErrorPage = () => {
    navigate(routeNamesPaths.errorPage)
  }

  const handleError = (error: ICustomError) => {
    if (IS_DEV_MODE) {
      // eslint-disable-next-line no-console
      console.error(error)
    }

    const { message, level } = error

    switch (level) {
      case 'high':
        // Обработка ошибки высокого уровня
        redirectToErrorPage()

        break
      case 'medium':
        // Обработка ошибки среднего уровня
        // Вывод сообщения об ошибке тостом и отправка ошибки на сервер
        errorHandler(message)

        break
      default:
        // Обработка ошибки низкого уровня
        // Только отправка ошибки на сервер
        errorHandler(message, false)

        break
    }
  }

  return handleError
}
