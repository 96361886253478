import { styled } from '@mui/material'

export const ProgressBarScrollButtonWrapper = styled('div')<{
  direction: 'left' | 'right'
}>(({ direction, theme }) => ({
  width: 52,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: direction === 'left' ? 'calc(-52px / 2)' : 'unset',
  right: direction === 'right' ? 'calc(-52px / 2)' : 'unset',
  zIndex: 1,
  borderRadius: 4,

  '& button': {
    p: '8px',
    border: `2px solid ${theme.palette.primary.dark}`,
    backgroundColor: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,

      '& svg path': {
        fill: theme.palette.common.white,
      },
    },
  },
}))
