import { AccordionSummary, styled } from '@mui/material'

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  margin: 0 + '!important',
  minHeight: 'initial',
  justifyContent: 'flex-start',
  '&.Mui-expanded': {
    minHeight: 'initial',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0 + '!important',
    alignItems: 'center',
    flexGrow: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.text.primary,
    transition: 'initial',
  },
}))
