import { CircularProgress, styled } from '@mui/material'

import { grey } from '@shared/constants'

export const StyledSecondaryProgress = styled(CircularProgress)<{
  disabled?: boolean
}>(({ theme, disabled }) => ({
  color: grey[50],
  backgroundColor: disabled ? grey[50] : 'transparent',
  borderRadius: '100%',
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.standard,
  }),
}))
