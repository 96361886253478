import { Stack, styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  warningTrigger?: boolean
  component?: React.ElementType
}

export const StyledMobileListItemWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'warningTrigger',
})<IProps>(({ theme, warningTrigger }) => ({
  padding: '8px 12px',

  borderRadius: '10px',
  border: '1px solid',
  borderColor: warningTrigger ? theme.palette.error.main : grey[100],

  ...(warningTrigger && {
    backgroundColor: theme.palette.error.light,
  }),
}))
