import { colors, styled } from '@mui/material'

export const DialogWrapper = styled('div')(({ theme }) => ({
  borderRadius: 16,
  flexShrink: 0,
  backgroundColor: colors.common.white,
  paddingTop: 44,
  paddingBottom: 40,
  paddingLeft: 40,
  paddingRight: 40,
  position: 'relative',
  width: '90%',
  [theme.breakpoints.up('sm')]: {
    width: 560,
  },
}))
