import { styled } from '@mui/material'

export const BuySellAbsoluteWrapper = styled('div')(() => ({
  width: 'calc(100% + 4px)',
  height: 'calc(100% + 4px)',

  borderRadius: '10px',

  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))
