// import PersonIcon from '@mui/icons-material/Person'
import { Typography } from '@mui/material'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { useVirtualAccountStore } from '@entities/virtual-account'

import { ReactComponent as PersonIcon } from './assets/person-icon.svg'
import { StyledUserProfileButton } from './styled'
import { UserAvatar } from './user-avatar'

interface IAccountProfileButtonProps {
  id: string
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
}

export function AccountProfileButton({
  id,
  onClick,
}: IAccountProfileButtonProps) {
  const { t } = useTranslation('features')
  const currentAccount = useVirtualAccountStore((state) => state.virtualAccount)

  return (
    <StyledUserProfileButton
      id={id}
      onClick={onClick}
      show={!!currentAccount}
      disableFocusRipple
    >
      {currentAccount && (
        <>
          <PersonIcon />

          <Typography variant="body3" flex={1} ml={1}>
            {t(`app-bar.${currentAccount.type}`)}
          </Typography>
        </>
      )}

      <UserAvatar />
    </StyledUserProfileButton>
  )
}
