import { styled } from '@mui/material'

export const StyledIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    width: 52,
    height: 52,
  },

  [theme.breakpoints.up('md')]: {
    '& svg': {
      width: 100,
      height: 100,
    },
  },
}))
