import { styled } from '@mui/material'

export const StyledUploadTinyWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 110,
  margin: '28px 0',

  [theme.breakpoints.up('md')]: {
    height: 'min-content',
    margin: '32px 0 38px 0',
  },

  [theme.breakpoints.up('lg')]: {
    minHeight: 142,
  },
  '& .MuiStack-root.error_message': {
    paddingLeft: '8px',
  },
}))
