import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import {
  businessTypeCategories,
  IBusinessTypeCategory,
} from '@shared/constants'

interface IProps {
  selectedItems: IBusinessTypeCategory[]
  addItem: (item: IBusinessTypeCategory) => void
  removeItem: (index: number) => void
  input: string
}

const selectableItems = businessTypeCategories.records.filter((item) => {
  return item.selectable
})

export function BusinessActivitiesList({
  selectedItems,
  addItem,
  removeItem,
  input,
}: IProps) {
  const parentCategories = businessTypeCategories.records.filter(
    (item) => item.parentId === undefined && !item.selectable
  )

  const addOrRemoveItem = (item: IBusinessTypeCategory) => () => {
    const index = selectedItems.findIndex(
      (selectedItem) => selectedItem.id === item.id
    )

    if (index === -1) {
      addItem(item)
    } else {
      removeItem(index)
    }
  }

  const CategoriesByParentId = ({
    parentId,
  }: {
    parentId: number
  }): React.ReactNode | undefined => {
    return businessTypeCategories.records.map((item, index) => {
      if (item.parentId === parentId && !item.selectable) {
        return (
          <TreeItem
            key={index}
            nodeId={item.id.toString()}
            label={item.code + ' ' + item.name}
          >
            <CategoriesByParentId parentId={item.id} />
          </TreeItem>
        )
      } else if (item.parentId === parentId && item.selectable) {
        return (
          <TreeItem
            key={index}
            nodeId={item.id.toString()}
            label={
              <FormGroup key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !!selectedItems?.find(
                          (selectedItem) => selectedItem.id === item.id
                        )
                      }
                      onChange={addOrRemoveItem(item)}
                      sx={{
                        background: 'transparent',
                        '&:hover': { background: 'transparent' },
                      }}
                    />
                  }
                  label={item.code + ' ' + item.name}
                />
              </FormGroup>
            }
          />
        )
      }
    })
  }

  const WithoutInput = () => {
    return (
      <TreeView
        disableSelection
        aria-label="categories"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {parentCategories.map((item, index) => (
          <TreeItem
            key={index}
            nodeId={item.id.toString()}
            label={item.code + ' ' + item.name}
          >
            <CategoriesByParentId parentId={item.id} />
          </TreeItem>
        ))}
      </TreeView>
    )
  }

  const WithInput = () => {
    const [filteredList, setFilteredList] = useState<IBusinessTypeCategory[]>(
      []
    )

    const filterList = useCallback(
      // TODO not working debounce
      debounce((input) => {
        if (input.length >= 3) {
          const re = new RegExp(input.toLowerCase())

          const list = selectableItems.filter((item) => {
            return re.test(item.name.toLowerCase())
          })

          setFilteredList(list)
        }
      }, 0),
      []
    )

    useEffect(() => {
      filterList(input)
    }, [input])

    return (
      <Box>
        {filteredList.map((item, index) => (
          <FormGroup key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    !!selectedItems?.find(
                      (selectedItem) => selectedItem.id === item.id
                    )
                  }
                  onChange={addOrRemoveItem(item)}
                />
              }
              label={item.code + ' ' + item.name}
            />
          </FormGroup>
        ))}
      </Box>
    )
  }

  return <Box>{input.length < 3 ? <WithoutInput /> : <WithInput />}</Box>
}
