import { styled } from '@mui/material'

import { deepBlue } from '@shared/constants'

interface IProps {
  isReview?: boolean
  active?: boolean
}

export const WalletCardPaper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isReview' && prop !== 'active',
})<IProps>(({ theme, active }) => ({
  cursor: 'pointer',
  width: 230,
  height: '100%',
  minHeight: 60,

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  padding: 14,
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: 12,
  background: theme.palette.common.white,
  ...(active && {
    border: `2px solid ${theme.palette.primary.main}`,
  }),

  '&:hover': {
    background: active ? 'transparent' : deepBlue[50],
  },

  [theme.breakpoints.up('md')]: {
    width: 336,
    minHeight: 110,
    gap: 15,
    padding: 24,
  },
}))
