export const orange = {
  50: '#FFEDE5',
  100: '#FFDBCC',
  200: '#FFB899',
  300: '#FF9466',
  400: '#FF783E',
  500: '#FF7033',
  600: '#CC3D00',
  700: '#992E00',
  800: '#661F00',
  900: '#330F00',
  950: '#1A0800',
}
