import { Box, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  StyledPercentageTypography,
  StyledTypographyTextContainer,
  StyledLinearProgress,
} from './styled'

interface IProps {
  stepCount: number
  currentStep: number
  title?: string
  withPercentage?: boolean
  value?: number
  stepsText?: string
  sx?: SxProps
}

export function CustomLinearProgress({
  stepCount,
  currentStep,
  title,
  withPercentage,
  value,
  stepsText,
  sx,
}: IProps) {
  const { t } = useTranslation('pages')

  return (
    <Box sx={sx}>
      <StyledTypographyTextContainer>
        <>{title}&nbsp;</>

        {withPercentage ? (
          <StyledPercentageTypography>
            {`${((currentStep * 100) / stepCount).toFixed(2)}%`}
          </StyledPercentageTypography>
        ) : (
          <Box
            component={'span'}
            className="steps_counter"
            color={'#22262B'}
            fontSize={'16px'}
            fontWeight={'400'}
          >
            {stepsText ||
              t('personal account application.step of', {
                stepCount,
                step: currentStep,
              })}
          </Box>
        )}
      </StyledTypographyTextContainer>

      <StyledLinearProgress
        variant="determinate"
        value={value || (currentStep * 100) / stepCount}
      />
    </Box>
  )
}
