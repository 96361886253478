import { styled, TableCell } from '@mui/material'

export const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.body3,
  lineHeight: `${theme.typography.body3.lineHeight} !important`,
  paddingTop: theme.spacing(1.25),
  borderBottomColor: theme.palette.common.white,

  '& .small': {
    ...theme.typography.subtitle5,
    lineHeight: `${theme.typography.body15.lineHeight} !important`,
    color: theme.palette.common.black,
  },
}))
