import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Skeleton, Stack, Typography } from '@mui/material'
import { ForwardedRef, forwardRef } from 'react'

import { TCryptoCoinKey } from '@shared/constants'
import { CryptoCoinIcon } from '@shared/ui'
import { tabIndexKeyUpCallback } from '@shared/utils'

import { WalletTokenItemBox } from '../styled'

interface IProps {
  open?: boolean
  onClick?: () => void
  coinName?: TCryptoCoinKey
  type?: string
  balance?: string
  loading?: boolean
  isItem?: boolean
  error?: boolean
}

function WalletForExchangeItemWithoutForward(
  { open, onClick, coinName, type, balance, loading, isItem, error }: IProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const getViewBalance = (balance?: string, coinName?: TCryptoCoinKey) => {
    return `${balance} ${coinName}`
  }

  return (
    <WalletTokenItemBox
      ref={ref}
      open={open}
      error={error}
      isItem={isItem}
      onClick={onClick}
      tabIndex={0}
      onKeyUp={tabIndexKeyUpCallback(onClick)}
    >
      {loading ? (
        <Stack direction={'row'} gap={1}>
          <Skeleton variant={'circular'} width={36} height={36} />

          <Stack gap={0.5}>
            <Skeleton variant={'text'} width={100} height={18} />
            <Skeleton variant={'text'} width={180} height={14} />
          </Stack>
        </Stack>
      ) : (
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <CryptoCoinIcon
            name={coinName}
            sx={{ '& svg': { width: 36, height: 36 } }}
            error={error}
          />

          <Stack gap={0.5}>
            <Typography
              fontSize={15}
              fontWeight={500}
              lineHeight={1}
              color={error ? 'error' : 'text.primary'}
            >
              {type}
            </Typography>

            <Typography
              fontSize={14}
              color={error ? 'error' : 'text.secondary'}
              lineHeight={1}
            >
              {getViewBalance(balance || '0', coinName)}
            </Typography>
          </Stack>
        </Stack>
      )}

      {!isItem && (
        <ExpandMoreIcon
          sx={{
            color: error ? 'error.main' : 'common.dark',
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      )}
    </WalletTokenItemBox>
  )
}

export const WalletTokenItem = forwardRef(WalletForExchangeItemWithoutForward)
