import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useButtonsConfig } from '@widgets/page-tab-switcher'

import { grey, routeNamesPaths } from '@shared/constants'
import { TGeneralTemplateType } from '@shared/types'

import {
  CustomButton,
  StyledDrawer,
  StyledListItemButton,
  StyledListItemIcon,
  StyledStack,
} from '../styled'

interface IProps {
  template: TGeneralTemplateType
}

export function MobileView({ template }: IProps) {
  const { pathname } = useLocation()
  const { t } = useTranslation('widgets')
  const theme = useTheme()

  const { firstArrayButtons, checkActivePath, moreButton, moreArrayButtons } =
    useButtonsConfig({ template })

  const [showDrawer, setShowDrawer] = useState(false)

  const handleClickMore = () => {
    setShowDrawer(true)
  }

  const isMoreBtnActive: boolean = useMemo(
    () =>
      pathname === routeNamesPaths.exchangeHistory ||
      pathname === routeNamesPaths.transactions,
    [pathname]
  )

  const customButtonSx = (): SxProps => ({
    width: {
      xs: template === 'virtualAccount' ? 100 : 'initial',
      sm: 100,
    },
  })

  return (
    <StyledStack justifyContent={{ sm: 'flex-start' }}>
      {firstArrayButtons.map(([key, data]) => (
        <CustomButton
          key={key}
          startIcon={data.icon}
          active={checkActivePath(data.href)}
          href={data.href}
          disableRipple
          sx={customButtonSx()}
        >
          {t(`page-tab-switcher.${key}` as never)}
        </CustomButton>
      ))}

      {moreArrayButtons.length > 0 && (
        <>
          <CustomButton
            active={isMoreBtnActive}
            last
            startIcon={moreButton.icon}
            onClick={handleClickMore}
            disableRipple
            sx={customButtonSx()}
          >
            {t(`page-tab-switcher.more`)}
          </CustomButton>

          <StyledDrawer
            anchor="bottom"
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              mb={2}
            >
              <Typography variant="subtitle3" px={2}>
                {t(`page-tab-switcher.more`)}
              </Typography>

              <IconButton
                onClick={() => setShowDrawer(false)}
                sx={{ background: grey[50] }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Stack>

            <List
              disablePadding
              sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}
            >
              {moreArrayButtons.map(([key, data]) => (
                <ListItem disablePadding key={key}>
                  <StyledListItemButton
                    selected={checkActivePath(data.href)}
                    onClick={() => setShowDrawer(false)}
                    href={data.href}
                  >
                    <StyledListItemIcon
                      sx={{
                        backgroundColor: checkActivePath(data.href)
                          ? theme.palette.common.white + '28'
                          : grey[50],
                      }}
                    >
                      {data.icon}
                    </StyledListItemIcon>

                    <ListItemText
                      className={
                        checkActivePath(data.href)
                          ? 'active-more-modals-item'
                          : ''
                      }
                      primary={t(`page-tab-switcher.${key}` as never)}
                    />
                  </StyledListItemButton>
                </ListItem>
              ))}
            </List>
          </StyledDrawer>
        </>
      )}
    </StyledStack>
  )
}
