import { styled, Typography } from '@mui/material'

interface IStyledTypography {
  breakWord?: boolean
  isCopyed?: boolean
}

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'breakWord' && prop != 'isCopyed',
})<IStyledTypography>(({ theme, breakWord, isCopyed }) => ({
  textAlign: 'start',
  maxWidth: !breakWord && isCopyed ? '40vw' : '65vw',
  whiteSpace: !breakWord ? 'nowrap' : 'wrap',
  overflow: !breakWord ? 'hidden' : 'normal',
  textOverflow: !breakWord ? 'ellipsis' : 'none',
  wordWrap: breakWord ? 'break-word' : 'normal',
  [theme.breakpoints.up('md')]: {
    maxWidth: 400,
  },
}))
