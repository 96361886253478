import { IconButton, styled } from '@mui/material'

import { grey } from '@shared/constants'

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 16,
  top: 20,
  background: grey[50],
  width: 32,
  height: 32,
  padding: '10px',

  '& svg': {
    opacity: 0.7,
    [theme.breakpoints.up('md')]: {
      opacity: 1,
    },
  },

  [theme.breakpoints.up('md')]: {
    width: 36,
    height: 36,
    padding: '8px',
    right: 32,
    top: 32,
    background: theme.palette.common.white,
  },
}))
