import { styled } from '@mui/material'

interface IProps {
  spacing?: boolean
}

export const StyledCookieWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'spacing',
})<IProps>(({ spacing, theme }) => ({
  position: spacing ? 'unset' : 'fixed',
  zIndex: spacing ? 10 : 20,
  bottom: 0,
  left: 0,
  width: '100%',
  background: theme.palette.common.white,
  boxShadow: '0px -4px 7px 1px #d8d8d8',
}))
