export const DocumentTypesList = [
  'ID',
  'PASSPORT',
  'PROOF_OF_ADDRESS',
  'BANK_STATEMENT_RIB',
  'BUSINESS_DESCRIPTION',
  'CERTIFICATE_OF_INCORPORATION',
  'MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION',
  'CERTIFICATE_OF_GOOD_STANDING',
  'POWER_OF_ATTORNEY',
  'DECLARATION_OF_TRUST',
  'BANK_REFERENCE_LETTER',
  'BANK_STATEMENT',
  'OTHER',
  'LEGAL_DOCS',
]

export enum DocumentTypes {
  ID = 'ID',
  PASSPORT = 'PASSPORT',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  BANK_STATEMENT_RIB = 'BANK_STATEMENT_RIB',
  BUSINESS_DESCRIPTION = 'BUSINESS_DESCRIPTION',
  CERTIFICATE_OF_INCORPORATION = 'CERTIFICATE_OF_INCORPORATION',
  MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION = 'MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION',
  CERTIFICATE_OF_GOOD_STANDING = 'CERTIFICATE_OF_GOOD_STANDING',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  DECLARATION_OF_TRUST = 'DECLARATION_OF_TRUST',
  BANK_REFERENCE_LETTER = 'BANK_REFERENCE_LETTER',
  BANK_STATEMENT = 'BANK_STATEMENT',
  OTHER = 'OTHER',
  LEGAL_DOCS = 'LEGAL_DOCS',
}

export type TDocumentType =
  | DocumentTypes.ID
  | DocumentTypes.PASSPORT
  | DocumentTypes.PROOF_OF_ADDRESS
  | DocumentTypes.BANK_STATEMENT_RIB
  | DocumentTypes.BUSINESS_DESCRIPTION
  | DocumentTypes.CERTIFICATE_OF_INCORPORATION
  | DocumentTypes.MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION
  | DocumentTypes.CERTIFICATE_OF_GOOD_STANDING
  | DocumentTypes.POWER_OF_ATTORNEY
  | DocumentTypes.DECLARATION_OF_TRUST
  | DocumentTypes.BANK_REFERENCE_LETTER
  | DocumentTypes.BANK_STATEMENT
  | DocumentTypes.OTHER
  | DocumentTypes.LEGAL_DOCS

export type TDocumentTypeSimple = 'ID' | 'PASSPORT'
