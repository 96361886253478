import { Container, styled } from '@mui/material'

export const StyledErrorTechnicalContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
