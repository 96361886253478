import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { externalLinks } from '@shared/constants'

import { useWelcomeDialogStore } from '../model'

export function WelcomeDialog() {
  const { open, closeHandler } = useWelcomeDialogStore()
  const { t } = useTranslation('features')

  function handleClose() {
    closeHandler()
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          px: 5,
          py: 6.5,
          maxWidth: 642,
          borderRadius: 2,
          position: 'relative',
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{ position: 'absolute', right: 29, top: 29 }}
      >
        <CloseIcon sx={{ color: '#212529' }} />
      </IconButton>

      <DialogTitle
        fontSize={{ xs: 28, md: 28 }}
        fontWeight={{ xs: 600 }}
        textAlign="center"
        sx={{ px: 0, pt: 0, pb: 2.5 }}
      >
        {t('welcome dialog.title')}
      </DialogTitle>

      <DialogContent sx={{ pb: 5 }}>
        <Typography
          fontSize={{ xs: 20, md: 20 }}
          fontWeight={{ xs: 400 }}
          textAlign="center"
        >
          {t('welcome dialog.description')}
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 3,
        }}
      >
        <Button
          href={externalLinks.idv}
          fullWidth
          sx={{
            fontSize: 16,
            fontWeight: 600,
            py: 2,
            lineHeight: '16px',
            textAlign: 'center',
          }}
          onClick={handleClose}
          variant="contained"
          size="large"
        >
          {t('welcome dialog.action button')}
        </Button>

        <Button
          fullWidth
          sx={{ fontSize: 16, fontWeight: 600, py: 2, lineHeight: '16px' }}
          variant="outlined"
          onClick={handleClose}
          size="large"
        >
          {t('welcome dialog.close button')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
