import { Box, Skeleton } from '@mui/material'

import { WrapperForSecondPartWalletFormPage } from '@shared/ui'

import { IModalProps } from '../../model'

import { FooterBtns } from './footer-btns'
import { OperationInfoList } from './operation-info-list'
import {
  OperationInfoWrapper,
  StyledBoxContentTitle,
  SuccessMessageWrapper,
} from './styled'

export function ModalContent({
  onConfirm,
  onClose,
  title,
  attentionMessage,
  operationInfo,
  successMessage,
  beforeContent,
  afterContent,
  singleBtn = false,
  singleBtnCallback,
  disabled = false,
  loading = false,
  hideActionButtons,
  content,
  singleBtnText,
  singleBtnVariant,
  transactionDetailsLoading,
  confirmBtnText,
  cancelBtnText,
}: Omit<IModalProps, 'sx' | 'open'>) {
  return (
    <>
      {title && (
        <StyledBoxContentTitle className={'title'} success={!!beforeContent}>
          <Box width={{ xs: '80%', md: 'initial' }}>{title}</Box>
        </StyledBoxContentTitle>
      )}

      {beforeContent && (
        <Box className={'beforeContent'} sx={{ mb: 2 }}>
          {beforeContent}
        </Box>
      )}

      {content}

      {operationInfo && (
        <OperationInfoWrapper
          spacing={{ xs: 0.25, md: 1 }}
          className="operation-info"
        >
          {!transactionDetailsLoading ? (
            <OperationInfoList operationInfo={operationInfo} />
          ) : (
            <Skeleton height={64} sx={{ transform: 'initial' }} />
          )}
        </OperationInfoWrapper>
      )}

      {attentionMessage && (
        <WrapperForSecondPartWalletFormPage
          className="attention-message"
          sx={{ mb: 4.5, py: { md: 4 } }}
        >
          {attentionMessage}
        </WrapperForSecondPartWalletFormPage>
      )}

      {successMessage && (
        <SuccessMessageWrapper className="success-message">
          {successMessage}
        </SuccessMessageWrapper>
      )}

      {afterContent && (
        <Box className={'afterContent'} sx={{ mb: 3.5 }}>
          {afterContent}
        </Box>
      )}

      {!hideActionButtons && (
        <FooterBtns
          onClose={onClose}
          loading={loading}
          disabled={disabled}
          onConfirm={onConfirm}
          singleBtn={singleBtn}
          singleBtnCallback={singleBtnCallback}
          singleBtnText={singleBtnText}
          singleBtnVariant={singleBtnVariant}
          confirmBtnText={confirmBtnText}
          cancelBtnText={cancelBtnText}
        />
      )}
    </>
  )
}
