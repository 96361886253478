import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SxProps } from '@mui/material'

import { HelpCenterContent, TitleWithIcon } from './components'
import {
  StyledAccordion,
  StyledAccordionSummary,
  titleWithIconSx,
  StyledStyledAccordionDetails,
} from './styled'

interface IProps {
  sx?: SxProps
}

export function HelpCenterAccordion({ sx }: IProps) {
  return (
    <StyledAccordion sx={sx}>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="help-center-content"
        id="help-center-header"
      >
        <TitleWithIcon sx={titleWithIconSx} />
      </StyledAccordionSummary>

      <StyledStyledAccordionDetails>
        <HelpCenterContent isAccordion />
      </StyledStyledAccordionDetails>
    </StyledAccordion>
  )
}
