import { Grid } from '@mui/material'

import { TBlockchain } from '@shared/types'
import { Map } from '@shared/ui'

import { ICoinsList } from './choose-blockchain'
import { ChooseButton } from './choose-button'

interface IProps {
  coinsList: ICoinsList[]
  activeBlockchain: TBlockchain | null
  setActiveBlockchain: (value: TBlockchain | null) => void
}

export function MobileBlockchainList({
  coinsList,
  activeBlockchain,
  setActiveBlockchain,
}: IProps) {
  return (
    <Grid container rowSpacing={1.5} columnSpacing={2}>
      <Map
        collection={coinsList}
        iterate={(item) => (
          <Grid item xs={6}>
            <ChooseButton
              icon={item.icon}
              text={item.name}
              active={activeBlockchain === item.key}
              onClick={() => setActiveBlockchain(item.key)}
              disabled={item.disabled}
              selectType={'coin'}
              sx={{ height: 52, ...item.sx }}
            />
          </Grid>
        )}
      />
    </Grid>
  )
}
