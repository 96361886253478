import { Stack, styled } from '@mui/material'

export const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: '#E8F1FF',
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(5),
    borderRadius: theme.spacing(1),
  },
}))
