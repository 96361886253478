import { styled } from '@mui/material'

import { mobileTitlePb } from '../../../model'

interface IStyledTypography {
  success?: boolean
}

export const StyledBoxContentTitle = styled('div', {
  shouldForwardProp: (prop) => prop != 'success',
})<IStyledTypography>(({ theme, success }) => ({
  marginBottom: success ? 12 : 16,
  paddingBottom: mobileTitlePb,
  borderBottom: `1px solid ${theme.palette.text.secondary}`,
  [theme.breakpoints.up('md')]: {
    marginBottom: success ? 28 : 24,
    paddingBottom: 0,
    borderBottom: 'none',
  },
}))
