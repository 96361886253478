import { keyframes, Box } from '@mui/material'

import Rocket from '../assets/rocket.svg'

const RocketAnimation = keyframes`
    50% {
      margin-top: 50px;
    }
  `

export const RocketBox = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Box
        component="img"
        src={Rocket}
        alt="Rocket"
        sx={{
          marginTop: '-50px',
          animation: `${RocketAnimation} 4s infinite ease`,
          width: 124,
        }}
      />
    </Box>
  )
}
