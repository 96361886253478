import { styled } from '@mui/material'

export const StyledBox = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  border: '1px solid rgba(34, 38, 43, 0.4)',
  borderRadius: 8,
  fontSize: 18,
  paddingTop: 4,
  paddingBottom: 4,
  paddingLeft: 24,
  paddingRight: 24,
  marginBottom: 16,
}))
