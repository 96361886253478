import { styled } from '@mui/material'

export const CustomSplideWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  background: theme.palette.common.white,

  '& .splide__track': {
    paddingTop: '8px',

    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
    },
  },

  '& .splide__list': {
    paddingBottom: theme.spacing(0.5) + '!important',
    paddingLeft: '16px !important',
    marginRight: '26px !important',

    [theme.breakpoints.up('md')]: {
      paddingLeft: '0 !important',
    },
  },

  '& .splide__pagination': {
    position: 'relative',
    bottom: 0,

    '& .splide__pagination__page': {
      opacity: 'initial',
      transition: theme.transitions.create('width'),
      height: 5,
      width: 5,
      '&.is-active': {
        width: 24,
        background: theme.palette.primary.dark,
        borderRadius: 100,
        transform: 'initial',
      },
    },
  },

  '& .click-slide:focus-visible': {
    borderRadius: 12,
  },
}))
