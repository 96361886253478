import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TAccountType } from '@shared/types'
import { formatEurNumber } from '@shared/utils'

interface IProps {
  amount?: string | number
  type?: TAccountType
  onClick?: () => void
}

export function AmountAndType({ amount, type, onClick }: IProps) {
  const { t } = useTranslation('features')

  return (
    <Stack onClick={onClick} alignItems="flex-start">
      {amount !== undefined && (
        <Typography variant="body11" color={'primary.dark'}>
          {`${formatEurNumber(amount)} EUR`}
        </Typography>
      )}

      {type && (
        <Typography variant="body17" color={'common.black'}>
          {t(`app-bar.${type}`).toUpperCase()}
        </Typography>
      )}
    </Stack>
  )
}
