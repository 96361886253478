import { styled } from '@mui/material'

export const StyledSpacingElement = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  background: theme.palette.common.white,
  zIndex: 12,
  position: 'absolute',
  top: 0,
  left: 0,
}))
