export const formatEurNumber = (value?: string | number) => {
  if (value === undefined) {
    return ''
  }

  const correctAmount = Number(value)
  const correctAmountString = correctAmount.toFixed(2)

  return parseFloat(correctAmountString).toLocaleString('sk-SK')
}
