import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const BusinessAccountApplicationRoutes: RouteObject[] = [
  {
    lazy: async () => {
      const { BusinessAccountApplicationLayout } = await import('./ui')
      const { FormProviderComponent } = await import('./model')

      return {
        element: (
          <FormProviderComponent
            children={<BusinessAccountApplicationLayout />}
          />
        ),
      }
    },
    children: [
      {
        path: routeNamesPaths.baGeneral,
        lazy: async () => {
          const { GeneralInfo } = await import('./ui')

          return {
            Component: GeneralInfo,
          }
        },
      },
      {
        path: routeNamesPaths.baTurnover,
        lazy: async () => {
          const { Turnover } = await import('./ui')

          return {
            Component: Turnover,
          }
        },
      },
      {
        path: routeNamesPaths.baDirectors,
        lazy: async () => {
          const { Directors } = await import('./ui')

          return {
            Component: Directors,
          }
        },
      },
      {
        path: routeNamesPaths.baDirectorsWithParam,
        lazy: async () => {
          const { Directors } = await import('./ui')

          return {
            Component: Directors,
          }
        },
      },
      {
        path: routeNamesPaths.baEditDirector,
        lazy: async () => {
          const { DirectorEdit } = await import('./ui')

          return {
            Component: DirectorEdit,
          }
        },
      },
      {
        path: routeNamesPaths.baShareholders,
        lazy: async () => {
          const { Shareholders } = await import('./ui')

          return {
            Component: Shareholders,
          }
        },
      },
      {
        path: routeNamesPaths.baEditShareholder,
        lazy: async () => {
          const { DirectorEdit } = await import('./ui')

          return {
            Component: DirectorEdit,
          }
        },
      },
      {
        path: routeNamesPaths.baTerms,
        lazy: async () => {
          const { Terms } = await import('./ui')

          return {
            Component: Terms,
          }
        },
      },
    ],
  },
  {
    path: routeNamesPaths.baSuccess,
    lazy: async () => {
      const { SuccessBusinessApplication } = await import('./ui')

      return {
        Component: SuccessBusinessApplication,
      }
    },
  },
]
