import { sendError } from './send-error'
import { showErrorMessage } from './show-error-message'

export type TError = string | string[]

export function errorHandler(error: TError, isShow = true) {
  if (Array.isArray(error) && error.length) {
    const message = error.join('/n')

    sendError({ message })

    isShow && showErrorMessage(message)

    return
  }

  if (typeof error === 'object' && 'sessionId' in error) {
    const message = 'Registration session was interrupted'

    sendError({ message })

    isShow && showErrorMessage(message)

    return
  }

  if (typeof error === 'object') {
    let errorMessage

    for (const key in error) {
      errorMessage = `${key}: ${error[key]}\n`
    }

    sendError({ message: errorMessage || '32: IDN' })

    isShow && showErrorMessage(errorMessage || 'Oops. Something wrong...')

    return
  }

  sendError({ message: error || 'Oops. Something wrong...' })

  isShow && showErrorMessage(error || 'Oops. Something wrong...')
}
