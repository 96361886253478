import { Accordion, styled } from '@mui/material'

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: theme.spacing(0.5),
  background: '#F6FCFF',
  '&.MuiPaper-root': {
    boxShadow: 'none',
  },
}))
