import axios from 'axios'

import { configs } from '@shared/constants'

interface ISendErrorPayload {
  message: string
}

export async function sendError({ message }: ISendErrorPayload) {
  try {
    const { currentStage } = configs()

    if (currentStage === 'loc') {
      return
    }

    await axios.post('https://api.arbiex.eu/common/error', {
      message: `${currentStage}: ${message}`,
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('cant send to slack')
  }
}
