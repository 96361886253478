import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Button, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useCreateWalletStore } from '@entities/create-wallet'

import { ReactComponent as AddIcon } from './assets/add-icon.svg'
import { StyledIconWrapper, StyledButton } from './styled'

interface IProps {
  small?: boolean
}

export function CreateWalletButton({ small }: IProps) {
  const { t } = useTranslation('features')
  const { setShow } = useCreateWalletStore()
  const theme = useTheme()

  return (
    <>
      {small && (
        <Button
          variant="contained"
          disableFocusRipple
          size="small"
          onClick={() => setShow(true)}
          startIcon={<AddIcon />}
        >
          {t('create wallet.title')}
        </Button>
      )}

      {!small && (
        <StyledButton disableFocusRipple onClick={() => setShow(true)}>
          <StyledIconWrapper>
            <AddRoundedIcon sx={{ width: 24, height: 24, color: 'white' }} />
          </StyledIconWrapper>

          <Typography color={theme.palette.primary.dark} variant="subtitle5">
            {t('create wallet.title')}
          </Typography>
        </StyledButton>
      )}
    </>
  )
}
