import { Typography, styled } from '@mui/material'

export const StyledStep = styled(Typography)(({ theme }) => ({
  minWidth: 110,
  width: 'fit-content',
  alignSelf: 'flex-start',
  padding: '4px 12px',
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: '100px',
  boxShadow: theme.shadows[3],
  whiteSpace: 'nowrap',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    fontSize: 16,
    alignSelf: 'initial',
  },
}))
