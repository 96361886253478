import { styled, Typography } from '@mui/material'

interface IProps {
  component?: React.ElementType
  isFill?: boolean
  disabled?: boolean
}

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isFill' && prop !== 'disabled',
})<IProps>(({ theme, isFill, disabled }) => {
  const setColor = () => {
    if (disabled) {
      return theme.palette.text.secondary
    }

    if (isFill) {
      return theme.palette.common.white
    }

    return theme.palette.common.black
  }

  return {
    color: setColor(),
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.standard,
    }),
  }
})
