import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@shared/constants'

import { fetchApplications } from '../../api'
import { TApplicationStatus } from '../types'

interface IProps {
  status?: TApplicationStatus
  enabled?: boolean
}

export function useGetApplications({ status, enabled = true }: IProps = {}) {
  return useQuery({
    queryKey: [queryKeys.applications, status],
    queryFn: () => fetchApplications({ status }),
    refetchInterval: 15 * 1000,
    enabled,
  })
}
