import { Stack, styled } from '@mui/material'

import { blue, grey } from '@shared/constants'

export const StyledProfileWrapper = styled(Stack)(({ theme }) => ({
  padding: '16px 20px',
  minWidth: 314,
  border: `1px solid ${blue[900]}`,
  borderRadius: 20,
  gap: '4px',

  [theme.breakpoints.up('md')]: {
    padding: '20px 24px',
    borderColor: grey[100],
    gap: '16px',

    '& button': {
      width: 120,
    },
  },
}))
