import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'

import { fetchExchangeFees } from '@entities/exchange'
import { useVirtualAccountStore } from '@entities/virtual-account'

import { queryKeys } from '@shared/constants'

export function useExchangeFees() {
  const accountType = useVirtualAccountStore((s) => s.virtualAccount)?.type

  const query = useQuery({
    queryKey: [queryKeys.exchangeFee],
    queryFn: fetchExchangeFees,
    refetchInterval: 10 * 60 * 1000,
  })

  const calculateFee = (amount: string | number) => {
    const amountNumber = _.toNumber(amount)

    if (!query.data || !accountType) {
      return 0
    }

    const feeRule = query.data[accountType].plan.find((rule) => {
      return rule.min <= amountNumber && amountNumber < rule.max
    })

    if (!feeRule) {
      return 0
    }

    const result = (amountNumber * feeRule.per) / 100

    if (result < 4) {
      return 4
    }

    return result
  }

  const getExchangeFee = () => {
    if (!accountType || !query.data) {
      return
    }

    return query.data[accountType].plan
  }

  const getMinAmount = () => {
    if (!accountType || !query.data) {
      return
    }

    const minAmountArray = query.data[accountType].plan.map((item) => item.min)

    return Math.min(...minAmountArray)
  }

  return {
    ...query,
    getExchangeFee,
    calculateFee,
    getMinAmount,
  }
}
