import { styled } from '@mui/material'

import { grey } from '@shared/constants'

export const SpaceBox = styled('div')(() => ({
  borderRadius: '50%',
  position: 'absolute',
  zIndex: 1,
  top: 11,
  left: 11,
  width: 178,
  height: 178,
  background: grey[50],
  overflow: 'hidden',
}))
