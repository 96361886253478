import { Avatar, styled } from '@mui/material'

export const ProfileAvatar = styled(Avatar)<{ bgcolor: string }>(
  ({ theme, bgcolor }) => ({
    width: 44,
    height: 44,
    backgroundColor: bgcolor,
    color: theme.palette.getContrastText(bgcolor),
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '100%',
    border: 'initial',
    [theme.breakpoints.up('md')]: {
      border: `2px solid ${theme.palette.common.white}`,
      width: 52,
      height: 52,
    },
  })
)
