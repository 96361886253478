import { Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { WalletCardPaper } from '@entities/wallet'

import { TAccountType } from '@shared/types'

import { ReactComponent as CorporateIcon } from '../../assets/business.svg'
import { ReactComponent as IndividualIcon } from '../../assets/personal.svg'

import { InReviewBadgeWrapper } from './styled'

interface IProps {
  accountType: TAccountType
}

export function WalletInReviewCard({ accountType }: IProps) {
  const { t } = useTranslation(['features', 'common'])

  return (
    <WalletCardPaper sx={{ px: { xs: 2, md: 3.5 } }}>
      <Stack spacing={1} direction="row" alignItems="center" height="100%">
        {accountType === 'corporate' ? <CorporateIcon /> : <IndividualIcon />}

        <Typography variant="body9">
          {t(`features:wallet-lis.${accountType}-in-review` as never)}
        </Typography>
      </Stack>

      <InReviewBadgeWrapper>
        <Typography variant="body15">{t('common:in-review')}</Typography>
      </InReviewBadgeWrapper>
    </WalletCardPaper>
  )
}
