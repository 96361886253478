import { IconButton, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useMobileWebviewStore } from '@shared/hooks'
import { CopyButton, HiddenPartComponent } from '@shared/ui'

import { IWallet, useWalletStore } from '../model'

import { ReactComponent as SearchIconDisabled } from './assets/search-icon-disabled.svg'
import { ReactComponent as SearchIcon } from './assets/search-icon.svg'
import { BlockchainIcon } from './components'
import { WalletCardPaper } from './styled'

interface IProps {
  wallet: IWallet
  active?: boolean
}

export function WalletCard({ wallet, active }: IProps) {
  const walletFromStore = useWalletStore((state) => state.wallet)
  const setActiveWallet = useWalletStore((state) => state.setWallet)
  const isMobile = useMobileWebviewStore((state) => state.isMobile)
  const { t } = useTranslation('shared')

  const sendPostMessageLink = useMobileWebviewStore(
    (state) => state.sendPostMessageLink
  )

  const theme = useTheme()

  useEffect(() => {
    if (active) {
      setActiveWallet(wallet)
    }
  }, [active])

  const buttonIconSx = {
    svg: {
      color: theme.palette.primary.dark,
      width: { xs: 18, md: 20 },
      height: { xs: 18, md: 20 },
    },
    mb: { xs: 0, md: -0.25 },
  }

  const tronScanLink = useMemo(
    () => `https://tronscan.org/#/address/${wallet.address}`,
    [wallet.address]
  )

  const isActiveWallet = useMemo(
    () => wallet.id === walletFromStore?.id,
    [wallet.id, walletFromStore?.id]
  )

  return (
    <WalletCardPaper active={isActiveWallet}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <BlockchainIcon type={wallet.blockchain} isActive={isActiveWallet} />

        <Typography variant={'body10'} color={'text.secondary'}>
          {wallet.type.toUpperCase()}
        </Typography>
      </Stack>

      <Stack
        direction={'row'}
        gap={0.5}
        alignItems={'flex-end'}
        justifyContent={'space-between'}
        width={'100%'}
      >
        <HiddenPartComponent
          isActive={isActiveWallet}
          value={wallet.address}
          hiddenValue={wallet.address}
        />

        <Stack direction="row">
          <CopyButton
            sx={buttonIconSx}
            value={wallet.address}
            copiedElementName={t('copy-button.Copied-elements.wallet-address')}
            disabled={!isActiveWallet}
          />

          <IconButton
            href={isMobile ? undefined : tronScanLink}
            onClick={() => {
              isMobile && sendPostMessageLink(tronScanLink)
            }}
            target="_blank"
            size="small"
            sx={buttonIconSx}
          >
            {isActiveWallet ? <SearchIcon /> : <SearchIconDisabled />}
          </IconButton>
        </Stack>
      </Stack>
    </WalletCardPaper>
  )
}
