import { Menu, styled } from '@mui/material'

export const StyledMenu = styled(Menu)(() => ({
  '& .MuiPopover-paper': {
    elevation: 0,
    overflow: 'visible',
    marginTop: '4px',
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      marginLeft: '-4px',
      marginRight: '8px',
    },
    '&::before': {
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
    gap: '4px',
    width: 260,
    borderRadius: 16,
  },
}))
