import { styled } from '@mui/material'

import { MobileDrawer } from '@shared/ui'

export const StyledMobileDrawer = styled(MobileDrawer)(({ theme }) => ({
  '& > .MuiPaper-root': {
    paddingTop: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))
