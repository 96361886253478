import { Grid, styled } from '@mui/material'

export const StyledGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(4),
  },
}))
