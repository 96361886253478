import { Stack, styled } from '@mui/material'

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  gap: '32px',
  padding: '90px 0',

  [theme.breakpoints.up('md')]: {
    padding: '120px 0',
  },
}))
