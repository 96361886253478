import { Box } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import { qrCode } from './config-styles'

interface IProps {
  link: string
  size?: number
}

const QrCodeForwardRef = forwardRef(function QrCode(
  { link, size = 250 }: IProps,
  ref
) {
  const boxRef = useRef<HTMLElement | undefined>()

  useEffect(() => {
    qrCode.append(boxRef.current)
  }, [])

  useEffect(() => {
    qrCode.update({ data: link, width: size, height: size })
  }, [link, size])

  useImperativeHandle(ref, () => ({
    download: () => {
      qrCode.download({ extension: 'png', name: link })
    },
  }))

  return <Box ref={boxRef} />
})

export const QrCode = QrCodeForwardRef
