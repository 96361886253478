import { styled } from '@mui/material'

export const StyledPageTabSwitcherBox = styled('div')(({ theme }) => ({
  scrollbarWidth: 'none',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  background: theme.palette.common.white,
  padding: '0 10px',
}))
