import { useKeycloak } from '@react-keycloak/web'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useWelcomeDialogStore } from '@features/welcome-dialog'

import { useAppLoaderStore } from '@entities/app-loader'

import { useAuth } from '@shared/auth'
import { routeNamesPaths } from '@shared/constants'
import { useErrorListener } from '@shared/error-handlers'
import { useNoInternet } from '@shared/hooks'

export function useMainLayout() {
  const location = useLocation()
  const setShowLoader = useAppLoaderStore((state) => state.setShow)
  const setOpenWelcomeDialog = useWelcomeDialogStore((state) => state.setOpen)

  useErrorListener()

  useNoInternet()

  const [userDetailLoaded, setUserDetailLoaded] = useState(false)

  const {
    store: { user },
    fetchUser,
    fetchUserDetailInfo,
  } = useAuth()

  const { keycloak } = useKeycloak()

  const fetchUserDetailInfoAndHideLoader = async () => {
    if (!user) {
      return
    }

    if (user.statusOndata !== 'APPROVED') {
      setUserDetailLoaded(true)
    } else if (user.statusOndata === 'APPROVED') {
      await fetchUserDetailInfo()

      setTimeout(() => {
        setUserDetailLoaded(true)
      }, 50)
    }
  }

  useEffect(() => {
    setShowLoader(true)

    if (keycloak.authenticated) {
      if (user) {
        setShowLoader(false)
      }

      fetchUser()
    }
  }, [])

  const isVerified = useMemo(
    () =>
      user?.statusOndata === 'APPROVED' ||
      location.pathname === routeNamesPaths.profile,
    [user, location]
  )

  useEffect(() => {
    user && setOpenWelcomeDialog(!isVerified)

    fetchUserDetailInfoAndHideLoader()
  }, [user, isVerified])

  return { isVerified, userDetailLoaded }
}
