import { styled } from '@mui/material'
import { ToastContainer } from 'react-toastify'

export const StyledToastContainer = styled(ToastContainer)(({ theme }) => ({
  '&.Toastify__toast-container': {
    '&.Toastify__toast-container--bottom-right, &.Toastify__toast-container--top-center':
      {
        '& .Toastify__toast.Toastify__toast-theme--light': {
          '&.Toastify__toast--success': {
            backgroundColor: theme.palette.success.main,
            '--toastify-icon-color-success': theme.palette.common.white,
          },
          '&.Toastify__toast--warning': {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
            '--toastify-icon-color-warning': theme.palette.warning.contrastText,
            '& .Toastify__close-button': {
              opacity: 1,
              color: theme.palette.warning.contrastText,
            },
            '& > svg': {
              marginTop: 15,
              fill: theme.palette.warning.contrastText,
            },
          },
          '&.Toastify__toast--info': {
            backgroundColor: theme.palette.info.main,
            '--toastify-icon-color-info': theme.palette.common.white,
          },
          '&.Toastify__toast--error': {
            backgroundColor: theme.palette.error.main,
            '--toastify-icon-color-error': theme.palette.common.white,
          },
        },
        '& .Toastify__close-button': {
          opacity: 1,
          color: theme.palette.common.white,
        },
        '& > svg': {
          marginTop: 15,
          fill: theme.palette.common.white,
        },
      },
    '& .Toastify__toast': {
      color: theme.palette.common.white,
    },
  },
}))
