import { Radio, RadioProps } from '@mui/material'

import { ReactComponent as CheckedIcon } from '../assets/checked.svg'
import { ReactComponent as UncheckedIcon } from '../assets/unchecked.svg'

export function CustomRadioInput(props: RadioProps) {
  return (
    <Radio {...props} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
  )
}
