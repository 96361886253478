import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const CryptoTransactionsRoute: RouteObject = {
  path: routeNamesPaths.transactions,
  lazy: async () => {
    const { CryptoTransactions } = await import('./ui')

    return { Component: CryptoTransactions }
  },
}
