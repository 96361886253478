import { Card, styled } from '@mui/material'

interface IProps {
  focus: boolean
}

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'focus',
})<IProps>(({ theme, focus }) => ({
  transition: theme.transitions.create(['border', 'box-shadow', 'background']),
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: '8px',
  ...(!focus && {
    border: `unset`,
    boxShadow: 'unset',
    background: 'inherit',
  }),
}))
