import { Box, FormHelperText, RadioGroup, Typography } from '@mui/material'
import { ChangeEvent, forwardRef } from 'react'

import { PlannedMonthlyTurnover } from '@entities/business-account-application'

import { IInputProps } from '@shared/types'

interface IProps extends IInputProps<ChangeEvent<HTMLInputElement>> {
  text?: string
}

export const InfoAboutPayments = forwardRef<HTMLInputElement, IProps>(
  function Named(props, ref) {
    const { text, label, value, onChange, error, helperText, sx } = props

    return (
      <Box sx={sx} ref={ref}>
        <Typography variant="subtitle4" mb={2.5}>
          {text}
        </Typography>

        <Typography variant="subtitle5" sx={{ mb: 1 }}>
          {label}
        </Typography>

        <RadioGroup onChange={onChange} value={value}>
          <PlannedMonthlyTurnover />
        </RadioGroup>

        {!!error && (
          <FormHelperText error={!!error}>{helperText || ' '}</FormHelperText>
        )}
      </Box>
    )
  }
)
