import { Box, styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  open?: boolean
  isItem?: boolean
  error?: boolean
}

export const WalletTokenItemBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'open' && prop !== 'isItem' && prop !== 'error',
})<IProps>(({ theme, open, isItem, error }) => ({
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingLeft: '10px',
  paddingRight: '10px',
  height: '100%',
  cursor: 'pointer',

  '&:focus-visible': {
    outline: 'none',
    boxShadow: `0px 0px 0px 2px ${theme.palette.primary.main}`,
  },

  '&:active': {
    boxShadow: 'none',
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: isItem ? '16px' : '24px',
    paddingRight: isItem ? '16px' : '24px',
  },

  ...(open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }),

  ...(isItem && {
    background: theme.palette.common.white,
    paddingLeft: isItem ? '4px' : '12px',
    paddingRight: isItem ? '4px' : '12px',
    paddingTop: '8px',
    paddingBottom: '8px',

    '&:hover': {
      background: grey[100],
    },
  }),

  ...(error && {
    background: theme.palette.error.light,
  }),
}))
