import { SxProps } from '@mui/material'

export const createWalletModalSx: SxProps = {
  '& .MuiDialog-paper': {
    minWidth: '600px !important',
    overflow: 'visible',
  },
  '& .MuiDialogContent-root': {
    overflow: 'visible',
    padding: '40px',
  },
  '& .content': { background: 'none', mb: 0, px: 0 },
  '& .title': { mb: 0, pb: 0 },
}
