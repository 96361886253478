import { styled } from '@mui/material'

export const InReviewBadgeWrapper = styled('div')(({ theme }) => ({
  width: 'max-content',
  padding: '0 12px',

  backgroundColor: theme.palette.warning.main,
  borderRadius: '0 12px 0 12px',
  border: '1px solid',
  borderColor: theme.palette.primary.dark,

  position: 'absolute',
  top: 0,
  right: 0,
}))
