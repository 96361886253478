import { Box, styled } from '@mui/material'

interface IProps {
  payload: {
    active?: boolean
  }
}

export const StyledTrack = styled(Box)<IProps>(
  ({ theme, payload: { active } }) => ({
    width: '52%',
    height: 'calc(100% + 2px)',
    position: 'absolute',
    top: -1,
    left: active ? 'calc(48% + 1px)' : -1,
    right: active ? -1 : '48%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
  })
)
