import { styled } from '@mui/material'

import { grey } from '@shared/constants'

export const MobileRowItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(1.5),
  borderBottom: `1px solid ${grey[200]}`,
  padding: '8px 0',
}))
