import { useEffect } from 'react'

import { useErrorHandler } from './use-error-handler'

/**
 * This hook works under the Router provider.
 */

export function useErrorListener() {
  const handleError = useErrorHandler()

  const handleWindowError = (e: ErrorEvent) => {
    const stack = e.error.stack
    let message = e.error.toString()

    if (stack) {
      message += '\n' + stack
    }

    handleError({ message })
  }

  useEffect(() => {
    window.addEventListener('error', handleWindowError)

    return () => {
      window.removeEventListener('error', handleWindowError)
    }
  }, [handleError])
}
