import { styled } from '@mui/material'

interface IProps {
  small?: boolean
}

export const StyledIconWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'small',
})<IProps>(({ theme, small }) => ({
  padding: small ? '2px' : '8px',
  borderRadius: small ? '3px' : '12px',
  background: theme.palette.primary.dark,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: theme.transitions.create('padding'),
}))
