import { styled } from '@mui/material'

import { grey } from '@shared/constants'

export const WrapperForMainLayout = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    background: grey[50],
  },
}))
