import { styled } from '@mui/material'

export const EstimateDataBox = styled('div')(({ theme }) => ({
  borderRadius: '10px',
  border: `1px solid ${theme.palette.text.secondary}`,
  padding: '16px 20px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,

  [theme.breakpoints.down('xl')]: {
    border: 0,
    background: theme.palette.primary.light,
  },
}))
