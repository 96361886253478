import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const BalancesRoute: RouteObject = {
  path: routeNamesPaths.balances,
  lazy: async () => {
    const { BalancesPage } = await import('../ui')

    return { Component: BalancesPage }
  },
}
