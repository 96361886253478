import { styled, TextField } from '@mui/material'

export const StyledShareholderPercentInput = styled(TextField)(({ theme }) => ({
  alignSelf: 'center',
  justifySelf: 'center',
  fieldset: {
    transition: theme.transitions.create(['border-color'], {
      duration: theme.transitions.duration.standard,
    }),
    borderColor: `${theme.palette.primary.dark}`,
  },
  width: 82,

  '& .MuiInputBase-root': {
    paddingLeft: 8,
    paddingRight: '0 !important',
    minHeight: '36px !important',
    maxHeight: '36px !important',
  },

  '& input': {
    paddingLeft: 8,
    paddingRight: 0,
    textAlign: 'end',
    fontFamily: 'IBM Plex Sans',
    fontWeight: 500,
  },

  [theme.breakpoints.up('md')]: {
    width: 82,

    '& .MuiInputBase-root': {
      padding: 0,
    },

    '& input': {
      paddingLeft: 8,
      paddingRight: 0,
      textAlign: 'end',
    },
  },
}))
