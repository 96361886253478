// import to from 'await-to-js'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useCreateWalletStore } from '@entities/create-wallet'
import {
  IVirtualAccount,
  useVirtualAccountList,
} from '@entities/virtual-account'
import { /* createWallet, */ useWalletList } from '@entities/wallet'

import { routeNamesPaths } from '@shared/constants'
import { TBlockchain } from '@shared/types'

interface IProps {
  accountType: IVirtualAccount['type'] | null
  activeBlockchain: TBlockchain | null
  setIsLoading: (value: boolean) => void
}

export function useCreateWallet({
  accountType,
  activeBlockchain,
  setIsLoading,
}: IProps) {
  const { t } = useTranslation('features')

  const navigate = useNavigate()
  const { data: virtualAccounts } = useVirtualAccountList()
  const { refetch: updateWalletList, data: wallets } = useWalletList()

  const { setShow } = useCreateWalletStore()

  const createdWallet = wallets?.find(
    (w) => w.blockchain === activeBlockchain && w.type === accountType
  )

  const getVirtualAccByType = () => {
    const virtualAccountByType = virtualAccounts?.find(
      (va) => va.type === accountType
    )

    if (!virtualAccountByType) {
      accountType === 'individual'
        ? navigate(routeNamesPaths.paPersonalDetail)
        : navigate(routeNamesPaths.businessApplication)

      setShow(false)

      return
    }
  }

  const handleCreateWallet = () => {
    if (createdWallet) {
      toast.error(t('create wallet.errors.wallet-already-exist'))

      setShow(false)

      return
    }
  }

  const getCreateWalletResult = async () => {
    if (!activeBlockchain || !accountType || createdWallet) {
      setIsLoading(false)

      return
    }

    // const [error] = await to(
    //   createWallet({
    //     blockchain: activeBlockchain,
    //     accountType: accountType,
    //   })
    // )

    setIsLoading(false)

    // if (error) {
    //   toast.error(t('create wallet.errors.wallet-not-created'))

    //   return
    // }
  }

  const onCreateWallet = async () => {
    if (virtualAccounts && virtualAccounts.length > 0) {
      getVirtualAccByType()

      handleCreateWallet()

      setIsLoading(true)

      getCreateWalletResult()

      await updateWalletList()

      return
    }

    accountType === 'individual'
      ? navigate(routeNamesPaths.paPersonalDetail)
      : navigate(routeNamesPaths.businessApplication)

    setShow(false)
  }

  return onCreateWallet
}
