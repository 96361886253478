import { Menu, styled } from '@mui/material'

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(1),
    background: ' #FFFFFF',
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.12)',
    borderRadius: '12px',
  },
  '& .MuiList-root': {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
}))
