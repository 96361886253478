import { useKeycloak } from '@react-keycloak/web'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import { BalancesRoute } from '@pages/balances'
import { BusinessAccountApplicationRoutes } from '@pages/business-account-application'
import { CryptoTransactionsRoute } from '@pages/crypto-transactions'
import { ErrorPageRoute } from '@pages/error'
import { ExchangeRoute } from '@pages/exchange'
import { ExchangeHistoryRoute } from '@pages/exchange-history'
import { MainPageRoute } from '@pages/main-page'
import { NoInternetPageRoute } from '@pages/no-internet'
import { OpenCryptoAccountRoute } from '@pages/open-crypto-account'
import { PersonalAccountApplicationRoute } from '@pages/personal-account-application'
import { ProfilePageRoute } from '@pages/profile'
import { SendRoute } from '@pages/send'
import { TechnicalPageRoute } from '@pages/technical-work'
import { TopUpCryptoWalletRoute } from '@pages/top-up-crypto-wallet'
import { TopUpVirtualAccountRoute } from '@pages/top-up-virtual-account'
import { WithdrawRoute } from '@pages/withdraw'
import { WithdrawTransactionsRoute } from '@pages/withdraw-transactions'

import { AppLoader } from '@entities/app-loader'

import { routeNamesPaths } from '@shared/constants'

import {
  ErrorElement,
  MainGeneralLayout,
  MainVirtualAccountLayout,
  MainWalletLayout,
  TechnicalLayout,
} from './layouts'

const router = createBrowserRouter([
  {
    path: routeNamesPaths.home,
    errorElement: <ErrorElement />,
    element: <Navigate replace to={routeNamesPaths.balances} />,
  },
  {
    element: <MainGeneralLayout />,
    errorElement: <ErrorElement />,
    children: [
      OpenCryptoAccountRoute,
      PersonalAccountApplicationRoute,
      ...BusinessAccountApplicationRoutes,
      ProfilePageRoute,
    ],
  },
  {
    element: <MainWalletLayout />,
    errorElement: <ErrorElement />,
    children: [MainPageRoute, SendRoute, ExchangeRoute, TopUpCryptoWalletRoute],
  },

  {
    element: <MainVirtualAccountLayout />,
    errorElement: <ErrorElement />,
    children: [WithdrawRoute, TopUpVirtualAccountRoute],
  },
  {
    element: <MainVirtualAccountLayout paddingVariant="list" />,
    errorElement: <ErrorElement />,
    children: [WithdrawTransactionsRoute],
  },
  {
    element: <MainWalletLayout paddingVariant="list" />,
    errorElement: <ErrorElement />,
    children: [BalancesRoute, ExchangeHistoryRoute, CryptoTransactionsRoute],
  },
  {
    element: <TechnicalLayout />,
    errorElement: <ErrorElement />,
    children: [TechnicalPageRoute, NoInternetPageRoute, ErrorPageRoute],
  },
  {
    path: '*',
    errorElement: <ErrorElement />,
    element: <Navigate replace to={routeNamesPaths.techWork} />,
  },
])

export function RouterViewer() {
  const { keycloak, initialized } = useKeycloak()

  if (!initialized || !keycloak.authenticated) {
    return <AppLoader showManual />
  }

  return <RouterProvider router={router} />
}
