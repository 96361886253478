import { styled } from '@mui/material'

export const ProgressBarItemList = styled('ul')(({ theme }) => ({
  width: '100%',
  margin: 0,
  padding: '0 10px',
  overflowX: 'scroll',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  columnGap: 5,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  listStyleType: 'none',
  [theme.breakpoints.up('sm')]: {
    width: '90%',
    margin: '0 auto',
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
}))
