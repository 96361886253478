import { setCookie } from './set-cookie'

export function deleteCookie(cookiesNames: string[] | string) {
  if (Array.isArray(cookiesNames)) {
    cookiesNames.forEach((name) => setCookie(name, '', -1))

    return
  }

  setCookie(cookiesNames, '', -1)
}
