import { Box, styled } from '@mui/material'

export const StyledBoxEmptyShareholders = styled(Box)(({ theme }) => ({
  borderRadius: 4,
  border: `1px solid ${theme.palette.primary.dark}`,
  background: '#F6FCFF',
  width: '100%',
  height: 236,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
  padding: 16,
}))
