import { Button, styled } from '@mui/material'

export const StyledMobileButton = styled(Button)(({ theme }) => ({
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
  width: '100% !important',
  justifyContent: 'flex-start',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiButton-startIcon': {
    marginRight: '12px',
    marginLeft: 0,

    '& svg': {
      width: 24,
      height: 24,

      [theme.breakpoints.up('md')]: {
        width: 'initial',
        height: 'initial',
      },
    },
  },
}))
