import { styled } from '@mui/material'

interface IProps {
  opened?: boolean
  selected?: boolean
}

export const ListRow = styled('li', {
  shouldForwardProp: (prop) => prop !== 'opened',
})<IProps>(({ theme, opened, selected }) => ({
  background: opened || selected ? '#F6FCFF' : theme.palette.common.white,
  boxShadow: '0px 4px 8px -8px rgba(0, 0, 0, 0.25)',
  listStyle: 'none',
  cursor: 'pointer',
  position: 'relative',
  borderRadius: opened
    ? `${theme.spacing(1)} ${theme.spacing(1)} 0 0`
    : theme.spacing(1),
  borderColor: opened || selected ? 'rgba(0, 158, 224, 0.50)' : 'transparent',
  borderWidth: '1px',
  borderStyle: 'solid',
  [theme.breakpoints.up('md')]: {
    background: opened || selected ? '#F6FCFF' : '#FAFAFA',
    borderRadius: opened
      ? `${theme.spacing(0.5)} ${theme.spacing(0.5)} 0 0`
      : theme.spacing(0.5),
    boxShadow: 'none',
    '&:hover': {
      background: '#F6FCFF',
    },
  },
  transition: theme.transitions.create(
    ['border-color', 'border-radius', 'box-shadow', 'background-color'],
    {
      duration: theme.transitions.duration.standard,
    }
  ),
}))
