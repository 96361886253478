import { TableRow, styled } from '@mui/material'

import { grey } from '@shared/constants'

export const StyledTableBodyRow = styled(TableRow)(() => ({
  borderRadius: '8px',
  position: 'relative',
  zIndex: 2,

  '&:hover': {
    backgroundColor: grey[100],
  },
}))
