import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const WithdrawTransactionsRoute: RouteObject = {
  path: routeNamesPaths.withdrawTransactions,
  lazy: async () => {
    const { WithdrawTransactions: CryptoTransactions } = await import('./ui')

    return { Component: CryptoTransactions }
  },
}
