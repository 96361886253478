import { Box, styled } from '@mui/material'

export const StyledDeleteButtonWrapper = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  mr: 16,
  justifySelf: 'flex-start',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    '&.delete-shareholder': {
      gridRowStart: 2,
      gridRowEnd: 3,
      gridColumnStart: 2,
      gridColumnEnd: 3,
      mr: 0,
    },
  },
  [theme.breakpoints.up('sm')]: {
    justifySelf: 'flex-end',
  },
}))
