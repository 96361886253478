import { Typography, styled } from '@mui/material'

export const MainTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 28,
  fontWeight: 500,
  lineHeight: '120%',
  marginBottom: theme.spacing(3.5),
  [theme.breakpoints.up('md')]: {
    fontSize: 40,
    marginBottom: theme.spacing(4.5),
  },
}))
