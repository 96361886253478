import { Drawer, styled } from '@mui/material'

export const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiPaper-root': {
    padding: '20px 16px 40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',

    borderRadius: '16px 16px 0 0',
    boxShadow: '0px -3px 8px 0px rgba(0, 0, 0, 0.08)',
  },
}))
