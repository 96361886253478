import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { queryKeys } from '@shared/constants'

import { fetchVirtualAccounts } from '../../api'
import { useVirtualAccountStore } from '../store'

export const useVirtualAccountList = () => {
  const { virtualAccount, setVirtualAccount } = useVirtualAccountStore(
    (state) => state
  )

  const query = useQuery([queryKeys.virtualAccounts], fetchVirtualAccounts, {
    refetchInterval: 15000,
  })

  const { data: allVirtualAccounts } = query

  useEffect(() => {
    if (!allVirtualAccounts) {
      return
    }

    if (!virtualAccount) {
      setVirtualAccount(
        allVirtualAccounts.length ? allVirtualAccounts[0] : null
      )

      return
    }

    const found = allVirtualAccounts.find((va) => va.id === virtualAccount?.id)

    if (!found) {
      setVirtualAccount(
        allVirtualAccounts.length ? allVirtualAccounts[0] : null
      )

      return
    }

    setVirtualAccount(found)
  }, [allVirtualAccounts])

  return query
}
