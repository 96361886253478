import { Button, styled } from '@mui/material'
import { ElementType } from 'react'

import { grey } from '@shared/constants'

interface IProps {
  isInstagramIcon?: boolean
  component?: ElementType
  target: '_blank'
}

export const StyledIconsWrapper = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isInstagramIcon',
})<IProps>(({ isInstagramIcon }) => ({
  '&.MuiButtonBase-root': { padding: 0, minWidth: 'initial', borderRadius: 5 },
  '& svg:hover': {
    width: 24,
    height: 24,
    fill: grey[300],
    '& rect:first-of-type': {
      fill: grey[400],
    },
    ...(isInstagramIcon && {
      '& path': {
        fill: grey[400],
      },
    }),
  },
}))
