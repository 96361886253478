import { api } from '@shared/http-instance'
import { TAccountType } from '@shared/types'

import { ITariffPlan } from '../model'

export interface IAccountTariffPlanBaseInfo {
  id: number
  name: string
  plan: ITariffPlan[]
}

export async function fetchExchangeFees() {
  const url = '/exchanges/tariffs/plan'

  const response =
    await api.get<Record<TAccountType, IAccountTariffPlanBaseInfo>>(url)

  return response.data
}
