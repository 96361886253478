import { Accordion, styled } from '@mui/material'

export const StyledAccordion = styled(Accordion)(() => ({
  width: '100%',
  padding: 0 + '!important',
  '&.MuiPaper-root': {
    borderRadius: 0,
    boxShadow: 'none',
  },
}))
