import { SxProps, Typography, useTheme } from '@mui/material'

import { Map } from '@shared/ui'

import { StyledPercentageContainer, StyledPercentageWrapper } from './styled'

interface IPercentageFromWalletAmount {
  error?: boolean
  sx?: SxProps
  setChangeCurrPercentage: (value: number) => void
}

const percentage = [25, 50, 75, 100]

export const PercentageFromWalletAmount = ({
  error = false,
  sx,
  setChangeCurrPercentage,
}: IPercentageFromWalletAmount) => {
  const theme = useTheme()

  return (
    <StyledPercentageWrapper sx={sx}>
      <Map
        collection={percentage}
        iterate={(item, index) => (
          <StyledPercentageContainer
            key={index}
            onClick={() => setChangeCurrPercentage(item)}
          >
            <Typography
              variant={'body3'}
              fontWeight={500}
              sx={{
                color: error
                  ? theme.palette.error.main
                  : theme.palette.primary.main,

                '&:hover': {
                  color: theme.palette.primary.dark,
                },
              }}
            >
              {item}%
            </Typography>
          </StyledPercentageContainer>
        )}
      />
    </StyledPercentageWrapper>
  )
}
