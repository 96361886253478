import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateWalletStore } from '@entities/create-wallet'
import { IVirtualAccount } from '@entities/virtual-account'

import { TBlockchain } from '@shared/types'
import { Modal } from '@shared/ui'

import { useCreateWallet } from '../../model'

import { ChooseAccount, ChooseBlockchain } from './components'
import { createWalletModalSx } from './styled'

export function CreateWalletModal() {
  const { t } = useTranslation('features')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const { setShow, show } = useCreateWalletStore()

  const [accountType, setAccountType] = useState<
    IVirtualAccount['type'] | null
  >(null)

  const [businessDir, setBusinessDir] = useState<'auth' | 'dir' | null>(null)

  const [activeBlockchain, setActiveBlockchain] = useState<TBlockchain | null>(
    null
  )

  const [isLoading, setIsLoading] = useState(false)

  const onCreateWallet = useCreateWallet({
    accountType,
    activeBlockchain,
    setIsLoading,
  })

  const isAccountTypeValid = useMemo(
    () =>
      accountType === 'individual' ||
      (accountType === 'corporate' && businessDir),
    [accountType, businessDir]
  )

  const reset = () => {
    setAccountType(null)

    setBusinessDir(null)

    setActiveBlockchain(null)
  }

  useEffect(() => {
    if (!show) {
      setTimeout(reset, 300)
    }
  }, [show])

  return (
    <>
      <Modal
        open={show}
        onClose={() => setShow(false)}
        hideActionButtons
        sx={createWalletModalSx}
        title={
          <Typography variant={mdAndUp ? 'h1' : 'subtitle3'} mb={3}>
            {t('create wallet.title')}
          </Typography>
        }
        content={
          <Stack>
            <ChooseAccount
              accountType={accountType}
              setAccountType={setAccountType}
              businessDir={businessDir}
              setBusinessDir={setBusinessDir}
            />

            <ChooseBlockchain
              activeBlockchain={activeBlockchain}
              setActiveBlockchain={setActiveBlockchain}
              disabled={!accountType}
            />

            <Box>
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => setShow(false)}
                    size="large"
                  >
                    {t('create wallet.cancel')}
                  </Button>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Button
                    disabled={
                      !isAccountTypeValid || !activeBlockchain || isLoading
                    }
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={onCreateWallet}
                    size="large"
                  >
                    {t('create wallet.title')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        }
      />
    </>
  )
}
