import { styled } from '@mui/material'

import { blue } from '@shared/constants'

interface IProps {
  open?: boolean
  isAdornment?: boolean
  error?: boolean
}

export const SelectedItemBox = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'open' && prop !== 'isAdornment' && prop !== 'error',
})<IProps>(({ theme, open, isAdornment, error }) => ({
  height: 52,
  minWidth: 200,
  border: isAdornment ? 'transparent' : `1px solid ${blue[950]}`,
  borderRadius: isAdornment ? '0 12px 12px 0' : '12px',
  boxSizing: 'border-box',
  borderLeftWidth: '1px !important',

  ...(error && {
    border: `1px solid ${theme.palette.error.main}`,
  }),

  [theme.breakpoints.up('md')]: {
    border: '1px solid',
    borderColor: theme.palette.primary.light,
    minWidth: 250,
    zIndex: 1,
    position: 'relative',

    '&:hover': {
      borderColor: theme.palette.primary.main,
    },

    ...(open && {
      borderRadius: isAdornment ? '0 10px 0 0' : '10px 10px 0 0',
      background: theme.palette.common.white,
    }),

    ...(error &&
      open && {
        border: `1px solid ${theme.palette.primary.dark}`,
      }),
  },

  [theme.breakpoints.down('md')]: {
    borderLeft: isAdornment ? 0 : 'auto',
  },
}))
