import { styled } from '@mui/material'

interface IProps {
  color: string
}

export const StyledName = styled('span', {
  shouldForwardProp: (prop) => prop !== 'color',
})<IProps>(({ theme, color }) => ({
  marginTop: theme.spacing(-0.125),
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1.5,
  color,
}))
