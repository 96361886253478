import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material'
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers'
import { forwardRef } from 'react'

import { grey } from '@shared/constants'
import { TextFieldStyledLabel } from '@shared/ui'

import { ReactComponent as CalendarIcon } from './assets/calendar.svg'

interface IProps {
  value?: Date | null
  onChange: (...event: unknown[]) => void
  onBlur?: (...event: unknown[]) => void
  label?: string
  placeholder?: string
  error?: boolean
  helperText?: string | boolean
  fullWidth?: boolean
  sx?: SxProps<Theme>
  disabled?: boolean
  format?: string
  disableFuture?: boolean
  disablePast?: boolean
}

export const DateField = forwardRef<HTMLInputElement, IProps>(
  function Named(props, ref) {
    const {
      value,
      onChange,
      onBlur,
      label,
      disabled,
      error,
      fullWidth,
      helperText,
      placeholder = 'DD/MM/YYYY',
      format = 'dd/MM/yyyy',
      sx,
      disableFuture,
      disablePast,
    } = props

    const theme = useTheme()
    const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'))

    return (
      <Box sx={sx}>
        {lgAndUp ? (
          <>
            <TextFieldStyledLabel className="label" disabled={disabled}>
              {label}
            </TextFieldStyledLabel>

            <DesktopDatePicker
              value={value}
              onChange={onChange}
              disabled={disabled}
              format={format}
              inputRef={ref}
              disableOpenPicker={disabled}
              slots={{
                openPickerIcon: CalendarIcon,
              }}
              slotProps={{
                textField: {
                  fullWidth: fullWidth,
                  placeholder: placeholder,
                  error: error,
                  helperText: error && helperText,
                  sx: {
                    color: theme.palette.text.primary,
                    '& .Mui-disabled': {
                      WebkitTextFillColor: theme.palette.text.disabled,
                      backgroundColor: grey[50],
                    },
                  },
                  onBlur: onBlur,
                },
                openPickerIcon: CalendarIcon,
              }}
              minDate={new Date(1900, 0, 1)}
              disableFuture={disableFuture}
              disablePast={disablePast}
            />
          </>
        ) : (
          <>
            <TextFieldStyledLabel className="label" disabled={disabled}>
              {label}
            </TextFieldStyledLabel>

            <MobileDatePicker
              value={value}
              onChange={onChange}
              format={format}
              disabled={disabled}
              inputRef={ref}
              slotProps={{
                textField: {
                  fullWidth: fullWidth,
                  placeholder: placeholder,
                  error: error,
                  helperText: error && helperText,
                  sx: {
                    mt: '0 !important',
                    color: theme.palette.text.primary,
                    '& .Mui-disabled': {
                      WebkitTextFillColor:
                        theme.palette.text.disabled + '!important',
                      backgroundColor: grey[50],
                    },
                  },
                  onBlur: onBlur,
                },
              }}
              minDate={new Date(1900, 0, 1)}
              disableFuture={disableFuture}
              disablePast={disablePast}
            />
          </>
        )}
      </Box>
    )
  }
)
