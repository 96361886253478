import { styled, Typography, TypographyProps } from '@mui/material'

interface IProps extends TypographyProps {
  diff?: number
}

export const TimerValueTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'diff',
})<IProps>(({ theme, diff }) => ({
  fontFamily: 'IBM Plex Sans, sans-serif',
  letterSpacing: '0.01em',
  transform: diff !== undefined && diff <= 0 ? 'scale(1.95)' : 'scale(1)',
  transition: theme.transitions.create(['transform']),
}))
