import { Stack, styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  error?: boolean
}

export const CloudIconWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'error',
})<IProps>(({ theme, error }) => ({
  [theme.breakpoints.down('md')]: {
    '& path:first-of-type': {
      fill: error ? theme.palette.error.main : theme.palette.primary.dark,
    },
    '& path:last-child': {
      fill: error ? theme.palette.error.light : grey[50],
    },
  },
}))
