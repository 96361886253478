import { SxProps, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { routeNamesPaths } from '@shared/constants'

import { ReactComponent as ArrowIcon } from './assets/arrow-icon.svg'

interface IProps {
  sx?: SxProps
}

export function ToCryptoButton({ sx }: IProps) {
  const { t } = useTranslation('widgets')

  return (
    <Button
      href={routeNamesPaths.balances}
      endIcon={<ArrowIcon />}
      variant="outlined"
      size="small"
      sx={sx}
    >
      {t('page-tab-switcher.toCrypto')}
    </Button>
  )
}
