import { Box } from '@mui/material'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'

import { TBlockchain } from '@shared/types'
import { Map } from '@shared/ui'

import { ICoinsList } from './choose-blockchain'
import { ChooseButton } from './choose-button'
import { ReactComponent as ArrowIcon } from './icons/arrow-right.svg'
import { SlideButton, SliderWrapper } from './styled'

interface IProps {
  coinsList: ICoinsList[]
  activeBlockchain: TBlockchain | null
  setActiveBlockchain: (value: TBlockchain | null) => void
}

export function DesktopBlockchainList({
  coinsList,
  activeBlockchain,
  setActiveBlockchain,
}: IProps) {
  return (
    <SliderWrapper>
      <Splide
        hasTrack={false}
        aria-label="Partners"
        className="splide"
        options={{
          type: 'loop',
          perPage: 3,
          arrows: true,
          pagination: false,
          pauseOnHover: false,
          drag: false,
          autoWidth: false,
        }}
      >
        <SplideTrack>
          <Map
            collection={coinsList}
            iterate={(item) => {
              return (
                <SplideSlide key={item.key}>
                  <ChooseButton
                    icon={item.icon}
                    text={item.name}
                    active={activeBlockchain === item.key}
                    onClick={() => setActiveBlockchain(item.key)}
                    disabled={item.disabled}
                    selectType="coin"
                    sx={{
                      width: 140,
                      height: 86,
                      '& .icon': {
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                      },
                      ...item.sx,
                    }}
                  />
                </SplideSlide>
              )
            }}
          />
        </SplideTrack>

        <Box sx={{ height: 0 }} className="splide__arrows">
          <SlideButton isBack={true} className="splide__arrow--prev">
            <ArrowIcon />
          </SlideButton>

          <SlideButton className="splide__arrow--next">
            <ArrowIcon />
          </SlideButton>
        </Box>
      </Splide>
    </SliderWrapper>
  )
}
