import { styled } from '@mui/material'

export const WalletTokenListBox = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 'calc(100% + 2px)',
  zIndex: 2,
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: '10px',
  padding: '8px',
  background: theme.palette.background.paper,
  overflowX: 'hidden',
  overflowY: 'auto',
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  gap: '2px',

  [theme.breakpoints.up('md')]: {
    borderRadius: '0 0 10px 10px',
    borderTop: 0,
    top: '100%',
  },

  '&::-webkit-scrollbar': {
    width: 6,
    height: 6,
    background: 'transparent',
  },
  '&::-webkit-scrollbar-track-piece': {
    marginTop: '5px',
    marginBottom: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    width: 6,
    height: 6,
    borderRadius: '100px',
    background: theme.palette.background.paper,
    visibility: 'hidden',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    visibility: 'visible',
    background: theme.palette.background.paper,
    width: 6,
    height: 6,
  },
}))
