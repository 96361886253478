import { CssBaseline, useMediaQuery, useTheme } from '@mui/material'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import 'react-toastify/dist/ReactToastify.css'

import '@shared/i18n'

import { CookieConfirmation } from '@widgets/cookies-confirmations'

import {
  InactiveNotification,
  useInactiveNotificationStore,
} from '@entities/inactive-notification'

import { useMobileWebviewStore, useSetIsMobile } from '@shared/hooks'

import { ComposeProvider } from './providers'
import { RouterViewer } from './router'
import { StyledToastContainer } from './styled-toast-container'

export default function App() {
  const theme = useTheme()
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'))

  const toastContainerPosition = useMemo(
    () => (!smAndUp ? 'top-center' : 'bottom-right'),
    [smAndUp]
  )

  const setShowInactiveNotification = useInactiveNotificationStore(
    (state) => state.setShowNotification
  )

  const resetInactiveNotificationTimer = useInactiveNotificationStore(
    (state) => state.resetTimer
  )

  const stopInactiveNotificationTimer = useInactiveNotificationStore(
    (state) => state.stopTimer
  )

  useSetIsMobile()

  const isMobile = useMobileWebviewStore((state) => state.isMobile)

  useEffect(() => {
    const events = [
      'mousemove',
      'keydown',
      'mousedown',
      'touchstart',
      'resize',
      'scroll',
    ]

    setShowInactiveNotification(false)

    events.forEach((event) => {
      window.addEventListener(event, resetInactiveNotificationTimer)
    })

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetInactiveNotificationTimer)
      })

      stopInactiveNotificationTimer()
    }
  }, [])

  return (
    <>
      <Helmet
        encodeSpecialCharacters={true}
        defaultTitle="Arbiex Crypto"
        titleTemplate="Arbiex Crypto - %s"
      />

      <ComposeProvider>
        <CssBaseline />
        <ReactQueryDevtools />
        {!isMobile && <CookieConfirmation />}

        <StyledToastContainer
          newestOnTop
          position={toastContainerPosition}
          hideProgressBar
          limit={isMobile ? 2 : undefined}
        />

        <RouterViewer />
        {process.env.NODE_ENV !== 'development' && <InactiveNotification />}
      </ComposeProvider>
    </>
  )
}
