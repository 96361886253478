import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { IExchangeRate } from './types'

interface IExchangeRateStore {
  rateList: IExchangeRate[]
  setRateList: (rateList: IExchangeRate[]) => void
}

export const useExchangeRateStore = create<IExchangeRateStore>()(
  devtools(
    (set) => ({
      rateList: [],
      setRateList: (rateList) => set({ rateList }),
    }),
    { name: 'exchange-rate-store' }
  )
)
