import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const MainPageRoute: RouteObject = {
  path: routeNamesPaths.home,
  lazy: async () => {
    const { MainPage } = await import('./ui')

    return { Component: MainPage }
  },
}
