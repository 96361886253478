import { styled, Stack } from '@mui/material'

export const StyledTransactionDetailsWrap = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.text.secondary}`,
  alignitems: 'flex-start',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}))
