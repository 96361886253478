import {
  Box,
  CircularProgress,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

import { displayTimerCount } from '@shared/utils'

import { TimerValueBox, TimerValueTypography } from './styled'

interface IProps {
  text?: string
  timerValue?: number
  action?: React.Dispatch<React.SetStateAction<boolean>>
  reset?: boolean
  stop?: boolean
  onSetDiff?: (diff: number) => void
  sx?: SxProps
}

export function CircularTimer({
  text,
  timerValue = 300 * 1000,
  action,
  reset,
  stop,
  onSetDiff,
  sx,
}: IProps) {
  const [diff, setDiff] = useState(timerValue)
  const timerId = useRef<NodeJS.Timer | null>(null)

  function countdownTimer(till: number) {
    const localDiff = till - Date.now()

    setDiff(localDiff)

    onSetDiff?.(localDiff)

    if (localDiff <= 0 && !stop) {
      action && action(true)

      clearInterval((!!timerId.current && +timerId.current) || 0)

      timerId.current = null
    }
  }

  useEffect(() => {
    clearInterval((!!timerId.current && +timerId.current) || 0)

    timerId.current = null

    const till = Date.now() + timerValue

    timerId.current = setInterval(() => {
      countdownTimer(till)
    }, 200)

    return () => {
      if (timerId.current) {
        clearInterval((!!timerId.current && +timerId.current) || 0)
      }
    }
  }, [reset])

  return (
    <Stack direction="row" spacing={1.5} alignItems="center" gap={4} sx={sx}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          color={'primary'}
          variant="determinate"
          size={64}
          value={(diff * 100) / timerValue}
        />

        <TimerValueBox>
          <TimerValueTypography variant={'body7'} diff={diff}>
            {displayTimerCount(diff)}
          </TimerValueTypography>
        </TimerValueBox>
      </Box>

      <Typography variant={'body5'}>{text}</Typography>
    </Stack>
  )
}
