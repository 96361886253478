import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { useMediaQuery, useTheme, Box, Stack } from '@mui/material'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import '@splidejs/react-splide/css'

import {
  useGetApplications,
  useVirtualAccountStore,
} from '@entities/virtual-account'
import { useWalletList, useWalletStore, WalletCard } from '@entities/wallet'

import { AnimationViewer, Map } from '@shared/ui'
import { tabIndexKeyUpCallback } from '@shared/utils'

import { CreateWalletButton } from '../create-wallet-button'

import { WalletInReviewCard } from './components'
import { CustomSplideWrapper, SlideButton } from './styled'

export const WalletsList = forwardRef<HTMLDivElement>(
  function WalletsList(_, ref) {
    const { data: walletList, isLoading } = useWalletList()
    const theme = useTheme()
    const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
    const splideRef = useRef<Splide>(null)
    const virtualAccount = useVirtualAccountStore((s) => s.virtualAccount)
    const setWallet = useWalletStore((s) => s.setWallet)
    const [activeIndex, setActiveIndex] = useState(0)

    const onClickSlide = (index?: number) => {
      splideRef.current?.splide?.go(index || 0)

      setActiveIndex(index || 0)
    }

    const walletsByType = useMemo(() => {
      if (!walletList) {
        return []
      }

      return walletList.filter((wallet) => wallet.type === virtualAccount?.type)
    }, [virtualAccount, walletList])

    useEffect(() => {
      if (walletsByType.length > 0) {
        onClickSlide()
      }
    }, [walletsByType])

    useEffect(() => {
      if (walletsByType.length > 0) {
        setWallet(walletsByType[0])
      }
    }, [walletsByType])

    const { data: applicationsOnReview } = useGetApplications({
      status: 'received',
    })

    const isThereApplicationsOnReview = useMemo(
      () => !!applicationsOnReview?.list.length,
      [applicationsOnReview]
    )

    return (
      <CustomSplideWrapper ref={ref}>
        {!isLoading && (
          <Splide
            onMove={(e) => {
              !mdAndUp && setActiveIndex(e?.Components.Controller.getIndex())
            }}
            hasTrack={false}
            aria-label="Partners"
            className="splide"
            ref={splideRef}
            options={{
              arrows: mdAndUp && !!(walletsByType.length > 1),
              focus: 0,
              perPage: 1,
              perMove: 1,
              autoWidth: true,
              pagination: !mdAndUp,
              drag: true,
              trimSpace: 'move',
              gap: '24px',
              padding: 0,
            }}
          >
            <SplideTrack>
              <Map
                collection={walletsByType}
                iterate={(item, index) => (
                  <SplideSlide
                    className={'click-slide'}
                    tabIndex={0}
                    key={item.id}
                    onClick={() => onClickSlide(index)}
                    onKeyUp={tabIndexKeyUpCallback(() => onClickSlide(index))}
                  >
                    <WalletCard wallet={item} active={activeIndex === index} />
                  </SplideSlide>
                )}
              />

              {isThereApplicationsOnReview &&
                applicationsOnReview?.list.map((application) => (
                  <SplideSlide key={application.id}>
                    <WalletInReviewCard accountType={application.accountType} />
                  </SplideSlide>
                ))}

              <SplideSlide>
                <CreateWalletButton />
              </SplideSlide>
            </SplideTrack>

            <Box sx={{ height: 0 }} className="splide__arrows">
              <SlideButton isBack={true} className="splide__arrow--prev">
                <ArrowForwardIosRoundedIcon />
              </SlideButton>

              <SlideButton className="splide__arrow--next">
                <ArrowForwardIosRoundedIcon />
              </SlideButton>
            </Box>
          </Splide>
        )}

        {isLoading && (
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: { xs: 'center', md: 'start' },
            }}
          >
            <AnimationViewer
              style={{ width: '92px', height: '92px' }}
              animation="searchAnimations"
            />
          </Stack>
        )}
      </CustomSplideWrapper>
    )
  }
)
