import { styled, Box, keyframes } from '@mui/material'

import Stars from '../assets/stars.svg'

const StarsAnimation = keyframes`
    100% {
      transform: translateX(-50%);
    }
  `

export const StarsBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: 1000000,
  backgroundImage: `url(${Stars})`,
  backgroundRepeat: 'repeat-x',
  animation: `${StarsAnimation} 600s infinite linear`,
}))
