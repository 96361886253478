import { Button, styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  active?: boolean
  last?: boolean
}

const contentHeight = '24px'

export const CustomButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'last',
})<IProps>(({ theme, active, last }) => ({
  gap: '3px',
  flexDirection: 'column',

  borderRadius: 0,
  padding: '10px 5px',

  ...theme.typography.subtitle5,
  lineHeight: contentHeight,
  color: active ? theme.palette.primary.main : grey[600],
  textDecoration: 'none',

  '& .MuiButton-startIcon': {
    margin: 0,
    '& svg': {
      width: contentHeight,
      height: contentHeight,
      '& path[stroke-width="1.5"]': {
        stroke: active
          ? theme.palette.primary.main
          : theme.palette.text.secondary,
      },
      '& path[fill-rule="evenodd"], & #more_vert_2': {
        fill: active
          ? theme.palette.primary.main
          : theme.palette.text.secondary,
      },
    },
  },

  '&:hover': {
    background: 'transparent',

    '& .MuiButton-endIcon path': {
      fill: theme.palette.primary.main,
    },
  },

  '& .MuiButton-endIcon': {
    marginLeft: 0,
  },

  [theme.breakpoints.up('md')]: {
    gap: '6px',
    flexDirection: 'row',

    marginRight: !last ? 24 : 0,
    borderBottom: `2px solid ${!active ? 'transparent' : theme.palette.primary.main + '!important'}`,
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: 'transparent',
      '& .MuiButton-startIcon': {
        '& path[stroke-width="1.5"]': {
          stroke: theme.palette.primary.main + '!important',
        },
        '& path[fill-rule="evenodd"], & #more_vert_2': {
          fill: theme.palette.primary.main + '!important',
        },
      },
    },
  },

  [theme.breakpoints.between('md', 'lg')]: {
    gap: '6px',
    paddingRight: '12px',
    paddingLeft: '12px',
  },

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    fontSize: 15,
    fontWeight: 400,
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    textWrap: 'nowrap',
    textDecoration: 'none',

    ...(active && {
      color: theme.palette.primary.dark,
      fontWeight: 500,
    }),

    '& .MuiButton-startIcon': {
      margin: 0,
      padding: 16,

      borderRadius: '50%',
      background: active ? theme.palette.primary.dark : grey[50],

      '& svg': {
        width: contentHeight,
        height: contentHeight,

        '& path[stroke-width="1.5"]': {
          stroke:
            (active ? theme.palette.common.white : theme.palette.text.primary) +
            '!important',
        },
        '& path[fill-rule="evenodd"], & #more_vert_2': {
          fill:
            (active ? theme.palette.common.white : theme.palette.text.primary) +
            '!important',
        },
      },
    },
  },
}))
