import { styled } from '@mui/material'

import { PaperRounded } from '@shared/ui'

export const StyledFooterWrapper = styled(PaperRounded)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  marginBottom: '12px',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    marginBottom: 'initial',
    padding: '16px 40px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '20px',
  },
}))
