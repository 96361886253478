import { ReactComponent as CloseIcon } from './assets/close-icon.svg'
import { StyledIconButton } from './styled'

interface ICrossBtn {
  onClose: () => void
  disabled?: boolean
}

export const CrossBtn = ({ onClose, disabled }: ICrossBtn) => {
  return (
    <StyledIconButton
      className="cross-btn"
      disabled={disabled}
      onClick={onClose}
    >
      <CloseIcon />
    </StyledIconButton>
  )
}
