import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { fetchWalletList, useWalletStore } from '@entities/wallet'

import { queryKeys } from '@shared/constants'

export function useWalletList() {
  const firstFetchWallet = useWalletStore((state) => state.firstFetchWallet)

  const setFirstFetchWallet = useWalletStore(
    (state) => state.setFirstFetchWallet
  )

  const query = useQuery(
    [queryKeys.walletList],
    () =>
      fetchWalletList(firstFetchWallet).then((data) => {
        if (!firstFetchWallet) {
          setFirstFetchWallet(true)
        }

        return data
      }),
    {
      refetchInterval: 5000,
    }
  )

  const wallet = useWalletStore((state) => state.wallet)
  const setWallet = useWalletStore((state) => state.setWallet)

  useEffect(() => {
    if (query.data && wallet) {
      const found = query.data.find((w) => w.id === wallet.id)

      if (!found) {
        setWallet(query.data[0])
      }
    }
  }, [query.data])

  return query
}
