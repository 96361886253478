import { styled } from '@mui/material'

import { grey } from '@shared/constants'

export const LocalLoaderBlurredShadow = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -16,
  left: -16,
  bottom: -16,
  right: -16,
  height: 'initial',
  width: 'initial',
  backdropFilter: 'blur(0px)',
  zIndex: 2,
  filter: 'blur(8px)',
  background: `${grey[100]}B2`,
  borderRadius: theme.spacing(2.25),
}))
