import { styled } from '@mui/material'

import { orange } from '@shared/constants'

import { coinsSliderArrowsPosition } from '../../constants'

interface IProps {
  isBack?: boolean
}

export const SlideButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'isBack',
})<IProps>(({ isBack, theme }) => ({
  border: 'none',
  background: theme.palette.common.white + 'c4',
  cursor: 'pointer',
  transform: 'translateY(-50%)',
  position: 'absolute',
  transition: theme.transitions.create(['opacity', 'transform']),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,

  '&:disabled': {
    display: 'none',
  },

  top: '50%',

  ...(isBack && {
    left: coinsSliderArrowsPosition,
  }),
  ...(!isBack && {
    left: 'unset !important',
    right: coinsSliderArrowsPosition,
  }),

  '& svg': {
    width: 24,
    height: 24,
  },

  '&:focus-visible': {
    outline: 'none',
    boxShadow: `0 0 0 3px ${orange[500]}`,
  },
}))
