import { Box, Button } from '@mui/material'

import BlueLogo from '@shared/assets/icons/arbiex-logo-old.svg'
import { routeNamesPaths } from '@shared/constants'

export function Logo() {
  return (
    <Button
      href={routeNamesPaths.balances}
      disableRipple
      sx={{
        '&.MuiButtonBase-root': {
          p: 0,
          minWidth: 'initial',
          '&:hover': { bgcolor: 'transparent' },
        },
      }}
    >
      <Box component="img" src={BlueLogo} alt="Arbiex logo" width={86} />
    </Button>
  )
}
