import { useEffect } from 'react'

export const useResize = (
  element?: HTMLElement | null,
  callback?: () => void
) => {
  useEffect(() => {
    if (!callback) {
      return
    }

    const resizeObserver = new ResizeObserver(callback)

    if (element) {
      resizeObserver.observe(element)
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element)
      }

      resizeObserver.disconnect()
    }
  }, [element])
}
