import {
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  CreateWalletButton,
  CreateWalletModal,
  WalletsList,
} from '@features/wallets'

import { useVirtualAccountStore } from '@entities/virtual-account'
import { useWalletStore } from '@entities/wallet'

import { mobileWalletsMarginTop } from '@shared/constants'
import { useResize } from '@shared/hooks'

import { StyledAbsoluteMobileWrapper, WalletsWrapper } from './styled'

interface IProps {
  sx?: SxProps
}

export function Wallets({ sx }: IProps) {
  const { t } = useTranslation('widgets')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const virtualAccount = useVirtualAccountStore((state) => state.virtualAccount)
  const ref = useRef<HTMLDivElement>(null)
  const setWalletHeight = useWalletStore((s) => s.setWalletsSliderHeight)

  const accountTitle = useMemo(() => {
    switch (virtualAccount?.type) {
      case 'individual':
        return t('wallets.title-personal')
      case 'corporate':
        return t('wallets.title-business')
      default:
        return t('wallets.title-unassigned')
    }
  }, [virtualAccount])

  const handleSetWalletHeight = () => {
    if (ref.current) {
      setWalletHeight(ref.current.offsetHeight + mobileWalletsMarginTop)
    }
  }

  useResize(ref.current, handleSetWalletHeight)

  return (
    <>
      {mdAndUp && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={5}
        >
          <Typography variant={'h1'} mb={1}>
            {accountTitle}
          </Typography>

          <CreateWalletButton small />
        </Stack>
      )}

      <WalletsWrapper sx={sx}>
        <StyledAbsoluteMobileWrapper>
          <WalletsList ref={ref} />
          <CreateWalletModal />
        </StyledAbsoluteMobileWrapper>
      </WalletsWrapper>
    </>
  )
}
