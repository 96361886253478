import { Paper, styled } from '@mui/material'

export const StyledDragNDropAreaWrap = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(3),
  borderRadius: 10,
  borderStyle: 'dashed',
  borderWidth: 1.5,
  borderColor: theme.palette.primary.dark,
  cursor: 'pointer',
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    backgroundColor: '#D9E0F3',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.dark,
  },
  minHeight: 175,
  height: 'auto',
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    maxWidth: 435,
  },
}))
