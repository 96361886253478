import { Box, styled } from '@mui/material'

export const ProgressBarButton = styled(Box)(({ theme }) => ({
  background: 'transparent',
  padding: '14px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& .MuiTypography-root': {
    ...theme.typography.body7,
    textAlign: 'start',
    whiteSpace: 'pre-wrap',
    borderBottom: '1px solid transparent',
  },
  '&.active .MuiTypography-root': {
    borderBottom: '1px solid white',
  },
  '& svg': {
    fill: 'none',
    stroke: 'transparent',
    width: 28,
    height: 28,
    transition: '.3s',
    '& path': {
      fill: 'transparent',
      stroke: theme.palette.primary.dark,
      transition: '.3s',
    },
  },
  '&.active svg': {
    fill: theme.palette.primary.dark,
    stroke: 'transparent',
    '& path': {
      fill: theme.palette.primary.dark,
      stroke: 'transparent',
    },
  },
}))
