import { styled } from '@mui/material'

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 220px)',
  justifyContent: 'space-between',
  marginBottom: 3,

  [theme.breakpoints.up('md')]: {
    minHeight: 'initial',
    justifyContent: 'initial',
    marginBottom: 10,
  },
}))
