import { styled } from '@mui/material'

export const StyledPercentageTypography = styled('span')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '24px',
  textAlign: 'end',
  marginLeft: 'auto',
}))
