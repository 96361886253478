import { styled } from '@mui/material'

export const StyledInsideWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto 0',
  padding: '12px 24px',
  flexWrap: 'wrap',
  position: 'relative',
}))
