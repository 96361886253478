import { Typography, styled } from '@mui/material'

export const StyledSubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 400,
  fontSize: 14,
  textAlign: 'start',
  lineHeight: 1.4,
  marginBottom: 0,
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(0.5),
    fontSize: 18,
    textAlign: 'center',
    lineHeight: 1.4,
  },
}))
