import { AxiosError } from 'axios'

import { errorHandler } from './error-handler'

export function apiErrorHandler(error: AxiosError) {
  let message: string | string[] = JSON.stringify(error)

  if (error.response) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = error.response?.data as Record<string, any>

    message = data?.error || data?.message || error.message || data?.errors
  }

  errorHandler(message)

  if (typeof message === 'object' && 'sessionId' in message) {
    return message
  }
}
