import { FormControlLabel, styled } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { useTranslation } from 'react-i18next'

import { CustomRadioInput } from '@shared/ui'

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiTypography-root': {
    color: '#4A4E5C',
  },
}))

export function PlannedMonthlyTurnover() {
  const { t } = useTranslation('entities')

  return (
    <Grid2 container columnSpacing={4.5}>
      <Grid2 xs={12} md={6}>
        <StyledFormControlLabel
          value="Up to 10 000 EUR"
          control={<CustomRadioInput />}
          label={t('planned monthly turnover.Up to 10 000 EUR')}
        />
      </Grid2>

      <Grid2 xs={12} md={6}>
        <StyledFormControlLabel
          value="From 10 000 EUR to 50 000 EUR"
          control={<CustomRadioInput />}
          label={t('planned monthly turnover.From 10 000 EUR to 50 000 EUR')}
        />
      </Grid2>

      <Grid2 xs={12} md={6}>
        <StyledFormControlLabel
          value="From 50 000 EUR to 250 000 EUR"
          control={<CustomRadioInput />}
          label={t('planned monthly turnover.From 50 000 EUR to 250 000 EUR')}
        />
      </Grid2>

      <Grid2 xs={12} md={6}>
        <StyledFormControlLabel
          value="More than 250 000 EUR"
          control={<CustomRadioInput />}
          label={t('planned monthly turnover.More than 250 000 EUR')}
        />
      </Grid2>
    </Grid2>
  )
}
