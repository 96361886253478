import { Button, styled } from '@mui/material'

interface IProps {
  component?: React.ElementType
}

export const StyledButton = styled(Button)<IProps>(({ theme }) => ({
  width: '100%',
  paddingLeft: 0 + '!important',
  paddingRight: 0 + '!important',
  gap: '12px',
  justifyContent: 'flex-start',
  alignItems: 'center',

  '&:hover': {
    backgroundColor: 'transparent',

    [theme.breakpoints.up('md')]: {
      cursor: 'initial',
    },
  },

  [theme.breakpoints.up('md')]: {
    width: 'initial',
    padding: 0 + '!important',
  },
}))
