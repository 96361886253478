import { keyframes } from '@emotion/react'
import { styled } from '@mui/material'

import Stars from '../../assets/stars.svg'

const StarsAnimation = keyframes`
    100% {
      transform: translateX(-50%);
    }
    `

export const StarsBox = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -5,
  bottom: -5,
  width: 1000000,
  backgroundImage: `url(${Stars})`,
  backgroundRepeat: 'repeat-x',
  backgroundSize: 'contain',
  backgroundColor: theme.palette.primary.dark,
  animation: `${StarsAnimation} 600s infinite linear`,
}))
