import { styled, TextField } from '@mui/material'

export const StyledTextField = styled(TextField)(({ theme }) => ({
  perspective: '1000px',
  '& > div': {
    borderRadius: 8,
    padding: 8,
  },
  '& input': {
    padding: 0,
    height: 32,
    lineHeight: '24px',
    fontFeatureSettings: "'zero' on",
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
      lineHeight: '22px',
    },
    '&::placeholder': {
      fontWeight: 400,
    },
    '&:-webkit-autofill, &:-webkit-autofill:hover , &:-webkit-autofill:focus': {
      WebkitTextFillColor: 'currentColor',
      WebkitBoxShadow: '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
    },
  },
  '& fieldset': {
    borderWidth: '1px !important',
  },
  '& .MuiButtonBase-root': {
    transition: theme.transitions.create(['transform', 'background-color'], {
      duration: theme.transitions.duration.standard,
    }),
    '&.MuiAutocomplete-popupIndicatorOpen': {
      transform: 'rotateX(180deg)',
    },
  },
  '& .MuiInputBase-root.Mui-focused': {
    '& .MuiSvgIcon-root': {
      width: 'auto',
      opacity: '1',
      transition: '300ms',
    },
    '& fieldset': {
      borderWidth: '2px !important',
    },
  },
  '& textarea': {
    paddingLeft: 12,
    paddingRight: 12,
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    fontFeatureSettings: "'zero' on",
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
      lineHeight: '22px',
    },
    '&::placeholder': {
      fontWeight: 400,
    },
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
    marginRight: 0,
  },

  '& .MuiChip-label': {
    lineHeight: 'normal',
  },
}))
