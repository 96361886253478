import { styled } from '@mui/material'

export const ListRowWarning = styled('li')(({ theme }) => ({
  background: '#FEE5B1',
  boxShadow: '0px 4px 8px -8px rgba(0, 0, 0, 0.25)',
  borderRadius: 8,
  [theme.breakpoints.up('md')]: {
    boxShadow: 'none',
    borderRadius: 4,
  },
}))
