import { styled } from '@mui/material'

export const StyledPercentageContainer = styled('div')(({ theme }) => ({
  padding: '4px 8px',
  backgroundColor: theme.palette.background.paper,
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    padding: '4px 0',
  },
}))
