import { IconButton, styled } from '@mui/material'

export const AccountSwitcherStyledButton = styled(IconButton)(({ theme }) => ({
  padding: '4px',
  borderWidth: '1px',
  borderRadius: '6px',
  borderStyle: 'solid',
  backgroundColor: 'transparent',
  color: theme.palette.primary.dark,
  fontSize: 24,
}))
