import { styled } from '@mui/material'

export const WrapperForSecondPartWalletFormPage = styled('div')(
  ({ theme }) => ({
    padding: '20px 20px',
    border: `1px solid #D5DEE5`,
    boxShadow: '3px 3px 10px #585C5F26',
    borderRadius: '10px',

    [theme.breakpoints.up('md')]: {
      boxShadow: '3px 3px 10px #585C5F26',
      padding: '30px 30px',
    },
  })
)
