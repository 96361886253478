import { useEffect } from 'react'

import { useMobileWebviewStore } from './use-mobile-webview-store'

export function useSetIsMobile() {
  const setIsMobile = useMobileWebviewStore((state) => state.setIsMobile)

  useEffect(() => {
    window.setIsMobile = setIsMobile
  }, [])
}
