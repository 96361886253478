import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { TBlockchain } from '@shared/types'

import { cardsContainerPaddingX } from '../constants'

import { DesktopBlockchainList } from './desktop-blockchain-list'
import { ReactComponent as BitcoinIcon } from './icons/bitcoin.svg'
import { ReactComponent as EthereumIcon } from './icons/ethereum.svg'
import { ReactComponent as TronIcon } from './icons/tron.svg'
import { MobileBlockchainList } from './mobile-blockchain-list'

interface IProps {
  activeBlockchain: TBlockchain | null
  setActiveBlockchain: (value: TBlockchain | null) => void
  disabled: boolean
}

export interface ICoinsList {
  icon: ReactElement
  name: string
  key: TBlockchain
  disabled?: boolean
  sx?: SxProps
}

export function ChooseBlockchain({
  activeBlockchain,
  setActiveBlockchain,
  disabled,
}: IProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation('features')

  const coinsList: ICoinsList[] = [
    {
      icon: <TronIcon />,
      name: 'TRC-20',
      key: 'tron',
      disabled,
      sx: {
        '& .icon svg': {
          width: 70,
          [theme.breakpoints.up('md')]: {
            width: 96,
          },
        },
      },
    },
    {
      icon: <EthereumIcon />,
      name: 'ERC-20',
      key: 'ethereum',
      disabled: true,
      sx: {
        '& .icon svg': {
          width: 80,
          [theme.breakpoints.up('md')]: {
            width: 128,
          },
        },
      },
    },
    {
      icon: <BitcoinIcon />,
      name: 'BTC',
      key: 'bitcoin',
      disabled: true,
      sx: {
        '& .icon svg': {
          width: 66,
          [theme.breakpoints.up('md')]: {
            width: 104,
          },
        },
      },
    },
  ]

  return (
    <Box mb={4}>
      <Typography variant="body3" mb={2}>
        {t('create wallet.choose network')}
      </Typography>

      <Box px={cardsContainerPaddingX}>
        {mdAndUp && (
          <DesktopBlockchainList
            coinsList={coinsList}
            setActiveBlockchain={setActiveBlockchain}
            activeBlockchain={activeBlockchain}
          />
        )}

        {!mdAndUp && (
          <MobileBlockchainList
            coinsList={coinsList}
            setActiveBlockchain={setActiveBlockchain}
            activeBlockchain={activeBlockchain}
          />
        )}
      </Box>
    </Box>
  )
}
