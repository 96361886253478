import { styled, Box } from '@mui/material'

export const StyledBox = styled(Box)(() => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
