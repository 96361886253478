import { styled } from '@mui/material'

export const NotificationBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hidden',
})<{ hidden?: boolean }>(({ theme, hidden }) => ({
  position: 'relative',
  top: -16,
  zIndex: 2,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  background: '#D60033',
  height: hidden ? 0 : 44,
  paddingBottom: hidden ? 0 : 7,
  paddingTop: hidden ? 0 : 16,
  paddingLeft: 8,
  paddingRight: 8,
  [theme.breakpoints.up('lg')]: {
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    justifyContent: 'center',
    paddingBottom: 5,
    paddingTop: 21,
    top: -14,
  },
}))
