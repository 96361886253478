import { styled } from '@mui/material'

export const CustomDivider = styled('div')(({ theme }) => ({
  minHeight: '100%',
  width: '1px',
  backgroundColor: theme.palette.primary.dark,
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    height: '5px',
    width: '5px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}))
