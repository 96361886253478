import { api } from '@shared/http-instance'

import { IApplication, IVirtualAccount, TApplicationStatus } from '../model'

interface IResponse {
  list: IApplication[]
  curPage: number
  perPage: number
  totalPages: number
}

interface IRequest {
  curPage?: number
  perPage?: number
  accountType?: IVirtualAccount['type']
  status?: TApplicationStatus
}

export async function fetchApplications({
  accountType,
  curPage = 1,
  perPage = 50,
  status,
}: IRequest) {
  const url = '/applications'
  const curPageParam = `cur-page=${curPage}`
  const perPageParam = `&per-page=${perPage}`
  const statusParam = status ? `&status=${status}` : ''
  const accountTypeParam = accountType ? `&account-type=${accountType}` : ''

  const response = await api.get<IResponse>(
    `${url}?${curPageParam}${perPageParam}${statusParam}${accountTypeParam}`
  )

  return response.data
}
