import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface IGlobalLoaderState {
  show: boolean
  setShow: (show: boolean) => void
}

export const useAppLoaderStore = create<IGlobalLoaderState>()(
  devtools(
    (set) => ({
      show: false,
      setShow: (show: boolean) => set({ show }),
    }),
    { name: 'app-loader-store' }
  )
)
