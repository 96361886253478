import { SxProps, Typography } from '@mui/material'

import { NotificationBoxButton, NotificationBox } from './styled'

interface IProps {
  message: string
  buttonText: string
  buttonAction?: () => void
  buttonHref?: string
  sx?: SxProps
}

export function NotificationBar({
  message,
  buttonAction,
  buttonHref,
  buttonText,
  sx,
}: IProps) {
  return (
    <NotificationBox sx={sx}>
      <Typography
        fontWeight={500}
        fontSize={{ xs: 14, lg: 16 }}
        lineHeight={{ xs: '14px', lg: '18px' }}
      >
        {message}
      </Typography>

      <NotificationBoxButton
        href={buttonHref}
        onClick={buttonAction}
        variant="outlined"
      >
        {buttonText}
      </NotificationBoxButton>
    </NotificationBox>
  )
}
