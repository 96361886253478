import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { IWallet } from '../model'

interface IWalletState {
  wallet: IWallet | null
  setWallet: (wallet: IWallet | null) => void

  walletsSliderHeight: number
  setWalletsSliderHeight: (payload: number) => void

  firstFetchWallet: boolean
  setFirstFetchWallet: (payload: boolean) => void
}

export const useWalletStore = create<IWalletState>()(
  devtools(
    (set) => ({
      wallet: null,
      setWallet: (wallet) => set({ wallet }),

      walletsSliderHeight: 0,
      setWalletsSliderHeight: (walletsSliderHeight) =>
        set({ walletsSliderHeight }),

      firstFetchWallet: false,
      setFirstFetchWallet: (firstFetchWallet) => set({ firstFetchWallet }),
    }),
    {
      name: 'walletStore',
    }
  )
)
