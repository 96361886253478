import { styled } from '@mui/material'

import { grey } from '@shared/constants'

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
  height: `100vh`,
  width: '100vw',
  top: 0,
  left: 0,
  background: `${grey[100]}B2`,
  backdropFilter: 'blur(10px)',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
}))
