import { SxProps } from '@mui/material'
import React, { ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react'

import { DragNDropArea } from '@shared/ui'

interface IProps {
  onClickUpload: () => void
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void
  onSelectFiles: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean | ReactElement
  fileList: ReactNode
  uploadDocTitle: string
  sx?: SxProps
}

export const DragNDropAreaWrapper = forwardRef(function DragNDropPaperFunc(
  {
    onClickUpload,
    onDrop,
    onDragOver,
    onSelectFiles,
    fileList,
    error,
    uploadDocTitle,
    sx,
  }: IProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <>
      <DragNDropArea
        ref={ref}
        onClickUpload={onClickUpload}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onSelectFiles={onSelectFiles}
        uploadDocTitle={uploadDocTitle}
        sx={sx}
      />

      {fileList}
      {error}
    </>
  )
})
