import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { HelpCenterAccordion } from '@features/app-bar'

import { routeNamesPaths } from '@shared/constants'
import { useLogoutHandler } from '@shared/hooks'

import { IMenuItem } from '../types'

import { ReactComponent as LogoutIcon } from './assets/logout.svg'
import { ReactComponent as SettingsIcon } from './assets/user-icon.svg'

export function useProfileMenuList() {
  const theme = useTheme()
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'))
  const logoutHandler = useLogoutHandler()
  const { t } = useTranslation('features')

  const menu: IMenuItem[] = [
    {
      title: t('app-bar.user-menu.profile'),
      icon: SettingsIcon,
      href: routeNamesPaths.profile,
    },
    ...(!smAndUp
      ? [
          {
            title: <HelpCenterAccordion />,
            disableClose: true,
          },
        ]
      : []),
    {
      title: t('app-bar.user-menu.logout'),
      icon: LogoutIcon,
      action: () => logoutHandler(),
    },
  ]

  return menu
}
