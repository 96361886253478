import { useConfirm } from 'material-ui-confirm'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@shared/auth'

export function useLogoutHandler() {
  const confirm = useConfirm()
  const { logout } = useAuth()
  const { t } = useTranslation('shared')

  const logoutHandler = () => {
    confirm({
      title: t('logout-confirmation.Logout'),
      description: t(
        'logout-confirmation.Are you sure you want to exit the app'
      ),
    }).then(() => {
      logout()
    })
  }

  return logoutHandler
}
