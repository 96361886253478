import { Container, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useMobileWebviewStore } from '@shared/hooks'

import { ReactComponent as FacebookIcon } from './assets/facebook-icon.svg'
import { ReactComponent as InstagramIcon } from './assets/instagram-icon.svg'
import { ReactComponent as LinkedInIcon } from './assets/linkedIn-icon.svg'
import { StyledFooterWrapper, StyledIconsWrapper } from './styled'

interface ISocialMediaBtns {
  icon: JSX.Element
  href: string
}

const socialMediaBtns: ISocialMediaBtns[] = [
  { icon: <LinkedInIcon />, href: 'https://www.linkedin.com/company/arbiex/' },
  { icon: <FacebookIcon />, href: 'https://www.facebook.com/arbiex.eu' },
  {
    icon: <InstagramIcon />,
    href: 'https://www.instagram.com/arbiexeu_crypto/?igsh=MWVyN2o0OTVhNzN6cg%3D%3D',
  },
]

export const Footer = () => {
  const isMobile = useMobileWebviewStore((state) => state.isMobile)

  const { t } = useTranslation('widgets')

  const sendPostMessageLink = useMobileWebviewStore(
    (state) => state.sendPostMessageLink
  )

  return (
    <Container maxWidth="xl">
      <StyledFooterWrapper transparent>
        <Typography variant={'body10'} color={'text.secondary'}>
          {t('footer')}
        </Typography>

        <Stack direction={'row'} gap={2}>
          {socialMediaBtns.map((btn, index) => (
            <StyledIconsWrapper
              onClick={() => {
                isMobile && btn.href && sendPostMessageLink(btn.href)
              }}
              disableFocusRipple
              component={isMobile ? 'div' : undefined}
              isInstagramIcon={btn.href.includes('instagram')}
              key={index}
              target={'_blank'}
              href={isMobile ? undefined : btn.href}
            >
              {btn.icon}
            </StyledIconsWrapper>
          ))}
        </Stack>
      </StyledFooterWrapper>
    </Container>
  )
}
