import { styled, Typography } from '@mui/material'

export const StyledDirectorName = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  fontSize: 18,
  fontWeight: 400,
  color: theme.palette.primary.dark,

  gridRowStart: 2,
  gridColumnStart: 1,

  [theme.breakpoints.up('md')]: {
    gridRowStart: 1,
    gridRowEnd: 2,
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
}))
