import { PropsWithChildren } from 'react'

import { ConfirmationProvider } from './confirmation-provider'
import { KeycloakProvider } from './keycloak-provider'
import { ReactQueryProvider } from './react-query-provider'
import LandingThemeProvider from './theme'
import { TimeLocaleProvider } from './time-locale-provider'

export function ComposeProvider({ children }: PropsWithChildren) {
  return (
    <LandingThemeProvider>
      <KeycloakProvider>
        <ReactQueryProvider>
          <ConfirmationProvider>
            <TimeLocaleProvider>{children}</TimeLocaleProvider>
          </ConfirmationProvider>
        </ReactQueryProvider>
      </KeycloakProvider>
    </LandingThemeProvider>
  )
}
