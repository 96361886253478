import { useTranslation } from 'react-i18next'

import { routeNamesPaths } from '@shared/constants'

import { IMenuItem } from '../types'

import { ReactComponent as BuyCryptoIcon } from './assets/buy-crypto.svg'
import { ReactComponent as SellCryptoIcon } from './assets/sell-crypto.svg'
import { ReactComponent as TransactionsIcon } from './assets/transactions.svg'

export function useFiatMenuList() {
  const { t } = useTranslation('features')

  const menu: IMenuItem[] = [
    {
      title: t('app-bar.user-menu.deposit'),
      icon: BuyCryptoIcon,
      href: routeNamesPaths.deposit,
    },
    {
      title: t('app-bar.user-menu.withdraw'),
      icon: SellCryptoIcon,
      href: routeNamesPaths.withdraw,
    },
    {
      title: t('app-bar.user-menu.transactions'),
      icon: TransactionsIcon,
      href: routeNamesPaths.withdrawTransactions,
    },
  ]

  return menu
}
