import {
  Container,
  Stack,
  SxProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import { AppBar } from '@widgets/app-bar'
import { Footer } from '@widgets/footer'
import { PageTabSwitcher } from '@widgets/page-tab-switcher'
import { Wallets } from '@widgets/wallets'

import { NotVerified } from '@features/not-verified'
import { WelcomeDialog } from '@features/welcome-dialog'

import { AppLoader, useAppLoaderStore } from '@entities/app-loader'
import { useVirtualAccountList } from '@entities/virtual-account'

import { externalLinks } from '@shared/constants'
import {
  NoWalletsMessage,
  PaperRounded,
  WrapperForMainLayout,
  WrapperForOutletWithWallets,
} from '@shared/ui'

import { useMainLayout } from './hooks'

type TPaddingVariant = 'form' | 'list'

interface IProps {
  paddingVariant?: TPaddingVariant
}

export function MainWalletLayout({ paddingVariant = 'form' }: IProps) {
  const { t } = useTranslation('app')
  const theme = useTheme()
  const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'))
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const setShowLoader = useAppLoaderStore((state) => state.setShow)
  const pageTabSwitcherRef = useRef<HTMLDivElement>(null)

  const { isVerified, userDetailLoaded } = useMainLayout()

  const { isFetched: isVirtualAccountFetched, data: virtualAccountsList } =
    useVirtualAccountList()

  const isVirtualAccountsList = useMemo(() => {
    return !!virtualAccountsList?.length
  }, [virtualAccountsList])

  useEffect(() => {
    if (isVirtualAccountFetched && userDetailLoaded) {
      setShowLoader(false)
    }
  }, [isVirtualAccountFetched, userDetailLoaded])

  const pageTabSwitcherStyles: SxProps = {
    pb: { xs: 0, md: 3 },
  }

  return (
    <WrapperForMainLayout>
      <ScrollRestoration />
      <AppBar showNotificationBar={!isVerified} />

      {isVerified && !mdAndUp && (
        <>
          <Wallets sx={{ mb: 2 }} />

          <PageTabSwitcher
            template={'wallet'}
            sx={pageTabSwitcherStyles}
            ref={pageTabSwitcherRef}
          />
        </>
      )}

      <Container
        disableGutters={!lgAndUp}
        maxWidth={'xl'}
        sx={{
          mt: { xs: 0, md: 3.5 },
          pb: 3,
          flex: 1,
          minHeight: 'max-content',
        }}
      >
        <Stack direction="row" gap={5} justifyContent={'space'}>
          <Container
            maxWidth="xl"
            disableGutters
            sx={{
              transition: theme.transitions.create(['width', 'height']),
            }}
          >
            {!isVerified && (
              <PaperRounded transparent={!mdAndUp} disableGutters={!mdAndUp}>
                <NotVerified
                  title={t('not verified.title')}
                  buttonText={t('not verified.button')}
                  description={t('not verified.description')}
                  href={externalLinks.idv}
                />
              </PaperRounded>
            )}

            {isVerified && (
              <PaperRounded sx={{ px: { xs: 2, md: 6 } }}>
                {mdAndUp && <Wallets sx={{ mb: 5 }} />}

                {mdAndUp && (
                  <PageTabSwitcher
                    template={'wallet'}
                    sx={pageTabSwitcherStyles}
                  />
                )}

                <WrapperForOutletWithWallets
                  variant={isVirtualAccountsList ? paddingVariant : 'form'}
                >
                  {isVirtualAccountsList ? <Outlet /> : <NoWalletsMessage />}
                </WrapperForOutletWithWallets>
              </PaperRounded>
            )}
          </Container>
        </Stack>
      </Container>

      <Footer />
      <AppLoader />
      <WelcomeDialog />
    </WrapperForMainLayout>
  )
}
