import { styled } from '@mui/material'

import { deepBlue } from '@shared/constants'

export const BuySellBox = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 52,
  display: 'flex',
  flexDirection: 'row',
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: '10px',

  '&:hover': {
    backgroundColor: deepBlue[50],
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: 310,
  },
}))
