export const grey = {
  50: '#F2F2F3',
  100: '#E4E4E7',
  200: '#CACACE',
  300: '#AFAFB6',
  400: '#94949E',
  500: '#82828D',
  600: '#61616B',
  700: '#494950',
  800: '#313135',
  900: '#18181B',
  950: '#0C0C0D',
}
