import { styled } from '@mui/material'

export const RocketBox = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
