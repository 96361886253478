import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

import { NoInternetPage } from './ui'

export const NoInternetPageRoute: RouteObject = {
  path: routeNamesPaths.noInternet,
  element: <NoInternetPage />,
}
