import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const ExchangeRoute: RouteObject = {
  path: routeNamesPaths.exchange,
  lazy: async () => {
    const { Exchange } = await import('./ui')

    return { Component: Exchange }
  },
}
