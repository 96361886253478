import { styled } from '@mui/material'

export const StyledSecondWarningBlock = styled('div')(({ theme }) => ({
  display: 'none',
  flexDirection: 'column',
  gap: '12px',
  background: '#F1F2F6',
  borderRadius: '10px',
  padding: '20px 20px',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    padding: '16px 24px',
    gap: '8px',
  },
}))
