import { AccordionSummary, styled } from '@mui/material'

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  fontSize: 24,
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  minHeight: 52,
  '& .MuiAccordionSummary-content': {
    m: 0,
    '&.Mui-expanded': {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 12,
      marginBottom: 12,
    },
  },
  borderBottom: `1px solid`,
  borderBottomColor: 'transparent',
  transition: theme.transitions.create(['border-color'], {
    duration: theme.transitions.duration.standard,
  }),
  '&.Mui-expanded': {
    borderBottomColor: theme.palette.primary.dark,
    minHeight: 52,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    perspective: 10000,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
    }),
    '&.Mui-expanded': {
      transform: 'rotateX(180deg)',
    },
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(4.5),
    paddingRight: theme.spacing(4.5),
    height: 56,
  },
}))
