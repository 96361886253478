import { Link, styled } from '@mui/material'

interface IProps {
  active: boolean
}

export const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'active',
})<IProps>(({ theme, active }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(0.75),
  padding: '12px 0',
  borderBottom: '1px solid #EAEAEA',
  borderRadius: theme.spacing(0.5),
  textDecoration: 'none',
  transition: theme.transitions.create('background-color', {
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '4px 8px',
    borderBottom: 'none',
    justifyContent: 'flex-start',
    ...(active && { backgroundColor: '#E8F1FF' }),
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  },
}))
