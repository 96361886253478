import { TCryptoCoinKey } from '@shared/constants'

import { coinComponents, defaultComponent } from '../constants'

export const currentCoin = (name: TCryptoCoinKey): JSX.Element => {
  return coinComponents[name]?.current || defaultComponent.current
}

export const currentIssueCoin = (name: TCryptoCoinKey): JSX.Element => {
  return coinComponents[name]?.issue || defaultComponent.issue
}

export const currentColorCoin = (name: TCryptoCoinKey): JSX.Element => {
  return coinComponents[name]?.color || defaultComponent.color
}
