import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface ICreateWalletStore {
  show: boolean
  setShow: (show: boolean) => void
}

export const useCreateWalletStore = create<ICreateWalletStore>()(
  devtools(
    (set) => ({
      show: false,
      setShow: (show: boolean) => set({ show }),
    }),
    { name: 'create-wallet-store' }
  )
)
