export interface IBusinessTypeCategory {
  id: number
  name: string
  code: string
  selectable: boolean
  parentId?: number
}

export const businessTypeCategories: { records: IBusinessTypeCategory[] } = {
  records: [
    {
      id: 1,
      name: 'AGRICULTURE, FORESTRY AND FISHING',
      code: 'A',
      selectable: false,
    },
    {
      id: 2,
      name: 'Crop and animal production, hunting and related service activities',
      code: '01',
      selectable: false,
      parentId: 1,
    },
    {
      id: 3,
      name: 'Growing of non-perennial crops',
      code: '01.1',
      selectable: false,
      parentId: 2,
    },
    {
      id: 4,
      name: 'Growing of cereals (except rice), leguminous crops and oil seeds',
      code: '01.11',
      selectable: true,
      parentId: 3,
    },
    {
      id: 5,
      name: 'Growing of rice',
      code: '01.12',
      selectable: true,
      parentId: 3,
    },
    {
      id: 6,
      name: 'Growing of vegetables and melons, roots and tubers',
      code: '01.13',
      selectable: true,
      parentId: 3,
    },
    {
      id: 7,
      name: 'Growing of potatoes',
      code: '01.13.10',
      selectable: true,
      parentId: 3,
    },
    {
      id: 8,
      name: 'Growing of sugar beet',
      code: '01.13.20',
      selectable: true,
      parentId: 3,
    },
    {
      id: 9,
      name: 'Growing of vegetables in the open',
      code: '01.13.30',
      selectable: true,
      parentId: 3,
    },
    {
      id: 10,
      name: 'Growing of vegetables under cover',
      code: '01.13.40',
      selectable: true,
      parentId: 3,
    },
    {
      id: 11,
      name: 'Production of seeds for vegetables',
      code: '01.13.50',
      selectable: true,
      parentId: 3,
    },
    {
      id: 12,
      name: 'Growing of mushrooms and truffles',
      code: '01.13.60',
      selectable: true,
      parentId: 3,
    },
    {
      id: 13,
      name: 'Growing of sugar cane',
      code: '01.14',
      selectable: true,
      parentId: 3,
    },
    {
      id: 14,
      name: 'Growing of tobacco',
      code: '01.15',
      selectable: true,
      parentId: 3,
    },
    {
      id: 15,
      name: 'Growing of fibre crops',
      code: '01.16',
      selectable: true,
      parentId: 3,
    },
    {
      id: 16,
      name: 'Growing of other non-perennial crops',
      code: '01.19',
      selectable: true,
      parentId: 3,
    },
    {
      id: 17,
      name: 'Growing of flowers',
      code: '01.19.10',
      selectable: true,
      parentId: 3,
    },
    {
      id: 18,
      name: 'Growing of perennial crops',
      code: '01.2',
      selectable: false,
      parentId: 2,
    },
    {
      id: 19,
      name: 'Growing of grapes',
      code: '01.21',
      selectable: true,
      parentId: 18,
    },
    {
      id: 20,
      name: 'Growing of tropical and subtropical fruits',
      code: '01.22',
      selectable: true,
      parentId: 18,
    },
    {
      id: 21,
      name: 'Growing of citrus fruits',
      code: '01.23',
      selectable: true,
      parentId: 18,
    },
    {
      id: 22,
      name: 'Growing of pome fruits and stone fruits',
      code: '01.24',
      selectable: true,
      parentId: 18,
    },
    {
      id: 23,
      name: 'Growing of other tree and bush fruits and nuts',
      code: '01.25',
      selectable: true,
      parentId: 18,
    },
    {
      id: 24,
      name: 'Growing of oleaginous fruits',
      code: '01.26',
      selectable: true,
      parentId: 18,
    },
    {
      id: 25,
      name: 'Growing of beverage crops',
      code: '01.27',
      selectable: true,
      parentId: 18,
    },
    {
      id: 26,
      name: 'Growing of spices, aromatic, drug and pharmaceutical crops',
      code: '01.28',
      selectable: true,
      parentId: 18,
    },
    {
      id: 27,
      name: 'Growing of other perennial crops',
      code: '01.29',
      selectable: true,
      parentId: 18,
    },
    {
      id: 28,
      name: 'Plant propagation',
      code: '01.3',
      selectable: false,
      parentId: 2,
    },
    {
      id: 29,
      name: 'Plant propagation',
      code: '01.30',
      selectable: true,
      parentId: 28,
    },
    {
      id: 30,
      name: 'Animal production',
      code: '01.4',
      selectable: true,
      parentId: 2,
    },
    {
      id: 31,
      name: 'Raising of dairy cattle',
      code: '01.41',
      selectable: true,
      parentId: 2,
    },
    {
      id: 32,
      name: 'Raising of other cattle and buffaloes',
      code: '01.42',
      selectable: true,
      parentId: 2,
    },
    {
      id: 33,
      name: 'Farming and commercial fattening of cattle',
      code: '01.42.10',
      selectable: true,
      parentId: 2,
    },
    {
      id: 34,
      name: 'Farming of pedigree heifers',
      code: '01.42.20',
      selectable: true,
      parentId: 2,
    },
    {
      id: 35,
      name: 'Farming of pedigree bulls',
      code: '01.42.30',
      selectable: true,
      parentId: 2,
    },
    {
      id: 36,
      name: 'Production of pedigree bull semen',
      code: '01.42.40',
      selectable: true,
      parentId: 2,
    },
    {
      id: 37,
      name: 'Raising of horses and other equines',
      code: '01.43',
      selectable: true,
      parentId: 2,
    },
    {
      id: 38,
      name: 'Raising of camels and camelids',
      code: '01.44',
      selectable: true,
      parentId: 2,
    },
    {
      id: 39,
      name: 'Raising of sheep and goats',
      code: '01.45',
      selectable: false,
      parentId: 2,
    },
    {
      id: 40,
      name: 'Farming of sheep',
      code: '01.45.10',
      selectable: true,
      parentId: 39,
    },
    {
      id: 41,
      name: 'Farming of goats',
      code: '01.45.20',
      selectable: true,
      parentId: 39,
    },
    {
      id: 42,
      name: 'Raising of swine/pigs',
      code: '01.46',
      selectable: true,
      parentId: 2,
    },
    {
      id: 43,
      name: 'Farming and commercial fattening of pigs',
      code: '01.46.10',
      selectable: true,
      parentId: 2,
    },
    {
      id: 44,
      name: 'Farming of pedigree pigs',
      code: '01.46.20',
      selectable: true,
      parentId: 2,
    },
    {
      id: 45,
      name: 'Raising of poultry',
      code: '01.47',
      selectable: true,
      parentId: 2,
    },
    {
      id: 46,
      name: 'Raising of poultry for meat and production of eggs',
      code: '01.47.10',
      selectable: true,
      parentId: 2,
    },
    {
      id: 47,
      name: 'Raising of pedigree poultry',
      code: '01.47.20',
      selectable: true,
      parentId: 2,
    },
    {
      id: 48,
      name: 'Raising of other animals',
      code: '01.49',
      selectable: true,
      parentId: 2,
    },
    {
      id: 49,
      name: 'Raising of fur animals',
      code: '01.49.10',
      selectable: true,
      parentId: 2,
    },
    {
      id: 50,
      name: 'Beekeeping',
      code: '01.49.20',
      selectable: true,
      parentId: 2,
    },
    {
      id: 51,
      name: 'Growing of grapevine snails and worms',
      code: '01.49.30',
      selectable: false,
      parentId: 2,
    },
    {
      id: 52,
      name: 'Mixed farming',
      code: '01.5',
      selectable: false,
      parentId: 2,
    },
    {
      id: 53,
      name: 'Mixed farming',
      code: '01.50',
      selectable: true,
      parentId: 52,
    },
    {
      id: 54,
      name: 'Support activities to agriculture and post-harvest crop activities',
      code: '01.6',
      selectable: true,
      parentId: 2,
    },
    {
      id: 55,
      name: 'Support activities for crop production',
      code: '01.61',
      selectable: true,
      parentId: 2,
    },
    {
      id: 56,
      name: 'Agricultural crop protection from diseases, pests and weeds',
      code: '01.61.10',
      selectable: true,
      parentId: 2,
    },
    {
      id: 57,
      name: 'Fertilisation of agricultural crops with mineral and organic fertilisers',
      code: '01.61.20',
      selectable: true,
      parentId: 2,
    },
    {
      id: 58,
      name: 'Support activities for animal production',
      code: '01.62',
      selectable: true,
      parentId: 2,
    },
    {
      id: 59,
      name: 'Artificial insemination',
      code: '01.62.10',
      selectable: true,
      parentId: 2,
    },
    {
      id: 60,
      name: 'Post-harvest crop activities',
      code: '01.63',
      selectable: true,
      parentId: 2,
    },
    {
      id: 61,
      name: 'Seed processing for propagation',
      code: '01.64',
      selectable: true,
      parentId: 2,
    },
    {
      id: 62,
      name: 'Hunting, trapping and related service activities',
      code: '01.7',
      selectable: false,
      parentId: 2,
    },
    {
      id: 63,
      name: 'Hunting, trapping and related service activities',
      code: '01.70',
      selectable: true,
      parentId: 62,
    },
    {
      id: 64,
      name: 'Forestry and logging',
      code: '02',
      selectable: false,
      parentId: 1,
    },
    {
      id: 65,
      name: 'Silviculture and other forestry activities',
      code: '02.1',
      selectable: false,
      parentId: 64,
    },
    {
      id: 66,
      name: 'Silviculture and other forestry activities',
      code: '02.10',
      selectable: true,
      parentId: 65,
    },
    {
      id: 67,
      name: 'Logging',
      code: '02.2',
      selectable: false,
      parentId: 64,
    },
    {
      id: 68,
      name: 'Logging',
      code: '02.20',
      selectable: true,
      parentId: 65,
    },
    {
      id: 69,
      name: 'Gathering of wild growing non-wood products',
      code: '02.3',
      selectable: false,
      parentId: 64,
    },
    {
      id: 70,
      name: 'Gathering of wild growing non-wood products',
      code: '02.30',
      selectable: true,
      parentId: 65,
    },
    {
      id: 71,
      name: 'Picking wild mushrooms',
      code: '02.30.10',
      selectable: true,
      parentId: 65,
    },
    {
      id: 72,
      name: 'Picking nuts and wild berries',
      code: '02.30.20',
      selectable: true,
      parentId: 65,
    },
    {
      id: 73,
      name: 'Support services to forestry',
      code: '02.4',
      selectable: false,
      parentId: 64,
    },
    {
      id: 74,
      name: 'Support services to forestry',
      code: '02.40',
      selectable: true,
      parentId: 65,
    },
    {
      id: 75,
      name: 'Fishing and aquaculture',
      code: '03',
      selectable: false,
      parentId: 1,
    },
    {
      id: 76,
      name: 'Fishing',
      code: '03.1',
      selectable: true,
      parentId: 75,
    },
    {
      id: 77,
      name: 'Marine fishing',
      code: '03.11',
      selectable: true,
      parentId: 75,
    },
    {
      id: 78,
      name: 'Processing of fish on fishing vessels',
      code: '03.11.10',
      selectable: true,
      parentId: 75,
    },
    {
      id: 79,
      name: 'Freshwater fishing',
      code: '03.12',
      selectable: true,
      parentId: 75,
    },
    {
      id: 80,
      name: 'Aquaculture',
      code: '03.2',
      selectable: true,
      parentId: 75,
    },
    {
      id: 81,
      name: 'Marine aquaculture',
      code: '03.21',
      selectable: true,
      parentId: 75,
    },
    {
      id: 82,
      name: 'Freshwater aquaculture',
      code: '03.22',
      selectable: true,
      parentId: 75,
    },
    {
      id: 83,
      name: 'MINING AND QUARRYING',
      code: 'B',
      selectable: false,
    },
    {
      id: 84,
      name: 'Mining of coal and lignite',
      code: '05',
      selectable: false,
      parentId: 83,
    },
    {
      id: 85,
      name: 'Mining of hard coal',
      code: '05.1',
      selectable: false,
      parentId: 84,
    },
    {
      id: 86,
      name: 'Mining of hard coal',
      code: '05.10',
      selectable: true,
      parentId: 85,
    },
    {
      id: 87,
      name: 'Mining of lignite',
      code: '05.2',
      selectable: false,
      parentId: 84,
    },
    {
      id: 88,
      name: 'Mining of lignite',
      code: '05.20',
      selectable: true,
      parentId: 85,
    },
    {
      id: 89,
      name: 'Extraction of crude petroleum and natural gas',
      code: '06',
      selectable: false,
      parentId: 85,
    },
    {
      id: 90,
      name: 'Extraction of crude petroleum',
      code: '06.1',
      selectable: false,
      parentId: 84,
    },
    {
      id: 91,
      name: 'Extraction of crude petroleum',
      code: '06.10',
      selectable: true,
      parentId: 85,
    },
    {
      id: 92,
      name: 'Extraction of natural gas',
      code: '06.2',
      selectable: false,
      parentId: 84,
    },
    {
      id: 93,
      name: 'Extraction of natural gas',
      code: '06.20',
      selectable: true,
      parentId: 85,
    },
    {
      id: 94,
      name: 'Mining of metal ores',
      code: '07',
      selectable: false,
      parentId: 83,
    },
    {
      id: 95,
      name: 'Mining of iron ores',
      code: '07.1',
      selectable: false,
      parentId: 94,
    },
    {
      id: 96,
      name: 'Mining of iron ores',
      code: '07.10',
      selectable: true,
      parentId: 95,
    },
    {
      id: 97,
      name: 'Mining of non-ferrous metal ores',
      code: '07.2',
      selectable: false,
      parentId: 94,
    },
    {
      id: 98,
      name: 'Mining of uranium and thorium ores',
      code: '07.21',
      selectable: true,
      parentId: 95,
    },
    {
      id: 99,
      name: 'Mining of other non-ferrous metal ores',
      code: '07.29',
      selectable: true,
      parentId: 95,
    },
    {
      id: 100,
      name: 'Other mining and quarrying',
      code: '08',
      selectable: false,
      parentId: 83,
    },
    {
      id: 101,
      name: 'Quarrying of stone, sand and clay',
      code: '08.1',
      selectable: true,
      parentId: 100,
    },
    {
      id: 102,
      name: 'Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate',
      code: '08.11',
      selectable: true,
      parentId: 100,
    },
    {
      id: 103,
      name: 'Operation of gravel and sand pits; mining of clays and kaolin',
      code: '08.12',
      selectable: true,
      parentId: 100,
    },
    {
      id: 104,
      name: 'Mining and quarrying n.e.c.',
      code: '08.9',
      selectable: false,
      parentId: 100,
    },
    {
      id: 105,
      name: 'Mining of chemical and fertiliser minerals',
      code: '08.91',
      selectable: true,
      parentId: 104,
    },
    {
      id: 106,
      name: 'Extraction of peat',
      code: '08.92',
      selectable: true,
      parentId: 104,
    },
    {
      id: 107,
      name: 'Extraction of salt',
      code: '08.93',
      selectable: true,
      parentId: 104,
    },
    {
      id: 108,
      name: 'Other mining and quarrying n.e.c.',
      code: '08.99',
      selectable: true,
      parentId: 104,
    },
    {
      id: 109,
      name: 'Mining support service activities',
      code: '09',
      selectable: false,
      parentId: 83,
    },
    {
      id: 110,
      name: 'Support activities for petroleum and natural gas extraction',
      code: '09.1',
      selectable: false,
      parentId: 109,
    },
    {
      id: 111,
      name: 'Support activities for petroleum and natural gas extraction',
      code: '09.10',
      selectable: true,
      parentId: 110,
    },
    {
      id: 112,
      name: 'Preparation of natural gas for transportation',
      code: '09.10.10',
      selectable: true,
      parentId: 110,
    },
    {
      id: 113,
      name: 'Support activities for other mining and quarrying',
      code: '09.9',
      selectable: false,
      parentId: 109,
    },
    {
      id: 114,
      name: 'Support activities for other mining and quarrying',
      code: '09.90',
      selectable: true,
      parentId: 110,
    },
    {
      id: 115,
      name: 'MANUFACTURING',
      code: 'C',
      selectable: false,
    },
    {
      id: 116,
      name: 'Manufacture of food products',
      code: '10',
      selectable: false,
      parentId: 115,
    },
    {
      id: 117,
      name: 'Processing and preserving of meat and production of meat products',
      code: '10.1',
      selectable: true,
      parentId: 116,
    },
    {
      id: 118,
      name: 'Processing and preserving of meat',
      code: '10.11',
      selectable: true,
      parentId: 116,
    },
    {
      id: 119,
      name: 'Processing and preserving of poultry meat',
      code: '10.12',
      selectable: true,
      parentId: 116,
    },
    {
      id: 120,
      name: 'Production of meat and poultry meat products',
      code: '10.13',
      selectable: true,
      parentId: 116,
    },
    {
      id: 121,
      name: 'Production of smoked meat and poultry products',
      code: '10.13.10',
      selectable: true,
      parentId: 116,
    },
    {
      id: 122,
      name: 'Processing and preserving of fish, crustaceans and molluscs',
      code: '10.2',
      selectable: false,
      parentId: 116,
    },
    {
      id: 123,
      name: 'Processing and preserving of fish, crustaceans and molluscs',
      code: '10.20',
      selectable: true,
      parentId: 122,
    },
    {
      id: 124,
      name: 'Processing and preserving of fruit and vegetables',
      code: '10.3',
      selectable: false,
      parentId: 116,
    },
    {
      id: 125,
      name: 'Processing and preserving of potatoes',
      code: '10.31',
      selectable: true,
      parentId: 124,
    },
    {
      id: 126,
      name: 'Production of potato flour',
      code: '10.31.10',
      selectable: true,
      parentId: 124,
    },
    {
      id: 127,
      name: 'Manufacture of fruit and vegetable juice',
      code: '10.32',
      selectable: true,
      parentId: 124,
    },
    {
      id: 128,
      name: 'Other processing and preserving of fruit and vegetables',
      code: '10.39',
      selectable: true,
      parentId: 124,
    },
    {
      id: 129,
      name: 'Manufacture of vegetable and animal oils and fats',
      code: '10.4',
      selectable: true,
      parentId: 116,
    },
    {
      id: 130,
      name: 'Manufacture of oils and fats',
      code: '10.41',
      selectable: true,
      parentId: 116,
    },
    {
      id: 131,
      name: 'Manufacture of margarine and similar edible fats',
      code: '10.42',
      selectable: true,
      parentId: 116,
    },
    {
      id: 132,
      name: 'Manufacture of dairy products',
      code: '10.5',
      selectable: true,
      parentId: 116,
    },
    {
      id: 133,
      name: 'Operation of dairies and cheese making',
      code: '10.51',
      selectable: true,
      parentId: 116,
    },
    {
      id: 134,
      name: 'Manufacture of ice cream',
      code: '10.52',
      selectable: true,
      parentId: 116,
    },
    {
      id: 135,
      name: 'Manufacture of grain mill products, starches and starch products',
      code: '10.6',
      selectable: false,
      parentId: 116,
    },
    {
      id: 136,
      name: 'Manufacture of grain mill products',
      code: '10.61',
      selectable: true,
      parentId: 135,
    },
    {
      id: 137,
      name: 'Production of flour',
      code: '10.61.10',
      selectable: true,
      parentId: 135,
    },
    {
      id: 138,
      name: 'Production of groats',
      code: '10.61.20',
      selectable: true,
      parentId: 135,
    },
    {
      id: 139,
      name: 'Manufacture of starches and starch products',
      code: '10.62',
      selectable: true,
      parentId: 135,
    },
    {
      id: 140,
      name: 'Production of glucose syrup and maltose',
      code: '10.62.10',
      selectable: true,
      parentId: 135,
    },
    {
      id: 141,
      name: 'Production of starch',
      code: '10.62.20',
      selectable: true,
      parentId: 135,
    },
    {
      id: 142,
      name: 'Manufacture of bakery and farinaceous products',
      code: '10.7',
      selectable: true,
      parentId: 116,
    },
    {
      id: 143,
      name: 'Manufacture of bread; manufacture of fresh pastry goods and cakes',
      code: '10.71',
      selectable: true,
      parentId: 116,
    },
    {
      id: 144,
      name: 'Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes',
      code: '10.72',
      selectable: true,
      parentId: 116,
    },
    {
      id: 145,
      name: 'Manufacture of macaroni, noodles, couscous and similar farinaceous products',
      code: '10.73',
      selectable: true,
      parentId: 116,
    },
    {
      id: 146,
      name: 'Manufacture of other food products',
      code: '10.8',
      selectable: false,
      parentId: 116,
    },
    {
      id: 147,
      name: 'Manufacture of sugar',
      code: '10.81',
      selectable: true,
      parentId: 146,
    },
    {
      id: 148,
      name: 'Manufacture of cocoa, chocolate and sugar confectionery',
      code: '10.82',
      selectable: true,
      parentId: 146,
    },
    {
      id: 149,
      name: 'Processing of tea and coffee',
      code: '10.83',
      selectable: true,
      parentId: 146,
    },
    {
      id: 150,
      name: 'Manufacture of condiments and seasonings',
      code: '10.84',
      selectable: true,
      parentId: 146,
    },
    {
      id: 151,
      name: 'Production of vinegar',
      code: '10.84.10',
      selectable: true,
      parentId: 146,
    },
    {
      id: 152,
      name: 'Manufacture of prepared meals and dishes',
      code: '10.85',
      selectable: true,
      parentId: 146,
    },
    {
      id: 153,
      name: 'Manufacture of homogenised food preparations and dietetic food',
      code: '10.86',
      selectable: true,
      parentId: 146,
    },
    {
      id: 154,
      name: 'Manufacture of other food products n.e.c.',
      code: '10.89',
      selectable: true,
      parentId: 146,
    },
    {
      id: 155,
      name: 'Production of yeast',
      code: '10.89.10',
      selectable: true,
      parentId: 146,
    },
    {
      id: 156,
      name: 'Manufacture of prepared animal feeds',
      code: '10.9',
      selectable: true,
      parentId: 116,
    },
    {
      id: 157,
      name: 'Manufacture of prepared feeds for farm animals',
      code: '10.91',
      selectable: true,
      parentId: 116,
    },
    {
      id: 158,
      name: 'Manufacture of prepared pet foods',
      code: '10.92',
      selectable: true,
      parentId: 116,
    },
    {
      id: 159,
      name: 'Manufacture of beverages',
      code: '11',
      selectable: false,
      parentId: 115,
    },
    {
      id: 160,
      name: 'Manufacture of beverages',
      code: '11.0',
      selectable: true,
      parentId: 159,
    },
    {
      id: 161,
      name: 'Distilling, rectifying and blending of spirits',
      code: '11.01',
      selectable: true,
      parentId: 159,
    },
    {
      id: 162,
      name: 'Manufacture of wine from grape',
      code: '11.02',
      selectable: true,
      parentId: 159,
    },
    {
      id: 163,
      name: 'Manufacture of cider and other fruit wines',
      code: '11.03',
      selectable: true,
      parentId: 159,
    },
    {
      id: 164,
      name: 'Manufacture of other non-distilled fermented beverages',
      code: '11.04',
      selectable: true,
      parentId: 159,
    },
    {
      id: 165,
      name: 'Manufacture of beer',
      code: '11.05',
      selectable: true,
      parentId: 159,
    },
    {
      id: 166,
      name: 'Manufacture of malt',
      code: '11.06',
      selectable: true,
      parentId: 159,
    },
    {
      id: 167,
      name: 'Manufacture of soft drinks; production of mineral waters and other bottled waters',
      code: '11.07',
      selectable: false,
      parentId: 159,
    },
    {
      id: 168,
      name: 'Production of soft drinks',
      code: '11.07.10',
      selectable: true,
      parentId: 167,
    },
    {
      id: 169,
      name: 'Production of natural mineral and other bottled water',
      code: '11.07.20',
      selectable: true,
      parentId: 167,
    },
    {
      id: 170,
      name: 'Manufacture of tobacco products',
      code: '12',
      selectable: false,
      parentId: 115,
    },
    {
      id: 171,
      name: 'Manufacture of tobacco products',
      code: '12.0',
      selectable: false,
      parentId: 170,
    },
    {
      id: 172,
      name: 'Manufacture of tobacco products',
      code: '12.00',
      selectable: true,
      parentId: 171,
    },
    {
      id: 173,
      name: 'Manufacture of textiles',
      code: '13',
      selectable: false,
      parentId: 115,
    },
    {
      id: 174,
      name: 'Preparation and spinning of textile fibres',
      code: '13.1',
      selectable: false,
      parentId: 173,
    },
    {
      id: 175,
      name: 'Preparation and spinning of textile fibres',
      code: '13.10',
      selectable: true,
      parentId: 174,
    },
    {
      id: 176,
      name: 'Weaving of textiles',
      code: '13.2',
      selectable: false,
      parentId: 173,
    },
    {
      id: 177,
      name: 'Weaving of textiles',
      code: '13.20',
      selectable: true,
      parentId: 176,
    },
    {
      id: 178,
      name: 'Manufacture of linen',
      code: '13.20.10',
      selectable: true,
      parentId: 176,
    },
    {
      id: 179,
      name: 'Manufacture of synthetics',
      code: '13.20.20',
      selectable: true,
      parentId: 176,
    },
    {
      id: 180,
      name: 'Finishing of textiles',
      code: '13.3',
      selectable: false,
      parentId: 173,
    },
    {
      id: 181,
      name: 'Finishing of textiles',
      code: '13.30',
      selectable: true,
      parentId: 180,
    },
    {
      id: 182,
      name: 'Manufacture of other textiles',
      code: '13.9',
      selectable: false,
      parentId: 173,
    },
    {
      id: 183,
      name: 'Manufacture of knitted and crocheted fabrics',
      code: '13.91',
      selectable: true,
      parentId: 182,
    },
    {
      id: 184,
      name: 'Manufacture of made-up textile articles, except apparel',
      code: '13.92',
      selectable: true,
      parentId: 182,
    },
    {
      id: 185,
      name: 'Manufacture of carpets and rugs',
      code: '13.93',
      selectable: true,
      parentId: 182,
    },
    {
      id: 186,
      name: 'Manufacture of cordage, rope, twine and netting',
      code: '13.94',
      selectable: true,
      parentId: 182,
    },
    {
      id: 187,
      name: 'Manufacture of non-wovens and articles made from non-wovens, except apparel',
      code: '13.95',
      selectable: true,
      parentId: 182,
    },
    {
      id: 188,
      name: 'Manufacture of other technical and industrial textiles',
      code: '13.96',
      selectable: true,
      parentId: 182,
    },
    {
      id: 189,
      name: 'Manufacture of other textiles n.e.c.',
      code: '13.99',
      selectable: true,
      parentId: 182,
    },
    {
      id: 190,
      name: 'Manufacture of wearing apparel',
      code: '14',
      selectable: false,
      parentId: 115,
    },
    {
      id: 191,
      name: 'Manufacture of wearing apparel, except fur apparel',
      code: '14.1',
      selectable: true,
      parentId: 190,
    },
    {
      id: 192,
      name: 'Manufacture of leather clothes',
      code: '14.11',
      selectable: true,
      parentId: 190,
    },
    {
      id: 193,
      name: 'Manufacture of workwear',
      code: '14.12',
      selectable: true,
      parentId: 190,
    },
    {
      id: 194,
      name: 'Manufacture of other outerwear',
      code: '14.13',
      selectable: true,
      parentId: 190,
    },
    {
      id: 195,
      name: 'Manufacture of outerwear for men',
      code: '14.13.10',
      selectable: true,
      parentId: 190,
    },
    {
      id: 196,
      name: 'Manufacture of outerwear for women',
      code: '14.13.20',
      selectable: true,
      parentId: 190,
    },
    {
      id: 197,
      name: 'Manufacture of underwear',
      code: '14.14',
      selectable: true,
      parentId: 190,
    },
    {
      id: 198,
      name: 'Manufacture of other wearing apparel and accessories',
      code: '14.19',
      selectable: true,
      parentId: 190,
    },
    {
      id: 199,
      name: 'Manufacture of children’s wear',
      code: '14.19.10',
      selectable: true,
      parentId: 190,
    },
    {
      id: 200,
      name: 'Manufacture of sportswear',
      code: '14.19.20',
      selectable: true,
      parentId: 190,
    },
    {
      id: 201,
      name: 'Manufacture of fur hats and other headgear',
      code: '14.19.30',
      selectable: true,
      parentId: 190,
    },
    {
      id: 202,
      name: 'Manufacture of articles of fur',
      code: '14.2',
      selectable: false,
      parentId: 190,
    },
    {
      id: 203,
      name: 'Manufacture of articles of fur',
      code: '14.20',
      selectable: true,
      parentId: 202,
    },
    {
      id: 204,
      name: 'Manufacture of knitted and crocheted apparel',
      code: '14.3',
      selectable: false,
      parentId: 190,
    },
    {
      id: 205,
      name: 'Manufacture of knitted and crocheted hosiery',
      code: '14.31',
      selectable: true,
      parentId: 204,
    },
    {
      id: 206,
      name: 'Manufacture of other knitted and crocheted apparel',
      code: '14.39',
      selectable: true,
      parentId: 204,
    },
    {
      id: 207,
      name: 'Manufacture of leather and related products',
      code: '15',
      selectable: false,
      parentId: 115,
    },
    {
      id: 208,
      name: 'Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur',
      code: '15.1',
      selectable: true,
      parentId: 207,
    },
    {
      id: 209,
      name: 'Tanning and dressing of leather; dressing and dyeing of fur',
      code: '15.11',
      selectable: true,
      parentId: 207,
    },
    {
      id: 210,
      name: 'Vegetable tanning of leather',
      code: '15.11.10',
      selectable: true,
      parentId: 207,
    },
    {
      id: 211,
      name: 'Tanning of leather with mineral and chemical materials',
      code: '15.11.20',
      selectable: true,
      parentId: 207,
    },
    {
      id: 212,
      name: 'Dressing of leather',
      code: '15.11.30',
      selectable: true,
      parentId: 207,
    },
    {
      id: 213,
      name: 'Manufacture of luggage, handbags and the like, saddlery and harness',
      code: '15.12',
      selectable: true,
      parentId: 207,
    },
    {
      id: 214,
      name: 'Manufacture of footwear',
      code: '15.2',
      selectable: false,
      parentId: 207,
    },
    {
      id: 215,
      name: 'Manufacture of footwear',
      code: '15.20',
      selectable: true,
      parentId: 214,
    },
    {
      id: 216,
      name: 'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
      code: '16',
      selectable: false,
      parentId: 115,
    },
    {
      id: 217,
      name: 'Sawmilling and planing of wood',
      code: '16.1',
      selectable: false,
      parentId: 216,
    },
    {
      id: 218,
      name: 'Sawmilling and planing of wood',
      code: '16.10',
      selectable: true,
      parentId: 217,
    },
    {
      id: 219,
      name: 'Chemical treatment of wood with preservatives',
      code: '16.10.10',
      selectable: true,
      parentId: 217,
    },
    {
      id: 220,
      name: 'Manufacture of products of wood, cork, straw and plaiting materials',
      code: '16.2',
      selectable: true,
      parentId: 216,
    },
    {
      id: 221,
      name: 'Manufacture of veneer sheets and wood-based panels',
      code: '16.21',
      selectable: true,
      parentId: 216,
    },
    {
      id: 222,
      name: 'Manufacture of wood fibreboards covered with facing film',
      code: '16.21.10',
      selectable: true,
      parentId: 216,
    },
    {
      id: 223,
      name: 'Manufacture of densified wood',
      code: '16.21.20',
      selectable: true,
      parentId: 216,
    },
    {
      id: 224,
      name: 'Manufacture of assembled parquet floors',
      code: '16.22',
      selectable: true,
      parentId: 216,
    },
    {
      id: 225,
      name: "Manufacture of other builders' carpentry and joinery",
      code: '16.23',
      selectable: true,
      parentId: 216,
    },
    {
      id: 226,
      name: 'Manufacture of fittings and shingles for roofing',
      code: '16.23.10',
      selectable: true,
      parentId: 216,
    },
    {
      id: 227,
      name: 'Manufacture of prefabricated wooden buildings or elements thereof',
      code: '16.23.20',
      selectable: true,
      parentId: 216,
    },
    {
      id: 228,
      name: 'Manufacture of wooden wallboards and wall closets',
      code: '16.23.30',
      selectable: true,
      parentId: 216,
    },
    {
      id: 229,
      name: 'Manufacture of wooden containers',
      code: '16.24',
      selectable: true,
      parentId: 216,
    },
    {
      id: 230,
      name: 'Manufacture of pallets, box pallets and other load boards of wood',
      code: '16.24.10',
      selectable: true,
      parentId: 216,
    },
    {
      id: 231,
      name: 'Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials',
      code: '16.29',
      selectable: true,
      parentId: 216,
    },
    {
      id: 232,
      name: 'Manufacture of paper and paper products',
      code: '17',
      selectable: false,
      parentId: 115,
    },
    {
      id: 233,
      name: 'Manufacture of pulp, paper and paperboard',
      code: '17.1',
      selectable: false,
      parentId: 232,
    },
    {
      id: 234,
      name: 'Manufacture of pulp',
      code: '17.11',
      selectable: true,
      parentId: 233,
    },
    {
      id: 235,
      name: 'Manufacture of paper and paperboard',
      code: '17.12',
      selectable: true,
      parentId: 233,
    },
    {
      id: 236,
      name: 'Manufacture of articles of paper and paperboard',
      code: '17.2',
      selectable: false,
      parentId: 232,
    },
    {
      id: 237,
      name: 'Manufacture of corrugated paper and paperboard and of containers of paper and paperboard',
      code: '17.21',
      selectable: true,
      parentId: 236,
    },
    {
      id: 238,
      name: 'Manufacture of household and sanitary goods and of toilet requisites',
      code: '17.22',
      selectable: true,
      parentId: 236,
    },
    {
      id: 239,
      name: 'Manufacture of toilet paper',
      code: '17.22.10',
      selectable: true,
      parentId: 236,
    },
    {
      id: 240,
      name: 'Manufacture of paper stationery',
      code: '17.23',
      selectable: true,
      parentId: 236,
    },
    {
      id: 241,
      name: 'Manufacture of carbon paper',
      code: '17.23.10',
      selectable: true,
      parentId: 236,
    },
    {
      id: 242,
      name: 'Manufacture of envelopes and letter-cards',
      code: '17.23.20',
      selectable: true,
      parentId: 236,
    },
    {
      id: 243,
      name: 'Manufacture of wallpaper',
      code: '17.24',
      selectable: true,
      parentId: 236,
    },
    {
      id: 244,
      name: 'Manufacture of other articles of paper and paperboard',
      code: '17.29',
      selectable: true,
      parentId: 236,
    },
    {
      id: 245,
      name: 'Manufacture of filter paper',
      code: '17.29.10',
      selectable: true,
      parentId: 236,
    },
    {
      id: 246,
      name: 'Printing and reproduction of recorded media',
      code: '18',
      selectable: false,
      parentId: 115,
    },
    {
      id: 247,
      name: 'Printing and service activities related to printing',
      code: '18.1',
      selectable: false,
      parentId: 246,
    },
    {
      id: 248,
      name: 'Printing of newspapers',
      code: '18.11',
      selectable: true,
      parentId: 247,
    },
    {
      id: 249,
      name: 'Other printing',
      code: '18.12',
      selectable: true,
      parentId: 247,
    },
    {
      id: 250,
      name: 'Pre-press and pre-media services',
      code: '18.13',
      selectable: true,
      parentId: 247,
    },
    {
      id: 251,
      name: 'Binding and related services',
      code: '18.14',
      selectable: true,
      parentId: 247,
    },
    {
      id: 252,
      name: 'Reproduction of recorded media',
      code: '18.2',
      selectable: false,
      parentId: 246,
    },
    {
      id: 253,
      name: 'Reproduction of recorded media',
      code: '18.20',
      selectable: true,
      parentId: 252,
    },
    {
      id: 254,
      name: 'Manufacture of coke and refined petroleum products',
      code: '19',
      selectable: false,
      parentId: 115,
    },
    {
      id: 255,
      name: 'Manufacture of coke oven products',
      code: '19.1',
      selectable: false,
      parentId: 254,
    },
    {
      id: 256,
      name: 'Manufacture of coke oven products',
      code: '19.10',
      selectable: true,
      parentId: 255,
    },
    {
      id: 257,
      name: 'Manufacture of refined petroleum products',
      code: '19.2',
      selectable: false,
      parentId: 254,
    },
    {
      id: 258,
      name: 'Manufacture of refined petroleum products',
      code: '19.20',
      selectable: true,
      parentId: 257,
    },
    {
      id: 259,
      name: 'Production of liquid and gaseous fuel',
      code: '19.20.10',
      selectable: true,
      parentId: 257,
    },
    {
      id: 260,
      name: 'Manufacture of lubricants and other oil',
      code: '19.20.20',
      selectable: true,
      parentId: 257,
    },
    {
      id: 261,
      name: 'Manufacture of paraffin and Vaseline',
      code: '19.20.30',
      selectable: true,
      parentId: 257,
    },
    {
      id: 262,
      name: 'Manufacture of petroleum bitumen',
      code: '19.20.40',
      selectable: true,
      parentId: 257,
    },
    {
      id: 263,
      name: 'Manufacture of chemicals and chemical products',
      code: '20',
      selectable: false,
      parentId: 115,
    },
    {
      id: 264,
      name: 'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      code: '20.1',
      selectable: true,
      parentId: 263,
    },
    {
      id: 265,
      name: 'Manufacture of industrial gases',
      code: '20.11',
      selectable: true,
      parentId: 263,
    },
    {
      id: 266,
      name: 'Manufacture of dyes and pigments',
      code: '20.12',
      selectable: true,
      parentId: 263,
    },
    {
      id: 267,
      name: 'Manufacture of other inorganic basic chemicals',
      code: '20.13',
      selectable: true,
      parentId: 263,
    },
    {
      id: 268,
      name: 'Manufacture of other organic basic chemicals',
      code: '20.14',
      selectable: true,
      parentId: 263,
    },
    {
      id: 269,
      name: 'Manufacture of fertilisers and nitrogen compounds',
      code: '20.15',
      selectable: true,
      parentId: 263,
    },
    {
      id: 270,
      name: 'Manufacture of straight or complex nitrogenous and phosphatic fertilisers',
      code: '20.15.10',
      selectable: true,
      parentId: 263,
    },
    {
      id: 271,
      name: 'Manufacture of carbamide',
      code: '20.15.20',
      selectable: true,
      parentId: 263,
    },
    {
      id: 272,
      name: 'Manufacture of ammonia',
      code: '20.15.30',
      selectable: true,
      parentId: 263,
    },
    {
      id: 273,
      name: 'Manufacture of plastics in primary forms',
      code: '20.16',
      selectable: true,
      parentId: 263,
    },
    {
      id: 274,
      name: 'Manufacture of synthetic rubber in primary forms',
      code: '20.17',
      selectable: true,
      parentId: 263,
    },
    {
      id: 275,
      name: 'Manufacture of pesticides and other agrochemical products',
      code: '20.2',
      selectable: false,
      parentId: 263,
    },
    {
      id: 276,
      name: 'Manufacture of pesticides and other agrochemical products',
      code: '20.20',
      selectable: true,
      parentId: 275,
    },
    {
      id: 277,
      name: 'Manufacture of disinfectants',
      code: '20.20.10',
      selectable: true,
      parentId: 275,
    },
    {
      id: 278,
      name: 'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
      code: '20.3',
      selectable: false,
      parentId: 263,
    },
    {
      id: 279,
      name: 'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
      code: '20.30',
      selectable: true,
      parentId: 278,
    },
    {
      id: 280,
      name: 'Manufacture of enamels and lacquers',
      code: '20.30.10',
      selectable: true,
      parentId: 278,
    },
    {
      id: 281,
      name: 'Manufacture of organic composite solvents and thinners',
      code: '20.30.20',
      selectable: true,
      parentId: 278,
    },
    {
      id: 282,
      name: 'Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations',
      code: '20.4',
      selectable: false,
      parentId: 263,
    },
    {
      id: 283,
      name: 'Manufacture of soap and detergents, cleaning and polishing preparations',
      code: '20.41',
      selectable: true,
      parentId: 282,
    },
    {
      id: 284,
      name: 'Manufacture of perfumes and toilet preparations',
      code: '20.42',
      selectable: true,
      parentId: 282,
    },
    {
      id: 285,
      name: 'Manufacture of other chemical products',
      code: '20.5',
      selectable: false,
      parentId: 263,
    },
    {
      id: 286,
      name: 'Manufacture of explosives',
      code: '20.51',
      selectable: true,
      parentId: 285,
    },
    {
      id: 287,
      name: 'Manufacture of glues',
      code: '20.52',
      selectable: true,
      parentId: 285,
    },
    {
      id: 288,
      name: 'Manufacture of essential oils',
      code: '20.53',
      selectable: true,
      parentId: 285,
    },
    {
      id: 289,
      name: 'Manufacture of other chemical products n.e.c.',
      code: '20.59',
      selectable: true,
      parentId: 285,
    },
    {
      id: 290,
      name: 'Manufacture of melting, soldering or welding powder and paste',
      code: '20.59.10',
      selectable: true,
      parentId: 285,
    },
    {
      id: 291,
      name: 'Manufacture of activated charcoal, lubricating additives, ready-made rubber vulcanisation accelerators, catalysers',
      code: '20.59.20',
      selectable: true,
      parentId: 285,
    },
    {
      id: 292,
      name: 'Manufacture of antidetonant agents, antifreeze agents, fluids for hydraulic systems',
      code: '20.59.30',
      selectable: true,
      parentId: 285,
    },
    {
      id: 293,
      name: 'Manufacture of writing and drawing ink',
      code: '20.59.40',
      selectable: true,
      parentId: 285,
    },
    {
      id: 294,
      name: 'Manufacture of man-made fibres',
      code: '20.6',
      selectable: false,
      parentId: 263,
    },
    {
      id: 295,
      name: 'Manufacture of man-made fibres',
      code: '20.60',
      selectable: true,
      parentId: 294,
    },
    {
      id: 296,
      name: 'Manufacture of basic pharmaceutical products and pharmaceutical preparations',
      code: '21',
      selectable: false,
      parentId: 115,
    },
    {
      id: 297,
      name: 'Manufacture of basic pharmaceutical products',
      code: '21.1',
      selectable: false,
      parentId: 296,
    },
    {
      id: 298,
      name: 'Manufacture of basic pharmaceutical products',
      code: '21.10',
      selectable: true,
      parentId: 297,
    },
    {
      id: 299,
      name: 'Manufacture of narcotic drugs',
      code: '21.10.10',
      selectable: true,
      parentId: 297,
    },
    {
      id: 300,
      name: 'Manufacture of pharmaceutical preparations',
      code: '21.2',
      selectable: false,
      parentId: 296,
    },
    {
      id: 301,
      name: 'Manufacture of pharmaceutical preparations',
      code: '21.20',
      selectable: true,
      parentId: 300,
    },
    {
      id: 302,
      name: 'Manufacture of medicaments',
      code: '21.20.10',
      selectable: true,
      parentId: 300,
    },
    {
      id: 303,
      name: 'Manufacture of veterinary medicaments',
      code: '21.20.20',
      selectable: true,
      parentId: 300,
    },
    {
      id: 304,
      name: 'Manufacture of rubber and plastic products',
      code: '22',
      selectable: false,
      parentId: 115,
    },
    {
      id: 305,
      name: 'Manufacture of rubber products',
      code: '22.1',
      selectable: false,
      parentId: 304,
    },
    {
      id: 306,
      name: 'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
      code: '22.11',
      selectable: true,
      parentId: 305,
    },
    {
      id: 307,
      name: 'Manufacture of other rubber products',
      code: '22.19',
      selectable: true,
      parentId: 305,
    },
    {
      id: 308,
      name: 'Manufacture of plastics products',
      code: '22.2',
      selectable: false,
      parentId: 304,
    },
    {
      id: 309,
      name: 'Manufacture of plastic plates, sheets, tubes and profiles',
      code: '22.21',
      selectable: true,
      parentId: 308,
    },
    {
      id: 310,
      name: 'Manufacture of plastic packing goods',
      code: '22.22',
      selectable: true,
      parentId: 308,
    },
    {
      id: 311,
      name: "Manufacture of builders' ware of plastic",
      code: '22.23',
      selectable: true,
      parentId: 308,
    },
    {
      id: 312,
      name: 'Manufacture of other plastic products',
      code: '22.29',
      selectable: true,
      parentId: 308,
    },
    {
      id: 313,
      name: 'Manufacture of other non-metallic mineral products',
      code: '23',
      selectable: false,
      parentId: 115,
    },
    {
      id: 314,
      name: 'Manufacture of glass and glass products',
      code: '23.1',
      selectable: true,
      parentId: 313,
    },
    {
      id: 315,
      name: 'Manufacture of flat glass',
      code: '23.11',
      selectable: true,
      parentId: 313,
    },
    {
      id: 316,
      name: 'Shaping and processing of flat glass',
      code: '23.12',
      selectable: true,
      parentId: 313,
    },
    {
      id: 317,
      name: 'Manufacture of hollow glass',
      code: '23.13',
      selectable: true,
      parentId: 313,
    },
    {
      id: 318,
      name: 'Manufacture of glass containers',
      code: '23.13.10',
      selectable: true,
      parentId: 313,
    },
    {
      id: 319,
      name: 'Manufacture of glass fibres',
      code: '23.14',
      selectable: true,
      parentId: 313,
    },
    {
      id: 320,
      name: 'Manufacture and processing of other glass, including technical glassware',
      code: '23.19',
      selectable: true,
      parentId: 313,
    },
    {
      id: 321,
      name: 'Manufacture of glass insulators and glass insulating fitters',
      code: '23.19.10',
      selectable: true,
      parentId: 313,
    },
    {
      id: 322,
      name: 'Manufacture of refractory products',
      code: '23.2',
      selectable: false,
      parentId: 313,
    },
    {
      id: 323,
      name: 'Manufacture of refractory products',
      code: '23.20',
      selectable: true,
      parentId: 322,
    },
    {
      id: 324,
      name: 'Manufacture of clay building materials',
      code: '23.3',
      selectable: true,
      parentId: 313,
    },
    {
      id: 325,
      name: 'Manufacture of ceramic tiles and flags',
      code: '23.31',
      selectable: true,
      parentId: 313,
    },
    {
      id: 326,
      name: 'Manufacture of tiles',
      code: '23.31.10',
      selectable: true,
      parentId: 313,
    },
    {
      id: 327,
      name: 'Manufacture of flooring blocks in baked clay',
      code: '23.31.20',
      selectable: true,
      parentId: 313,
    },
    {
      id: 328,
      name: 'Manufacture of bricks, tiles and construction products, in baked clay',
      code: '23.32',
      selectable: true,
      parentId: 313,
    },
    {
      id: 329,
      name: 'Manufacture of other porcelain and ceramic products',
      code: '23.4',
      selectable: true,
      parentId: 313,
    },
    {
      id: 330,
      name: 'Manufacture of ceramic household and ornamental articles',
      code: '23.41',
      selectable: true,
      parentId: 313,
    },
    {
      id: 331,
      name: 'Manufacture of ceramic sanitary fixtures',
      code: '23.42',
      selectable: true,
      parentId: 313,
    },
    {
      id: 332,
      name: 'Manufacture of ceramic insulators and insulating fittings',
      code: '23.43',
      selectable: true,
      parentId: 313,
    },
    {
      id: 333,
      name: 'Manufacture of other technical ceramic products',
      code: '23.44',
      selectable: true,
      parentId: 313,
    },
    {
      id: 334,
      name: 'Manufacture of other ceramic products',
      code: '23.49',
      selectable: true,
      parentId: 313,
    },
    {
      id: 335,
      name: 'Manufacture of cement, lime and plaster',
      code: '23.5',
      selectable: false,
      parentId: 313,
    },
    {
      id: 336,
      name: 'Manufacture of cement',
      code: '23.51',
      selectable: true,
      parentId: 335,
    },
    {
      id: 337,
      name: 'Manufacture of lime and plaster',
      code: '23.52',
      selectable: true,
      parentId: 335,
    },
    {
      id: 338,
      name: 'Manufacture of articles of concrete, cement and plaster',
      code: '23.6',
      selectable: false,
      parentId: 313,
    },
    {
      id: 339,
      name: 'Manufacture of concrete products for construction purposes',
      code: '23.61',
      selectable: true,
      parentId: 338,
    },
    {
      id: 340,
      name: 'Manufacture of plaster products for construction purposes',
      code: '23.62',
      selectable: true,
      parentId: 338,
    },
    {
      id: 341,
      name: 'Manufacture of ready-mixed concrete',
      code: '23.63',
      selectable: true,
      parentId: 338,
    },
    {
      id: 342,
      name: 'Manufacture of mortars',
      code: '23.64',
      selectable: true,
      parentId: 338,
    },
    {
      id: 343,
      name: 'Manufacture of fibre cement',
      code: '23.65',
      selectable: true,
      parentId: 338,
    },
    {
      id: 344,
      name: 'Manufacture of other articles of concrete, plaster and cement',
      code: '23.69',
      selectable: true,
      parentId: 338,
    },
    {
      id: 345,
      name: 'Cutting, shaping and finishing of stone',
      code: '23.7',
      selectable: false,
      parentId: 313,
    },
    {
      id: 346,
      name: 'Cutting, shaping and finishing of stone',
      code: '23.70',
      selectable: true,
      parentId: 345,
    },
    {
      id: 347,
      name: 'Manufacture of abrasive products and non-metallic mineral products n.e.c.',
      code: '23.9',
      selectable: true,
      parentId: 313,
    },
    {
      id: 348,
      name: 'Production of abrasive products',
      code: '23.91',
      selectable: true,
      parentId: 313,
    },
    {
      id: 349,
      name: 'Manufacture of other non-metallic mineral products n.e.c.',
      code: '23.99',
      selectable: true,
      parentId: 313,
    },
    {
      id: 350,
      name: 'Manufacture of asbestos fibre, yarn and materials and products thereof',
      code: '23.99.10',
      selectable: true,
      parentId: 313,
    },
    {
      id: 351,
      name: 'Manufacture of asbestos friction materials and products thereof',
      code: '23.99.20',
      selectable: true,
      parentId: 313,
    },
    {
      id: 352,
      name: 'Manufacture of mineral insulating materials',
      code: '23.99.30',
      selectable: true,
      parentId: 313,
    },
    {
      id: 353,
      name: 'Manufacture of bituminous products',
      code: '23.99.40',
      selectable: true,
      parentId: 313,
    },
    {
      id: 354,
      name: 'Manufacture of basic metals',
      code: '24',
      selectable: false,
      parentId: 313,
    },
    {
      id: 355,
      name: 'Manufacture of basic iron and steel and of ferro-alloys',
      code: '24.1',
      selectable: false,
      parentId: 354,
    },
    {
      id: 356,
      name: 'Manufacture of basic iron and steel and of ferro-alloys',
      code: '24.10',
      selectable: true,
      parentId: 356,
    },
    {
      id: 357,
      name: 'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
      code: '24.2',
      selectable: false,
      parentId: 354,
    },
    {
      id: 358,
      name: 'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
      code: '24.20',
      selectable: true,
      parentId: 357,
    },
    {
      id: 359,
      name: 'Manufacture of other products of first processing of steel',
      code: '24.3',
      selectable: true,
      parentId: 354,
    },
    {
      id: 360,
      name: 'Cold drawing of bars',
      code: '24.31',
      selectable: true,
      parentId: 354,
    },
    {
      id: 361,
      name: 'Cold rolling of narrow strip',
      code: '24.32',
      selectable: true,
      parentId: 354,
    },
    {
      id: 362,
      name: 'Cold forming or folding',
      code: '24.33',
      selectable: true,
      parentId: 354,
    },
    {
      id: 363,
      name: 'Cold drawing of wire',
      code: '24.34',
      selectable: true,
      parentId: 354,
    },
    {
      id: 364,
      name: 'Manufacture of basic precious and other non-ferrous metals',
      code: '24.4',
      selectable: true,
      parentId: 354,
    },
    {
      id: 365,
      name: 'Precious metals production',
      code: '24.41',
      selectable: true,
      parentId: 354,
    },
    {
      id: 366,
      name: 'Aluminium production',
      code: '24.42',
      selectable: true,
      parentId: 354,
    },
    {
      id: 367,
      name: 'Lead, zinc and tin production',
      code: '24.43',
      selectable: true,
      parentId: 354,
    },
    {
      id: 368,
      name: 'Copper production',
      code: '24.44',
      selectable: true,
      parentId: 354,
    },
    {
      id: 369,
      name: 'Other non-ferrous metal production',
      code: '24.45',
      selectable: true,
      parentId: 354,
    },
    {
      id: 370,
      name: 'Processing of nuclear fuel',
      code: '24.46',
      selectable: true,
      parentId: 354,
    },
    {
      id: 371,
      name: 'Casting of metals',
      code: '24.5',
      selectable: true,
      parentId: 354,
    },
    {
      id: 372,
      name: 'Casting of iron',
      code: '24.51',
      selectable: true,
      parentId: 354,
    },
    {
      id: 373,
      name: 'Casting of steel',
      code: '24.52',
      selectable: true,
      parentId: 354,
    },
    {
      id: 374,
      name: 'Casting of light metals',
      code: '24.53',
      selectable: true,
      parentId: 354,
    },
    {
      id: 375,
      name: 'Casting of other non-ferrous metals',
      code: '24.54',
      selectable: true,
      parentId: 354,
    },
    {
      id: 376,
      name: 'Manufacture of fabricated metal products, except machinery and equipment',
      code: '25',
      selectable: false,
      parentId: 115,
    },
    {
      id: 377,
      name: 'Manufacture of structural metal products',
      code: '25.1',
      selectable: true,
      parentId: 376,
    },
    {
      id: 378,
      name: 'Manufacture of metal structures and parts of structures',
      code: '25.11',
      selectable: true,
      parentId: 376,
    },
    {
      id: 379,
      name: 'Manufacture of doors and windows of metal',
      code: '25.12',
      selectable: true,
      parentId: 376,
    },
    {
      id: 380,
      name: 'Manufacture of tanks, reservoirs and containers of metal',
      code: '25.2',
      selectable: false,
      parentId: 376,
    },
    {
      id: 381,
      name: 'Manufacture of central heating radiators and boilers',
      code: '25.21',
      selectable: true,
      parentId: 380,
    },
    {
      id: 382,
      name: 'Manufacture of other tanks, reservoirs and containers of metal',
      code: '25.29',
      selectable: true,
      parentId: 380,
    },
    {
      id: 383,
      name: 'Manufacture of metal containers for compressed or liquefied gas',
      code: '25.29.10',
      selectable: true,
      parentId: 380,
    },
    {
      id: 384,
      name: 'Manufacture of steam generators, except central heating hot water boilers',
      code: '25.3',
      selectable: false,
      parentId: 376,
    },
    {
      id: 385,
      name: 'Manufacture of steam generators, except central heating hot water boilers',
      code: '25.30',
      selectable: true,
      parentId: 384,
    },
    {
      id: 386,
      name: 'Manufacture of weapons and ammunition',
      code: '25.4',
      selectable: false,
      parentId: 376,
    },
    {
      id: 387,
      name: 'Manufacture of weapons and ammunition',
      code: '25.40',
      selectable: true,
      parentId: 386,
    },
    {
      id: 388,
      name: 'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
      code: '25.5',
      selectable: false,
      parentId: 376,
    },
    {
      id: 389,
      name: 'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
      code: '25.50',
      selectable: true,
      parentId: 388,
    },
    {
      id: 390,
      name: 'Treatment and coating of metals; machining',
      code: '25.6',
      selectable: false,
      parentId: 376,
    },
    {
      id: 391,
      name: 'Treatment and coating of metals',
      code: '25.61',
      selectable: true,
      parentId: 390,
    },
    {
      id: 392,
      name: 'Machining',
      code: '25.62',
      selectable: true,
      parentId: 390,
    },
    {
      id: 393,
      name: 'Manufacture of cutlery, tools and general hardware',
      code: '25.7',
      selectable: true,
      parentId: 376,
    },
    {
      id: 394,
      name: 'Manufacture of cutlery',
      code: '25.71',
      selectable: true,
      parentId: 376,
    },
    {
      id: 395,
      name: 'Manufacture of locks and hinges',
      code: '25.72',
      selectable: true,
      parentId: 376,
    },
    {
      id: 396,
      name: 'Manufacture of tools',
      code: '25.73',
      selectable: true,
      parentId: 376,
    },
    {
      id: 397,
      name: 'Manufacture of other fabricated metal products',
      code: '25.9',
      selectable: false,
      parentId: 376,
    },
    {
      id: 398,
      name: 'Manufacture of steel drums and similar containers',
      code: '25.91',
      selectable: true,
      parentId: 397,
    },
    {
      id: 399,
      name: 'Manufacture of light metal packaging',
      code: '25.92',
      selectable: true,
      parentId: 397,
    },
    {
      id: 400,
      name: 'Manufacture of wire products, chain and springs',
      code: '25.93',
      selectable: true,
      parentId: 397,
    },
    {
      id: 401,
      name: 'Manufacture of fasteners and screw machine products',
      code: '25.94',
      selectable: true,
      parentId: 397,
    },
    {
      id: 402,
      name: 'Manufacture of other fabricated metal products n.e.c.',
      code: '25.99',
      selectable: true,
      parentId: 397,
    },
    {
      id: 403,
      name: 'Manufacture of computer, electronic and optical products',
      code: '26',
      selectable: false,
      parentId: 115,
    },
    {
      id: 404,
      name: 'Manufacture of electronic components and boards',
      code: '26.1',
      selectable: false,
      parentId: 403,
    },
    {
      id: 405,
      name: 'Manufacture of electronic components',
      code: '26.11',
      selectable: true,
      parentId: 404,
    },
    {
      id: 406,
      name: 'Manufacture of loaded electronic boards',
      code: '26.12',
      selectable: true,
      parentId: 404,
    },
    {
      id: 407,
      name: 'Manufacture of computers and peripheral equipment',
      code: '26.2',
      selectable: false,
      parentId: 403,
    },
    {
      id: 408,
      name: 'Manufacture of computers and peripheral equipment',
      code: '26.20',
      selectable: true,
      parentId: 407,
    },
    {
      id: 409,
      name: 'Manufacture of communication equipment',
      code: '26.3',
      selectable: false,
      parentId: 403,
    },
    {
      id: 410,
      name: 'Manufacture of communication equipment',
      code: '26.30',
      selectable: true,
      parentId: 409,
    },
    {
      id: 411,
      name: 'Manufacture of consumer electronics',
      code: '26.4',
      selectable: false,
      parentId: 403,
    },
    {
      id: 412,
      name: 'Manufacture of consumer electronics',
      code: '26.40',
      selectable: true,
      parentId: 411,
    },
    {
      id: 413,
      name: 'Manufacture of instruments and appliances for measuring, testing and navigation; watches and clocks',
      code: '26.5',
      selectable: false,
      parentId: 403,
    },
    {
      id: 414,
      name: 'Manufacture of instruments and appliances for measuring, testing and navigation',
      code: '26.51',
      selectable: true,
      parentId: 413,
    },
    {
      id: 415,
      name: 'Manufacture of electric meters',
      code: '26.51.10',
      selectable: true,
      parentId: 413,
    },
    {
      id: 416,
      name: 'Manufacture of water meters',
      code: '26.51.20',
      selectable: true,
      parentId: 413,
    },
    {
      id: 417,
      name: 'Manufacture of gas meters',
      code: '26.51.30',
      selectable: true,
      parentId: 413,
    },
    {
      id: 418,
      name: 'Manufacture of automatic regulation and control devices',
      code: '26.51.40',
      selectable: true,
      parentId: 413,
    },
    {
      id: 419,
      name: 'Manufacture of watches and clocks',
      code: '26.52',
      selectable: true,
      parentId: 413,
    },
    {
      id: 420,
      name: 'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
      code: '26.6',
      selectable: false,
      parentId: 403,
    },
    {
      id: 421,
      name: 'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
      code: '26.60',
      selectable: true,
      parentId: 420,
    },
    {
      id: 422,
      name: 'Manufacture of optical instruments and photographic equipment',
      code: '26.7',
      selectable: false,
      parentId: 403,
    },
    {
      id: 423,
      name: 'Manufacture of optical instruments and photographic equipment',
      code: '26.70',
      selectable: true,
      parentId: 422,
    },
    {
      id: 424,
      name: 'Manufacture of magnetic and optical media',
      code: '26.8',
      selectable: false,
      parentId: 403,
    },
    {
      id: 425,
      name: 'Manufacture of magnetic and optical media',
      code: '26.80',
      selectable: true,
      parentId: 424,
    },
    {
      id: 426,
      name: 'Manufacture of electrical equipment',
      code: '27',
      selectable: false,
      parentId: 115,
    },
    {
      id: 507,
      name: 'Manufacture of games and toys',
      code: '32.4',
      selectable: false,
      parentId: 498,
    },
    {
      id: 427,
      name: 'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
      code: '27.1',
      selectable: false,
      parentId: 426,
    },
    {
      id: 428,
      name: 'Manufacture of electric motors, generators and transformers',
      code: '27.11',
      selectable: true,
      parentId: 427,
    },
    {
      id: 429,
      name: 'Manufacture of electricity distribution and control apparatus',
      code: '27.12',
      selectable: true,
      parentId: 427,
    },
    {
      id: 430,
      name: 'Manufacture of batteries and accumulators',
      code: '27.2',
      selectable: false,
      parentId: 426,
    },
    {
      id: 431,
      name: 'Manufacture of batteries and accumulators',
      code: '27.20',
      selectable: true,
      parentId: 430,
    },
    {
      id: 432,
      name: 'Manufacture of wiring and wiring devices',
      code: '27.3',
      selectable: true,
      parentId: 426,
    },
    {
      id: 433,
      name: 'Manufacture of fibre optic cables',
      code: '27.31',
      selectable: true,
      parentId: 426,
    },
    {
      id: 434,
      name: 'Manufacture of other electronic and electric wires and cables',
      code: '27.32',
      selectable: true,
      parentId: 426,
    },
    {
      id: 435,
      name: 'Manufacture of wiring devices',
      code: '27.33',
      selectable: true,
      parentId: 426,
    },
    {
      id: 436,
      name: 'Manufacture of electric lighting equipment',
      code: '27.4',
      selectable: false,
      parentId: 426,
    },
    {
      id: 437,
      name: 'Manufacture of electric lighting equipment',
      code: '27.40',
      selectable: true,
      parentId: 436,
    },
    {
      id: 438,
      name: 'Manufacture of domestic appliances',
      code: '27.5',
      selectable: false,
      parentId: 426,
    },
    {
      id: 439,
      name: 'Manufacture of electric domestic appliances',
      code: '27.51',
      selectable: true,
      parentId: 438,
    },
    {
      id: 440,
      name: 'Manufacture of non-electric domestic appliances',
      code: '27.52',
      selectable: true,
      parentId: 438,
    },
    {
      id: 441,
      name: 'Manufacture of other electrical equipment',
      code: '27.9',
      selectable: false,
      parentId: 426,
    },
    {
      id: 442,
      name: 'Manufacture of other electrical equipment',
      code: '27.90',
      selectable: true,
      parentId: 441,
    },
    {
      id: 443,
      name: 'Manufacture of machinery and equipment n.e.c.',
      code: '28',
      selectable: false,
      parentId: 115,
    },
    {
      id: 444,
      name: 'Manufacture of general—purpose machinery',
      code: '28.1',
      selectable: true,
      parentId: 443,
    },
    {
      id: 445,
      name: 'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
      code: '28.11',
      selectable: true,
      parentId: 443,
    },
    {
      id: 446,
      name: 'Manufacture of fluid power equipment',
      code: '28.12',
      selectable: true,
      parentId: 443,
    },
    {
      id: 447,
      name: 'Manufacture of other pumps and compressors',
      code: '28.13',
      selectable: true,
      parentId: 443,
    },
    {
      id: 448,
      name: 'Manufacture of other taps and valves',
      code: '28.14',
      selectable: true,
      parentId: 443,
    },
    {
      id: 449,
      name: 'Manufacture of bearings, gears, gearing and driving elements',
      code: '28.15',
      selectable: true,
      parentId: 443,
    },
    {
      id: 450,
      name: 'Manufacture of other general-purpose machinery',
      code: '28.2',
      selectable: false,
      parentId: 443,
    },
    {
      id: 451,
      name: 'Manufacture of ovens, furnaces and furnace burners',
      code: '28.21',
      selectable: true,
      parentId: 450,
    },
    {
      id: 452,
      name: 'Manufacture of lifting and handling equipment',
      code: '28.22',
      selectable: true,
      parentId: 450,
    },
    {
      id: 453,
      name: 'Manufacture of office machinery and equipment (except computers and peripheral equipment)',
      code: '28.23',
      selectable: true,
      parentId: 450,
    },
    {
      id: 454,
      name: 'Manufacture of power-driven hand tools',
      code: '28.24',
      selectable: true,
      parentId: 450,
    },
    {
      id: 455,
      name: 'Manufacture of non-domestic cooling and ventilation equipment',
      code: '28.25',
      selectable: true,
      parentId: 450,
    },
    {
      id: 456,
      name: 'Manufacture of other general-purpose machinery n.e.c.',
      code: '28.29',
      selectable: true,
      parentId: 450,
    },
    {
      id: 457,
      name: 'Manufacture of agricultural and forestry machinery',
      code: '28.3',
      selectable: false,
      parentId: 443,
    },
    {
      id: 458,
      name: 'Manufacture of agricultural and forestry machinery',
      code: '28.30',
      selectable: true,
      parentId: 457,
    },
    {
      id: 459,
      name: 'Manufacture of metal forming machinery and machine tools',
      code: '28.4',
      selectable: false,
      parentId: 443,
    },
    {
      id: 460,
      name: 'Manufacture of metal forming machinery',
      code: '28.41',
      selectable: true,
      parentId: 459,
    },
    {
      id: 461,
      name: 'Manufacture of other machine tools',
      code: '28.49',
      selectable: true,
      parentId: 459,
    },
    {
      id: 462,
      name: 'Manufacture of other special-purpose machinery',
      code: '28.9',
      selectable: false,
      parentId: 443,
    },
    {
      id: 463,
      name: 'Manufacture of machinery for metallurgy',
      code: '28.91',
      selectable: true,
      parentId: 462,
    },
    {
      id: 464,
      name: 'Manufacture of machinery for mining, quarrying and construction',
      code: '28.92',
      selectable: true,
      parentId: 462,
    },
    {
      id: 465,
      name: 'Manufacture of machinery for food, beverage and tobacco processing',
      code: '28.93',
      selectable: true,
      parentId: 462,
    },
    {
      id: 466,
      name: 'Manufacture of machinery for textile, apparel and leather production',
      code: '28.94',
      selectable: true,
      parentId: 462,
    },
    {
      id: 467,
      name: 'Manufacture of machinery for paper and paperboard production',
      code: '28.95',
      selectable: true,
      parentId: 462,
    },
    {
      id: 468,
      name: 'Manufacture of plastic and rubber machinery',
      code: '28.96',
      selectable: true,
      parentId: 462,
    },
    {
      id: 469,
      name: 'Manufacture of other special-purpose machinery n.e.c.',
      code: '28.99',
      selectable: true,
      parentId: 462,
    },
    {
      id: 470,
      name: 'Manufacture of motor vehicles, trailers and semi-trailers',
      code: '29',
      selectable: false,
      parentId: 115,
    },
    {
      id: 471,
      name: 'Manufacture of motor vehicles',
      code: '29.1',
      selectable: false,
      parentId: 470,
    },
    {
      id: 472,
      name: 'Manufacture of motor vehicles',
      code: '29.10',
      selectable: true,
      parentId: 471,
    },
    {
      id: 473,
      name: 'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
      code: '29.2',
      selectable: false,
      parentId: 470,
    },
    {
      id: 474,
      name: 'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
      code: '29.20',
      selectable: true,
      parentId: 473,
    },
    {
      id: 475,
      name: 'Manufacture of parts and accessories for motor vehicles',
      code: '29.3',
      selectable: false,
      parentId: 470,
    },
    {
      id: 476,
      name: 'Manufacture of electrical and electronic equipment for motor vehicles',
      code: '29.31',
      selectable: true,
      parentId: 475,
    },
    {
      id: 477,
      name: 'Manufacture of other parts and accessories for motor vehicles',
      code: '29.32',
      selectable: true,
      parentId: 475,
    },
    {
      id: 478,
      name: 'Manufacture of other transport equipment',
      code: '30',
      selectable: false,
      parentId: 115,
    },
    {
      id: 479,
      name: 'Building of ships and boats',
      code: '30.1',
      selectable: true,
      parentId: 478,
    },
    {
      id: 480,
      name: 'Building of ships and floating structures',
      code: '30.11',
      selectable: true,
      parentId: 478,
    },
    {
      id: 481,
      name: 'Building of pleasure and sporting boats',
      code: '30.12',
      selectable: true,
      parentId: 478,
    },
    {
      id: 482,
      name: 'Manufacture of railway locomotives and rolling stock',
      code: '30.2',
      selectable: false,
      parentId: 478,
    },
    {
      id: 483,
      name: 'Manufacture of railway locomotives and rolling stock',
      code: '30.20',
      selectable: true,
      parentId: 482,
    },
    {
      id: 484,
      name: 'Manufacture of air and spacecraft and related machinery',
      code: '30.3',
      selectable: false,
      parentId: 478,
    },
    {
      id: 485,
      name: 'Manufacture of air and spacecraft and related machinery',
      code: '30.30',
      selectable: true,
      parentId: 484,
    },
    {
      id: 486,
      name: 'Manufacture of military fighting vehicles',
      code: '30.4',
      selectable: false,
      parentId: 478,
    },
    {
      id: 487,
      name: 'Manufacture of military fighting vehicles',
      code: '30.40',
      selectable: true,
      parentId: 486,
    },
    {
      id: 488,
      name: 'Manufacture of transport equipment n.e.c.',
      code: '30.9',
      selectable: false,
      parentId: 478,
    },
    {
      id: 489,
      name: 'Manufacture of motorcycles',
      code: '30.91',
      selectable: true,
      parentId: 488,
    },
    {
      id: 490,
      name: 'Manufacture of bicycles and invalid carriages',
      code: '30.92',
      selectable: true,
      parentId: 488,
    },
    {
      id: 491,
      name: 'Manufacture of other transport equipment n.e.c.',
      code: '30.99',
      selectable: true,
      parentId: 488,
    },
    {
      id: 492,
      name: 'Manufacture of furniture',
      code: '31',
      selectable: false,
      parentId: 115,
    },
    {
      id: 493,
      name: 'Manufacture of furniture',
      code: '31.0',
      selectable: false,
      parentId: 492,
    },
    {
      id: 494,
      name: 'Manufacture of office and shop furniture',
      code: '31.01',
      selectable: true,
      parentId: 493,
    },
    {
      id: 495,
      name: 'Manufacture of kitchen furniture',
      code: '31.02',
      selectable: true,
      parentId: 493,
    },
    {
      id: 496,
      name: 'Manufacture of mattresses',
      code: '31.03',
      selectable: true,
      parentId: 493,
    },
    {
      id: 497,
      name: 'Manufacture of other furniture',
      code: '31.09',
      selectable: true,
      parentId: 493,
    },
    {
      id: 498,
      name: 'Other manufacturing',
      code: '32',
      selectable: false,
      parentId: 115,
    },
    {
      id: 499,
      name: 'Manufacture of jewellery, bijouterie and related articles',
      code: '32.1',
      selectable: true,
      parentId: 498,
    },
    {
      id: 500,
      name: 'Striking of coins',
      code: '32.11',
      selectable: true,
      parentId: 498,
    },
    {
      id: 501,
      name: 'Manufacture of jewellery and related articles',
      code: '32.12',
      selectable: true,
      parentId: 498,
    },
    {
      id: 502,
      name: 'Manufacture of imitation jewellery and related articles',
      code: '32.13',
      selectable: true,
      parentId: 498,
    },
    {
      id: 503,
      name: 'Manufacture of musical instruments',
      code: '32.2',
      selectable: false,
      parentId: 498,
    },
    {
      id: 504,
      name: 'Manufacture of musical instruments',
      code: '32.20',
      selectable: true,
      parentId: 503,
    },
    {
      id: 505,
      name: 'Manufacture of sports goods',
      code: '32.3',
      selectable: false,
      parentId: 498,
    },
    {
      id: 506,
      name: 'Manufacture of sports goods',
      code: '32.30',
      selectable: true,
      parentId: 505,
    },
    {
      id: 508,
      name: 'Manufacture of games and toys',
      code: '32.40',
      selectable: true,
      parentId: 507,
    },
    {
      id: 509,
      name: 'Manufacture of construction kits',
      code: '32.40.10',
      selectable: true,
      parentId: 507,
    },
    {
      id: 510,
      name: 'Manufacture of plastic toys',
      code: '32.40.20',
      selectable: true,
      parentId: 507,
    },
    {
      id: 511,
      name: 'Manufacture of wooden toys',
      code: '32.40.30',
      selectable: true,
      parentId: 507,
    },
    {
      id: 512,
      name: 'Manufacture of stuffed toys',
      code: '32.40.40',
      selectable: true,
      parentId: 507,
    },
    {
      id: 513,
      name: 'Manufacture of table or parlour games',
      code: '32.40.50',
      selectable: true,
      parentId: 507,
    },
    {
      id: 514,
      name: 'Manufacture of wheeled toys designed to be ridden, including tricycles',
      code: '32.40.60',
      selectable: true,
      parentId: 507,
    },
    {
      id: 515,
      name: 'Manufacture of electronic toys and games',
      code: '32.40.70',
      selectable: true,
      parentId: 507,
    },
    {
      id: 516,
      name: 'Manufacture of medical and dental instruments and supplies',
      code: '32.5',
      selectable: false,
      parentId: 498,
    },
    {
      id: 517,
      name: 'Manufacture of medical and dental instruments and supplies',
      code: '32.50',
      selectable: true,
      parentId: 516,
    },
    {
      id: 518,
      name: 'Manufacture of dental cement',
      code: '32.50.10',
      selectable: true,
      parentId: 516,
    },
    {
      id: 519,
      name: 'Manufacture of orthopaedic and other appliances',
      code: '32.50.20',
      selectable: true,
      parentId: 516,
    },
    {
      id: 520,
      name: 'Manufacture of prosthetic appliances',
      code: '32.50.30',
      selectable: true,
      parentId: 516,
    },
    {
      id: 521,
      name: 'Manufacturing n.e.c.',
      code: '32.9',
      selectable: false,
      parentId: 498,
    },
    {
      id: 522,
      name: 'Manufacture of brooms and brushes',
      code: '32.91',
      selectable: true,
      parentId: 521,
    },
    {
      id: 523,
      name: 'Other manufacturing n.e.c.',
      code: '32.99',
      selectable: true,
      parentId: 521,
    },
    {
      id: 524,
      name: 'Manufacture of wooden coffins and other funerary items',
      code: '32.99.10',
      selectable: true,
      parentId: 521,
    },
    {
      id: 525,
      name: 'Repair and installation of machinery and equipment',
      code: '33',
      selectable: false,
      parentId: 115,
    },
    {
      id: 526,
      name: 'Repair of fabricated metal products, machinery and equipment',
      code: '33.1',
      selectable: false,
      parentId: 525,
    },
    {
      id: 527,
      name: 'Repair of fabricated metal products',
      code: '33.11',
      selectable: true,
      parentId: 526,
    },
    {
      id: 528,
      name: 'Repair of machinery',
      code: '33.12',
      selectable: true,
      parentId: 526,
    },
    {
      id: 529,
      name: 'Repair of electronic and optical equipment',
      code: '33.13',
      selectable: true,
      parentId: 526,
    },
    {
      id: 530,
      name: 'Repair of electrical equipment',
      code: '33.14',
      selectable: true,
      parentId: 526,
    },
    {
      id: 531,
      name: 'Repair and maintenance of ships and boats',
      code: '33.15',
      selectable: true,
      parentId: 526,
    },
    {
      id: 532,
      name: 'Repair and maintenance of aircraft and spacecraft',
      code: '33.16',
      selectable: true,
      parentId: 526,
    },
    {
      id: 533,
      name: 'Repair and maintenance of other transport equipment',
      code: '33.17',
      selectable: true,
      parentId: 526,
    },
    {
      id: 534,
      name: 'Repair of other equipment',
      code: '33.19',
      selectable: true,
      parentId: 526,
    },
    {
      id: 535,
      name: 'Installation of industrial machinery and equipment',
      code: '33.2',
      selectable: false,
      parentId: 525,
    },
    {
      id: 536,
      name: 'Installation of industrial machinery and equipment',
      code: '33.20',
      selectable: true,
      parentId: 535,
    },
    {
      id: 537,
      name: 'ELECTRICITY, GAS, STEAM AND AIR CONDITIONING SUPPLY',
      code: 'D',
      selectable: false,
    },
    {
      id: 538,
      name: 'Electricity, gas, steam and air conditioning supply',
      code: '35',
      selectable: false,
      parentId: 537,
    },
    {
      id: 539,
      name: 'Electric power generation, transmission and distribution',
      code: '35.1',
      selectable: true,
      parentId: 538,
    },
    {
      id: 540,
      name: 'Production of electricity',
      code: '35.11',
      selectable: true,
      parentId: 538,
    },
    {
      id: 541,
      name: 'Transmission of electricity',
      code: '35.12',
      selectable: true,
      parentId: 538,
    },
    {
      id: 542,
      name: 'Distribution of electricity',
      code: '35.13',
      selectable: true,
      parentId: 538,
    },
    {
      id: 543,
      name: 'Trade of electricity',
      code: '35.14',
      selectable: true,
      parentId: 538,
    },
    {
      id: 544,
      name: 'Manufacture of gas; distribution of gaseous fuels through mains',
      code: '35.2',
      selectable: false,
      parentId: 538,
    },
    {
      id: 545,
      name: 'Manufacture of gas',
      code: '35.21',
      selectable: true,
      parentId: 544,
    },
    {
      id: 546,
      name: 'Distribution of gaseous fuels through mains',
      code: '35.22',
      selectable: true,
      parentId: 544,
    },
    {
      id: 547,
      name: 'Trade of gas through mains',
      code: '35.23',
      selectable: true,
      parentId: 544,
    },
    {
      id: 548,
      name: 'Steam and air conditioning supply',
      code: '35.3',
      selectable: false,
      parentId: 538,
    },
    {
      id: 549,
      name: 'Steam and air conditioning supply',
      code: '35.30',
      selectable: true,
      parentId: 548,
    },
    {
      id: 550,
      name: 'Steam supply',
      code: '35.30.10',
      selectable: true,
      parentId: 548,
    },
    {
      id: 551,
      name: 'Hot water supply',
      code: '35.30.20',
      selectable: true,
      parentId: 548,
    },
    {
      id: 552,
      name: 'WATER SUPPLY; SEWERAGE, WASTE MANAGEMENT AND REMEDIATION ACTIVITIES',
      code: 'E',
      selectable: false,
    },
    {
      id: 553,
      name: 'Water collection, treatment and supply',
      code: '36',
      selectable: false,
      parentId: 552,
    },
    {
      id: 554,
      name: 'Water collection, treatment and supply',
      code: '36.0',
      selectable: false,
      parentId: 553,
    },
    {
      id: 555,
      name: 'Water collection, treatment and supply',
      code: '36.00',
      selectable: true,
      parentId: 554,
    },
    {
      id: 556,
      name: 'Collection and purification of water',
      code: '36.00.10',
      selectable: true,
      parentId: 554,
    },
    {
      id: 557,
      name: 'Water supply to industrial enterprises',
      code: '36.00.20',
      selectable: true,
      parentId: 554,
    },
    {
      id: 558,
      name: 'Water supply to the population',
      code: '36.00.30',
      selectable: true,
      parentId: 554,
    },
    {
      id: 559,
      name: 'Sewerage',
      code: '37',
      selectable: false,
      parentId: 552,
    },
    {
      id: 560,
      name: 'Sewerage',
      code: '37.0',
      selectable: false,
      parentId: 559,
    },
    {
      id: 561,
      name: 'Sewerage',
      code: '37.00',
      selectable: true,
      parentId: 560,
    },
    {
      id: 562,
      name: 'Waste collection, treatment and disposal activities; materials recovery',
      code: '38',
      selectable: false,
      parentId: 552,
    },
    {
      id: 563,
      name: 'Waste collection',
      code: '38.1',
      selectable: true,
      parentId: 562,
    },
    {
      id: 564,
      name: 'Collection of non-hazardous waste',
      code: '38.11',
      selectable: true,
      parentId: 562,
    },
    {
      id: 565,
      name: 'Collection of hazardous waste',
      code: '38.12',
      selectable: true,
      parentId: 562,
    },
    {
      id: 566,
      name: 'Waste treatment and disposal',
      code: '38.2',
      selectable: true,
      parentId: 562,
    },
    {
      id: 567,
      name: 'Treatment and disposal of non-hazardous waste',
      code: '38.21',
      selectable: true,
      parentId: 562,
    },
    {
      id: 568,
      name: 'Treatment and disposal of hazardous waste',
      code: '38.22',
      selectable: true,
      parentId: 562,
    },
    {
      id: 569,
      name: 'Materials recovery',
      code: '38.3',
      selectable: true,
      parentId: 562,
    },
    {
      id: 570,
      name: 'Dismantling of wrecks',
      code: '38.31',
      selectable: true,
      parentId: 562,
    },
    {
      id: 571,
      name: 'Recovery of sorted materials',
      code: '38.32',
      selectable: true,
      parentId: 562,
    },
    {
      id: 572,
      name: 'Remediation activities and other waste management services',
      code: '39',
      selectable: false,
      parentId: 552,
    },
    {
      id: 573,
      name: 'Remediation activities and other waste management services',
      code: '39.0',
      selectable: false,
      parentId: 572,
    },
    {
      id: 574,
      name: 'Remediation activities and other waste management services',
      code: '39.00',
      selectable: true,
      parentId: 573,
    },
    {
      id: 575,
      name: 'CONSTRUCTION',
      code: 'F',
      selectable: false,
    },
    {
      id: 576,
      name: 'Construction of buildings',
      code: '41',
      selectable: false,
      parentId: 575,
    },
    {
      id: 577,
      name: 'Development of building projects',
      code: '41.1',
      selectable: false,
      parentId: 576,
    },
    {
      id: 578,
      name: 'Development of building projects',
      code: '41.10',
      selectable: true,
      parentId: 577,
    },
    {
      id: 579,
      name: 'Construction of residential and non-residential buildings',
      code: '41.2',
      selectable: false,
      parentId: 576,
    },
    {
      id: 580,
      name: 'Construction of residential and non-residential buildings',
      code: '41.20',
      selectable: true,
      parentId: 579,
    },
    {
      id: 581,
      name: 'New construction',
      code: '41.20.10',
      selectable: true,
      parentId: 579,
    },
    {
      id: 582,
      name: 'Repair, restoration and reconstruction of buildings',
      code: '41.20.20',
      selectable: true,
      parentId: 579,
    },
    {
      id: 583,
      name: 'Civil engineering',
      code: '42',
      selectable: false,
      parentId: 575,
    },
    {
      id: 584,
      name: 'Construction of roads and railways',
      code: '42.1',
      selectable: true,
      parentId: 583,
    },
    {
      id: 585,
      name: 'Construction of roads and motorways',
      code: '42.11',
      selectable: true,
      parentId: 583,
    },
    {
      id: 586,
      name: 'Painting of markings on road surfaces and car parks',
      code: '42.11.10',
      selectable: true,
      parentId: 583,
    },
    {
      id: 587,
      name: 'Construction of railways and underground railways',
      code: '42.12',
      selectable: true,
      parentId: 583,
    },
    {
      id: 588,
      name: 'Construction of bridges and tunnels',
      code: '42.13',
      selectable: true,
      parentId: 583,
    },
    {
      id: 589,
      name: 'Construction of utility projects',
      code: '42.2',
      selectable: true,
      parentId: 583,
    },
    {
      id: 590,
      name: 'Construction of utility projects for fluids',
      code: '42.21',
      selectable: true,
      parentId: 583,
    },
    {
      id: 591,
      name: 'Construction of utility projects for electricity and telecommunications',
      code: '42.22',
      selectable: true,
      parentId: 583,
    },
    {
      id: 592,
      name: 'Construction of other civil engineering projects',
      code: '42.9',
      selectable: false,
      parentId: 583,
    },
    {
      id: 593,
      name: 'Construction of water projects',
      code: '42.91',
      selectable: true,
      parentId: 592,
    },
    {
      id: 594,
      name: 'Construction of other civil engineering projects n.e.c.',
      code: '42.99',
      selectable: true,
      parentId: 592,
    },
    {
      id: 595,
      name: 'Specialised construction activities',
      code: '43',
      selectable: false,
      parentId: 575,
    },
    {
      id: 596,
      name: 'Demolition and site preparation',
      code: '43.1',
      selectable: false,
      parentId: 595,
    },
    {
      id: 597,
      name: 'Demolition',
      code: '43.11',
      selectable: true,
      parentId: 596,
    },
    {
      id: 598,
      name: 'Site preparation',
      code: '43.12',
      selectable: true,
      parentId: 596,
    },
    {
      id: 599,
      name: 'Preliminary earth moving for road building',
      code: '43.12.10',
      selectable: true,
      parentId: 596,
    },
    {
      id: 600,
      name: 'Earth moving at the site',
      code: '43.12.20',
      selectable: true,
      parentId: 596,
    },
    {
      id: 601,
      name: 'Construction of drainage and irrigation systems',
      code: '43.12.30',
      selectable: true,
      parentId: 596,
    },
    {
      id: 602,
      name: 'Test drilling and boring',
      code: '43.13',
      selectable: true,
      parentId: 596,
    },
    {
      id: 603,
      name: 'Electrical, plumbing and other construction installation activities',
      code: '43.2',
      selectable: false,
      parentId: 595,
    },
    {
      id: 604,
      name: 'Electrical installation',
      code: '43.21',
      selectable: true,
      parentId: 603,
    },
    {
      id: 605,
      name: 'Installation of electrical wiring and fittings in buildings',
      code: '43.21.10',
      selectable: true,
      parentId: 603,
    },
    {
      id: 606,
      name: 'Installation of fire alarm and burglar alarm systems',
      code: '43.21.20',
      selectable: true,
      parentId: 603,
    },
    {
      id: 607,
      name: 'Installation of aerials, communication systems',
      code: '43.21.30',
      selectable: true,
      parentId: 603,
    },
    {
      id: 608,
      name: 'Plumbing, heat and air conditioning installation',
      code: '43.22',
      selectable: true,
      parentId: 603,
    },
    {
      id: 609,
      name: 'Other construction installation',
      code: '43.29',
      selectable: true,
      parentId: 603,
    },
    {
      id: 610,
      name: 'Building completion and finishing',
      code: '43.3',
      selectable: false,
      parentId: 595,
    },
    {
      id: 611,
      name: 'Plastering',
      code: '43.31',
      selectable: true,
      parentId: 610,
    },
    {
      id: 612,
      name: 'Joinery installation',
      code: '43.32',
      selectable: true,
      parentId: 610,
    },
    {
      id: 613,
      name: 'Floor and wall covering',
      code: '43.33',
      selectable: true,
      parentId: 610,
    },
    {
      id: 614,
      name: 'Painting and glazing',
      code: '43.34',
      selectable: true,
      parentId: 610,
    },
    {
      id: 615,
      name: 'Other building completion and finishing',
      code: '43.39',
      selectable: true,
      parentId: 610,
    },
    {
      id: 616,
      name: 'Other specialised construction activities',
      code: '43.9',
      selectable: false,
      parentId: 595,
    },
    {
      id: 617,
      name: 'Roofing activities',
      code: '43.91',
      selectable: true,
      parentId: 616,
    },
    {
      id: 618,
      name: 'Other specialised construction activities n.e.c.',
      code: '43.99',
      selectable: true,
      parentId: 616,
    },
    {
      id: 619,
      name: 'WHOLESALE AND RETAIL TRADE; REPAIR OF\nMOTOR VEHICLES AND MOTORCYCLE',
      code: 'G',
      selectable: false,
    },
    {
      id: 620,
      name: 'Wholesale and retail trade and repair of motor vehicles and motorcycles',
      code: '45',
      selectable: false,
      parentId: 619,
    },
    {
      id: 621,
      name: 'Sale of motor vehicles',
      code: '45.1',
      selectable: false,
      parentId: 620,
    },
    {
      id: 622,
      name: 'Sale of cars and light motor vehicles',
      code: '45.11',
      selectable: true,
      parentId: 621,
    },
    {
      id: 623,
      name: 'Sale of other motor vehicles',
      code: '45.19',
      selectable: true,
      parentId: 621,
    },
    {
      id: 624,
      name: 'Maintenance and repair of motor vehicles',
      code: '45.2',
      selectable: false,
      parentId: 620,
    },
    {
      id: 625,
      name: 'Maintenance and repair of motor vehicles',
      code: '45.20',
      selectable: true,
      parentId: 624,
    },
    {
      id: 626,
      name: 'Sale of motor vehicle parts and accessories',
      code: '45.3',
      selectable: true,
      parentId: 620,
    },
    {
      id: 627,
      name: 'Wholesale trade of motor vehicle parts and accessories',
      code: '45.31',
      selectable: true,
      parentId: 620,
    },
    {
      id: 628,
      name: 'Retail trade of motor vehicle parts and accessories',
      code: '45.32',
      selectable: true,
      parentId: 620,
    },
    {
      id: 629,
      name: 'Sale, maintenance and repair of motorcycles and related parts and accessories',
      code: '45.4',
      selectable: false,
      parentId: 620,
    },
    {
      id: 630,
      name: 'Sale, maintenance and repair of motorcycles and related parts and accessories',
      code: '45.40',
      selectable: true,
      parentId: 629,
    },
    {
      id: 631,
      name: 'Wholesale trade, except of motor vehicles and motorcycles',
      code: '46',
      selectable: false,
      parentId: 619,
    },
    {
      id: 632,
      name: 'Wholesale on a fee or contract basis',
      code: '46.1',
      selectable: true,
      parentId: 631,
    },
    {
      id: 633,
      name: 'Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods',
      code: '46.11',
      selectable: true,
      parentId: 631,
    },
    {
      id: 634,
      name: 'Agents involved in the sale of fuels, ores, metals and industrial chemicals',
      code: '46.12',
      selectable: true,
      parentId: 631,
    },
    {
      id: 635,
      name: 'Agents involved in the sale of timber and building materials',
      code: '46.13',
      selectable: true,
      parentId: 631,
    },
    {
      id: 636,
      name: 'Agents involved in the sale of machinery, industrial equipment, ships and aircraft',
      code: '46.14',
      selectable: true,
      parentId: 631,
    },
    {
      id: 637,
      name: 'Agents involved in the sale of furniture, household goods, hardware and ironmongery',
      code: '46.15',
      selectable: true,
      parentId: 631,
    },
    {
      id: 638,
      name: 'Agents involved in the sale of textiles, clothing, fur, footwear and leather goods',
      code: '46.16',
      selectable: true,
      parentId: 631,
    },
    {
      id: 639,
      name: 'Agents involved in the sale of food, beverages and tobacco',
      code: '46.17',
      selectable: true,
      parentId: 631,
    },
    {
      id: 640,
      name: 'Agents specialised in the sale of other particular products',
      code: '46.18',
      selectable: true,
      parentId: 631,
    },
    {
      id: 641,
      name: 'Agents involved in the sale of a variety of goods',
      code: '46.19',
      selectable: true,
      parentId: 631,
    },
    {
      id: 642,
      name: 'Wholesale of agricultural raw materials and live animals',
      code: '46.2',
      selectable: true,
      parentId: 631,
    },
    {
      id: 643,
      name: 'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
      code: '46.21',
      selectable: true,
      parentId: 631,
    },
    {
      id: 644,
      name: 'Wholesale of flowers and plants',
      code: '46.22',
      selectable: true,
      parentId: 631,
    },
    {
      id: 645,
      name: 'Wholesale of live animals',
      code: '46.23',
      selectable: true,
      parentId: 631,
    },
    {
      id: 646,
      name: 'Wholesale of hides, skins and leather',
      code: '46.24',
      selectable: true,
      parentId: 631,
    },
    {
      id: 647,
      name: 'Wholesale of food, beverages and tobacco',
      code: '46.3',
      selectable: true,
      parentId: 631,
    },
    {
      id: 648,
      name: 'Wholesale of fruit and vegetables',
      code: '46.31',
      selectable: true,
      parentId: 631,
    },
    {
      id: 649,
      name: 'Wholesale of meat and meat products',
      code: '46.32',
      selectable: true,
      parentId: 631,
    },
    {
      id: 650,
      name: 'Wholesale of dairy products, eggs and edible oils and fats',
      code: '46.33',
      selectable: true,
      parentId: 631,
    },
    {
      id: 651,
      name: 'Wholesale of beverages',
      code: '46.34',
      selectable: true,
      parentId: 631,
    },
    {
      id: 652,
      name: 'Wholesale of alcoholic beverages',
      code: '46.34.10',
      selectable: true,
      parentId: 631,
    },
    {
      id: 653,
      name: 'Wholesale of soft drinks',
      code: '46.34.20',
      selectable: true,
      parentId: 631,
    },
    {
      id: 654,
      name: 'Wholesale of tobacco products',
      code: '46.35',
      selectable: true,
      parentId: 631,
    },
    {
      id: 655,
      name: 'Wholesale of sugar and chocolate and sugar confectionery',
      code: '46.36',
      selectable: true,
      parentId: 631,
    },
    {
      id: 656,
      name: 'Wholesale of coffee, tea, cocoa and spices',
      code: '46.37',
      selectable: true,
      parentId: 631,
    },
    {
      id: 657,
      name: 'Wholesale of other food, including fish, crustaceans and molluscs',
      code: '46.38',
      selectable: true,
      parentId: 631,
    },
    {
      id: 658,
      name: 'Non-specialised wholesale of food, beverages and tobacco',
      code: '46.39',
      selectable: true,
      parentId: 631,
    },
    {
      id: 659,
      name: 'Wholesale of household goods',
      code: '46.4',
      selectable: false,
      parentId: 631,
    },
    {
      id: 660,
      name: 'Wholesale of textiles',
      code: '46.41',
      selectable: true,
      parentId: 659,
    },
    {
      id: 661,
      name: 'Wholesale of clothing and footwear',
      code: '46.42',
      selectable: true,
      parentId: 659,
    },
    {
      id: 662,
      name: 'Wholesale of rubber footwear',
      code: '46.42.10',
      selectable: true,
      parentId: 659,
    },
    {
      id: 663,
      name: 'Wholesale of leather footwear',
      code: '46.42.20',
      selectable: true,
      parentId: 659,
    },
    {
      id: 664,
      name: 'Wholesale of fur articles',
      code: '46.42.30',
      selectable: true,
      parentId: 659,
    },
    {
      id: 665,
      name: 'Wholesale of clothing and clothing accessories',
      code: '46.42.40',
      selectable: true,
      parentId: 659,
    },
    {
      id: 666,
      name: 'Wholesale of electrical household appliances',
      code: '46.43',
      selectable: true,
      parentId: 659,
    },
    {
      id: 667,
      name: 'Wholesale of china and glassware and cleaning materials',
      code: '46.44',
      selectable: true,
      parentId: 659,
    },
    {
      id: 668,
      name: 'Wholesale of porcelain and glass articles',
      code: '46.44.10',
      selectable: true,
      parentId: 659,
    },
    {
      id: 669,
      name: 'Wholesale of detergents',
      code: '46.44.20',
      selectable: true,
      parentId: 659,
    },
    {
      id: 670,
      name: 'Wholesale of perfume and cosmetics',
      code: '46.45',
      selectable: true,
      parentId: 659,
    },
    {
      id: 671,
      name: 'Wholesale of pharmaceutical goods',
      code: '46.46',
      selectable: true,
      parentId: 659,
    },
    {
      id: 672,
      name: 'Wholesale of surgical and orthopaedic aids and prostheses',
      code: '46.46.10',
      selectable: true,
      parentId: 659,
    },
    {
      id: 673,
      name: 'Wholesale of medicines and drugs',
      code: '46.46.20',
      selectable: true,
      parentId: 659,
    },
    {
      id: 674,
      name: 'Wholesale of furniture, carpets and lighting equipment',
      code: '46.47',
      selectable: true,
      parentId: 659,
    },
    {
      id: 675,
      name: 'Wholesale of watches and jewellery',
      code: '46.48',
      selectable: true,
      parentId: 659,
    },
    {
      id: 676,
      name: 'Wholesale of other household goods',
      code: '46.49',
      selectable: true,
      parentId: 659,
    },
    {
      id: 677,
      name: 'Wholesale of information and communication equipment',
      code: '46.5',
      selectable: true,
      parentId: 631,
    },
    {
      id: 678,
      name: 'Wholesale of computers, computer peripheral equipment and software',
      code: '46.51',
      selectable: true,
      parentId: 631,
    },
    {
      id: 679,
      name: 'Wholesale of electronic and telecommunications equipment and parts',
      code: '46.52',
      selectable: true,
      parentId: 631,
    },
    {
      id: 680,
      name: 'Wholesale of other machinery, equipment and supplies',
      code: '46.6',
      selectable: false,
      parentId: 631,
    },
    {
      id: 681,
      name: 'Wholesale of agricultural machinery, equipment and supplies',
      code: '46.61',
      selectable: true,
      parentId: 680,
    },
    {
      id: 682,
      name: 'Wholesale of machine tools',
      code: '46.62',
      selectable: true,
      parentId: 680,
    },
    {
      id: 683,
      name: 'Wholesale of mining, construction and civil engineering machinery',
      code: '46.63',
      selectable: true,
      parentId: 680,
    },
    {
      id: 684,
      name: 'Wholesale of machinery for the textile industry and of sewing and knitting machines',
      code: '46.64',
      selectable: true,
      parentId: 680,
    },
    {
      id: 685,
      name: 'Wholesale of office furniture',
      code: '46.65',
      selectable: true,
      parentId: 680,
    },
    {
      id: 686,
      name: 'Wholesale of other office machinery and equipment',
      code: '46.66',
      selectable: true,
      parentId: 680,
    },
    {
      id: 687,
      name: 'Wholesale of other machinery and equipment',
      code: '46.69',
      selectable: true,
      parentId: 680,
    },
    {
      id: 688,
      name: 'Other specialised wholesale',
      code: '46.7',
      selectable: false,
      parentId: 631,
    },
    {
      id: 689,
      name: 'Wholesale of solid, liquid and gaseous fuels and related products',
      code: '46.71',
      selectable: true,
      parentId: 631,
    },
    {
      id: 690,
      name: 'Wholesale of solid fuel',
      code: '46.71.10',
      selectable: true,
      parentId: 631,
    },
    {
      id: 691,
      name: 'Wholesale of petrol',
      code: '46.71.20',
      selectable: true,
      parentId: 631,
    },
    {
      id: 692,
      name: 'Wholesale of gaseous fuel',
      code: '46.71.30',
      selectable: true,
      parentId: 631,
    },
    {
      id: 693,
      name: 'Wholesale of diesel fuel',
      code: '46.71.40',
      selectable: true,
      parentId: 631,
    },
    {
      id: 694,
      name: 'Wholesale of lubricants',
      code: '46.71.50',
      selectable: true,
      parentId: 631,
    },
    {
      id: 695,
      name: 'Wholesale of metals and metal ores',
      code: '46.72',
      selectable: true,
      parentId: 631,
    },
    {
      id: 696,
      name: 'Wholesale of wood, construction materials and sanitary equipment',
      code: '46.73',
      selectable: true,
      parentId: 631,
    },
    {
      id: 697,
      name: 'Wholesale of wallpaper',
      code: '46.73.10',
      selectable: true,
      parentId: 631,
    },
    {
      id: 698,
      name: 'Wholesale of hardware, plumbing and heating equipment and supplies',
      code: '46.74',
      selectable: true,
      parentId: 631,
    },
    {
      id: 699,
      name: 'Wholesale of hardware',
      code: '46.74.10',
      selectable: true,
      parentId: 631,
    },
    {
      id: 700,
      name: 'Wholesale of plumbing equipment',
      code: '46.74.20',
      selectable: true,
      parentId: 631,
    },
    {
      id: 701,
      name: 'Wholesale of heating equipment',
      code: '46.74.30',
      selectable: true,
      parentId: 631,
    },
    {
      id: 702,
      name: 'Wholesale of chemical products',
      code: '46.75',
      selectable: true,
      parentId: 631,
    },
    {
      id: 703,
      name: 'Wholesale of agrochemical products and fertilisers',
      code: '46.75.10',
      selectable: true,
      parentId: 631,
    },
    {
      id: 704,
      name: 'Wholesale of non-medicinal potent and poisonous substances',
      code: '46.75.20',
      selectable: true,
      parentId: 631,
    },
    {
      id: 705,
      name: 'Wholesale of other intermediate products',
      code: '46.76',
      selectable: true,
      parentId: 631,
    },
    {
      id: 706,
      name: 'Wholesale of paper rolls and paperboard',
      code: '46.76.10',
      selectable: true,
      parentId: 631,
    },
    {
      id: 707,
      name: 'Wholesale of flax fibre',
      code: '46.76.20',
      selectable: true,
      parentId: 631,
    },
    {
      id: 708,
      name: 'Wholesale of waste and scrap',
      code: '46.77',
      selectable: true,
      parentId: 631,
    },
    {
      id: 709,
      name: 'Non-specialised wholesale trade',
      code: '46.9',
      selectable: false,
      parentId: 631,
    },
    {
      id: 710,
      name: 'Non-specialised wholesale trade',
      code: '46.90',
      selectable: true,
      parentId: 709,
    },
    {
      id: 711,
      name: 'Retail trade, except of motor vehicles and motorcycles',
      code: '47',
      selectable: false,
      parentId: 619,
    },
    {
      id: 712,
      name: 'Retail sale in non-specialised stores',
      code: '47.1',
      selectable: false,
      parentId: 711,
    },
    {
      id: 713,
      name: 'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
      code: '47.11',
      selectable: true,
      parentId: 712,
    },
    {
      id: 714,
      name: 'Other retail sale in non-specialised stores',
      code: '47.19',
      selectable: true,
      parentId: 712,
    },
    {
      id: 715,
      name: 'Retail sale of food, beverages and tobacco in specialised stores',
      code: '47.2',
      selectable: false,
      parentId: 711,
    },
    {
      id: 716,
      name: 'Retail sale of fruit and vegetables in specialised stores',
      code: '47.21',
      selectable: true,
      parentId: 715,
    },
    {
      id: 717,
      name: 'Retail sale of meat and meat products in specialised stores',
      code: '47.22',
      selectable: true,
      parentId: 715,
    },
    {
      id: 718,
      name: 'Retail sale of fish, crustaceans and molluscs in specialised stores',
      code: '47.23',
      selectable: true,
      parentId: 715,
    },
    {
      id: 719,
      name: 'Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores',
      code: '47.24',
      selectable: false,
      parentId: 715,
    },
    {
      id: 720,
      name: 'Retail sale of bread, cakes and other flour products',
      code: '47.24.10',
      selectable: true,
      parentId: 715,
    },
    {
      id: 721,
      name: 'Retail sale of flour and sugar confectionery',
      code: '47.24.20',
      selectable: true,
      parentId: 715,
    },
    {
      id: 722,
      name: 'Retail sale of beverages in specialised stores',
      code: '47.25',
      selectable: false,
      parentId: 715,
    },
    {
      id: 723,
      name: 'Retail sale of alcoholic beverages',
      code: '47.25.10',
      selectable: true,
      parentId: 715,
    },
    {
      id: 724,
      name: 'Retail sale of soft drinks',
      code: '47.25.20',
      selectable: true,
      parentId: 715,
    },
    {
      id: 725,
      name: 'Retail sale of tobacco products in specialised stores',
      code: '47.26',
      selectable: true,
      parentId: 715,
    },
    {
      id: 726,
      name: 'Other retail sale of food in specialised stores',
      code: '47.29',
      selectable: true,
      parentId: 715,
    },
    {
      id: 727,
      name: 'Retail sale of automotive fuel in specialised stores',
      code: '47.3',
      selectable: false,
      parentId: 711,
    },
    {
      id: 728,
      name: 'Retail sale of automotive fuel in specialised stores',
      code: '47.30',
      selectable: true,
      parentId: 727,
    },
    {
      id: 729,
      name: 'Retail sale of information and communication equipment in specialised stores',
      code: '47.4',
      selectable: true,
      parentId: 711,
    },
    {
      id: 730,
      name: 'Retail sale of computers, peripheral units and software in specialised stores',
      code: '47.41',
      selectable: true,
      parentId: 711,
    },
    {
      id: 731,
      name: 'Retail sale of telecommunications equipment in specialised stores',
      code: '47.42',
      selectable: true,
      parentId: 711,
    },
    {
      id: 732,
      name: 'Retail sale of audio and video equipment in specialised stores',
      code: '47.43',
      selectable: true,
      parentId: 711,
    },
    {
      id: 733,
      name: 'Retail sale of other household equipment in specialised stores',
      code: '47.5',
      selectable: true,
      parentId: 711,
    },
    {
      id: 734,
      name: 'Retail sale of textiles in specialised stores',
      code: '47.51',
      selectable: true,
      parentId: 711,
    },
    {
      id: 735,
      name: 'Retail sale of hardware, paints and glass in specialised stores',
      code: '47.52',
      selectable: true,
      parentId: 711,
    },
    {
      id: 736,
      name: 'Retail sale of dyes, varnishes and polishes',
      code: '47.52.10',
      selectable: true,
      parentId: 711,
    },
    {
      id: 737,
      name: 'Retail sale of flat glass',
      code: '47.52.20',
      selectable: true,
      parentId: 711,
    },
    {
      id: 738,
      name: 'Retail sale of building materials',
      code: '47.52.30',
      selectable: true,
      parentId: 711,
    },
    {
      id: 739,
      name: 'Retail sale of materials and equipment for minor repairs',
      code: '47.52.40',
      selectable: true,
      parentId: 711,
    },
    {
      id: 740,
      name: 'Retail sale of carpets, rugs, wall and floor coverings in specialised stores',
      code: '47.53',
      selectable: true,
      parentId: 711,
    },
    {
      id: 741,
      name: 'Retail sale of electrical household appliances in specialised stores',
      code: '47.54',
      selectable: true,
      parentId: 711,
    },
    {
      id: 742,
      name: 'Retail sale of furniture, lighting equipment and other household articles in specialised stores',
      code: '47.59',
      selectable: true,
      parentId: 711,
    },
    {
      id: 743,
      name: 'Retail sale of cultural and recreation goods in specialised stores',
      code: '47.6',
      selectable: true,
      parentId: 711,
    },
    {
      id: 744,
      name: 'Retail sale of books in specialised stores',
      code: '47.61',
      selectable: true,
      parentId: 711,
    },
    {
      id: 745,
      name: 'Retail sale of newspapers and stationery in specialised stores',
      code: '47.62',
      selectable: true,
      parentId: 711,
    },
    {
      id: 746,
      name: 'Retail sale of music and video recordings in specialised stores',
      code: '47.63',
      selectable: true,
      parentId: 711,
    },
    {
      id: 747,
      name: 'Retail sale of sporting equipment in specialised stores',
      code: '47.64',
      selectable: true,
      parentId: 711,
    },
    {
      id: 748,
      name: 'Retail sale of games and toys in specialised stores',
      code: '47.65',
      selectable: true,
      parentId: 711,
    },
    {
      id: 749,
      name: 'Retail sale of other goods in specialised stores',
      code: '47.7',
      selectable: true,
      parentId: 711,
    },
    {
      id: 750,
      name: 'Retail sale of clothing in specialised stores',
      code: '47.71',
      selectable: true,
      parentId: 711,
    },
    {
      id: 751,
      name: 'Retail sale of footwear and leather goods in specialised stores',
      code: '47.72',
      selectable: true,
      parentId: 711,
    },
    {
      id: 752,
      name: 'Dispensing chemist in specialised stores',
      code: '47.73',
      selectable: true,
      parentId: 711,
    },
    {
      id: 753,
      name: 'Retail sale of medical and orthopaedic goods in specialised stores',
      code: '47.74',
      selectable: true,
      parentId: 711,
    },
    {
      id: 840,
      name: 'Other accommodation',
      code: '55.9',
      selectable: false,
      parentId: 831,
    },
    {
      id: 754,
      name: 'Retail sale of cosmetic and toilet articles in specialised stores',
      code: '47.75',
      selectable: true,
      parentId: 711,
    },
    {
      id: 755,
      name: 'Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores',
      code: '47.76',
      selectable: true,
      parentId: 711,
    },
    {
      id: 756,
      name: 'Retail sale of watches and jewellery in specialised stores',
      code: '47.77',
      selectable: false,
      parentId: 711,
    },
    {
      id: 757,
      name: 'Other retail sale of new goods in specialised stores',
      code: '47.78',
      selectable: false,
      parentId: 711,
    },
    {
      id: 758,
      name: 'Specialised retail sale of souvenirs, craftwork and religious articles',
      code: '47.78.10',
      selectable: true,
      parentId: 757,
    },
    {
      id: 759,
      name: 'Specialised retail sale of household fuel oil, bottled gas, coal and wood',
      code: '47.78.20',
      selectable: true,
      parentId: 757,
    },
    {
      id: 760,
      name: 'Specialised retail sale of weapons and ammunition',
      code: '47.78.30',
      selectable: true,
      parentId: 757,
    },
    {
      id: 761,
      name: 'Retail sale of optical goods',
      code: '47.78.40',
      selectable: true,
      parentId: 757,
    },
    {
      id: 762,
      name: 'Other retail sale in specialised stores',
      code: '47.78.90',
      selectable: true,
      parentId: 757,
    },
    {
      id: 763,
      name: 'Retail sale of second-hand goods in stores',
      code: '47.79',
      selectable: true,
      parentId: 711,
    },
    {
      id: 764,
      name: 'Retail sale of antiques',
      code: '47.79.10',
      selectable: true,
      parentId: 711,
    },
    {
      id: 765,
      name: 'Retail sale via stalls and markets',
      code: '47.8',
      selectable: false,
      parentId: 711,
    },
    {
      id: 766,
      name: 'Retail sale via stalls and markets of food, beverages and tobacco products',
      code: '47.81',
      selectable: true,
      parentId: 765,
    },
    {
      id: 767,
      name: 'Retail sale via stalls and markets of textiles, clothing and footwear',
      code: '47.82',
      selectable: true,
      parentId: 765,
    },
    {
      id: 768,
      name: 'Retail sale via stalls and markets of other goods',
      code: '47.89',
      selectable: true,
      parentId: 765,
    },
    {
      id: 769,
      name: 'Retail trade not in stores, stalls or markets',
      code: '47.9',
      selectable: false,
      parentId: 711,
    },
    {
      id: 770,
      name: 'Retail sale via mail order houses or via Internet',
      code: '47.91',
      selectable: true,
      parentId: 769,
    },
    {
      id: 771,
      name: 'Other retail sale not in stores, stalls or markets',
      code: '47.99',
      selectable: true,
      parentId: 769,
    },
    {
      id: 772,
      name: 'Retail sale by door-to-door sales persons',
      code: '47.99.10',
      selectable: true,
      parentId: 769,
    },
    {
      id: 773,
      name: 'Retail sale by vending machines',
      code: '47.99.20',
      selectable: true,
      parentId: 769,
    },
    {
      id: 774,
      name: 'TRANSPORTATION AND STORAGE',
      code: 'H',
      selectable: false,
    },
    {
      id: 775,
      name: 'Land transport and transport via pipelines',
      code: '49',
      selectable: false,
      parentId: 774,
    },
    {
      id: 776,
      name: 'Passenger rail transport, interurban',
      code: '49.1',
      selectable: false,
      parentId: 775,
    },
    {
      id: 777,
      name: 'Passenger rail transport, interurban',
      code: '49.10',
      selectable: true,
      parentId: 776,
    },
    {
      id: 778,
      name: 'Freight rail transport',
      code: '49.2',
      selectable: false,
      parentId: 775,
    },
    {
      id: 779,
      name: 'Freight rail transport',
      code: '49.20',
      selectable: true,
      parentId: 778,
    },
    {
      id: 780,
      name: 'Other passenger land transport',
      code: '49.3',
      selectable: false,
      parentId: 775,
    },
    {
      id: 781,
      name: 'Urban and suburban passenger land transport',
      code: '49.31',
      selectable: true,
      parentId: 780,
    },
    {
      id: 782,
      name: 'Urban and suburban passenger transport by bus',
      code: '49.31.10',
      selectable: true,
      parentId: 780,
    },
    {
      id: 783,
      name: 'Urban and suburban passenger transport by trolleybus',
      code: '49.31.20',
      selectable: true,
      parentId: 780,
    },
    {
      id: 784,
      name: 'Taxi operation',
      code: '49.32',
      selectable: true,
      parentId: 780,
    },
    {
      id: 785,
      name: 'Other passenger land transport n.e.c.',
      code: '49.39',
      selectable: true,
      parentId: 780,
    },
    {
      id: 786,
      name: 'Freight transport by road and removal services',
      code: '49.4',
      selectable: false,
      parentId: 775,
    },
    {
      id: 787,
      name: 'Freight transport by road',
      code: '49.41',
      selectable: true,
      parentId: 786,
    },
    {
      id: 788,
      name: 'Removal services',
      code: '49.42',
      selectable: true,
      parentId: 786,
    },
    {
      id: 789,
      name: 'Transport via pipeline',
      code: '49.5',
      selectable: false,
      parentId: 775,
    },
    {
      id: 790,
      name: 'Transport via pipeline',
      code: '49.50',
      selectable: true,
      parentId: 789,
    },
    {
      id: 791,
      name: 'Water transport',
      code: '50',
      selectable: false,
      parentId: 774,
    },
    {
      id: 792,
      name: 'Sea and coastal passenger water transport',
      code: '50.1',
      selectable: false,
      parentId: 791,
    },
    {
      id: 793,
      name: 'Sea and coastal passenger water transport',
      code: '50.10',
      selectable: true,
      parentId: 792,
    },
    {
      id: 794,
      name: 'Sea and coastal freight water transport',
      code: '50.2',
      selectable: false,
      parentId: 791,
    },
    {
      id: 795,
      name: 'Sea and coastal freight water transport',
      code: '50.20',
      selectable: true,
      parentId: 794,
    },
    {
      id: 796,
      name: 'Inland passenger water transport',
      code: '50.3',
      selectable: false,
      parentId: 791,
    },
    {
      id: 797,
      name: 'Inland passenger water transport',
      code: '50.30',
      selectable: true,
      parentId: 796,
    },
    {
      id: 798,
      name: 'Inland freight water transport',
      code: '50.4',
      selectable: false,
      parentId: 791,
    },
    {
      id: 799,
      name: 'Inland freight water transport',
      code: '50.40',
      selectable: true,
      parentId: 798,
    },
    {
      id: 800,
      name: 'Air transport',
      code: '51',
      selectable: false,
      parentId: 774,
    },
    {
      id: 801,
      name: 'Passenger air transport',
      code: '51.1',
      selectable: false,
      parentId: 800,
    },
    {
      id: 802,
      name: 'Passenger air transport',
      code: '51.10',
      selectable: true,
      parentId: 801,
    },
    {
      id: 803,
      name: 'Freight air transport and space transport',
      code: '51.2',
      selectable: false,
      parentId: 800,
    },
    {
      id: 804,
      name: 'Freight air transport',
      code: '51.21',
      selectable: true,
      parentId: 803,
    },
    {
      id: 805,
      name: 'Space transport',
      code: '51.22',
      selectable: true,
      parentId: 803,
    },
    {
      id: 806,
      name: 'Warehousing and support activities for transportation',
      code: '52',
      selectable: false,
      parentId: 774,
    },
    {
      id: 807,
      name: 'Warehousing and storage',
      code: '52.1',
      selectable: false,
      parentId: 806,
    },
    {
      id: 808,
      name: 'Warehousing and storage',
      code: '52.10',
      selectable: true,
      parentId: 807,
    },
    {
      id: 809,
      name: 'Support activities for transportation',
      code: '52.2',
      selectable: false,
      parentId: 806,
    },
    {
      id: 810,
      name: 'Service activities incidental to land transportation',
      code: '52.21',
      selectable: true,
      parentId: 809,
    },
    {
      id: 811,
      name: 'Organisation and regulation of railway transport',
      code: '52.21.10',
      selectable: true,
      parentId: 809,
    },
    {
      id: 812,
      name: 'Operation of car parks',
      code: '52.21.20',
      selectable: true,
      parentId: 809,
    },
    {
      id: 813,
      name: 'Operation of technical equipment for the regulation of traffic',
      code: '52.21.30',
      selectable: true,
      parentId: 809,
    },
    {
      id: 814,
      name: 'Operation of streets, roads, bridges, tunnels',
      code: '52.21.40',
      selectable: true,
      parentId: 809,
    },
    {
      id: 815,
      name: 'Operation of overland transport stations',
      code: '52.21.50',
      selectable: true,
      parentId: 809,
    },
    {
      id: 816,
      name: 'Maintenance and minor repair of rolling stock',
      code: '52.21.60',
      selectable: true,
      parentId: 809,
    },
    {
      id: 817,
      name: 'Operation of railways',
      code: '52.21.70',
      selectable: true,
      parentId: 809,
    },
    {
      id: 818,
      name: 'Service activities incidental to water transportation',
      code: '52.22',
      selectable: true,
      parentId: 809,
    },
    {
      id: 819,
      name: 'Service activities incidental to air transportation',
      code: '52.23',
      selectable: true,
      parentId: 809,
    },
    {
      id: 820,
      name: 'Cargo handling',
      code: '52.24',
      selectable: true,
      parentId: 809,
    },
    {
      id: 821,
      name: 'Other transportation support activities',
      code: '52.29',
      selectable: true,
      parentId: 809,
    },
    {
      id: 822,
      name: 'Activities of customs agents',
      code: '52.29.10',
      selectable: true,
      parentId: 809,
    },
    {
      id: 823,
      name: 'Activities of freight and forwarding agents',
      code: '52.29.20',
      selectable: true,
      parentId: 809,
    },
    {
      id: 824,
      name: 'Preparation and issuing of transport documentation and driver’s trip tickets',
      code: '52.29.30',
      selectable: true,
      parentId: 809,
    },
    {
      id: 825,
      name: 'Postal and courier activities',
      code: '53',
      selectable: false,
      parentId: 774,
    },
    {
      id: 826,
      name: 'Postal activities under universal service obligation',
      code: '53.1',
      selectable: false,
      parentId: 825,
    },
    {
      id: 827,
      name: 'Postal activities under universal service obligation',
      code: '53.10',
      selectable: true,
      parentId: 826,
    },
    {
      id: 828,
      name: 'Other postal and courier activities',
      code: '53.2',
      selectable: false,
      parentId: 825,
    },
    {
      id: 829,
      name: 'Other postal and courier activities',
      code: '53.20',
      selectable: true,
      parentId: 826,
    },
    {
      id: 830,
      name: 'ACCOMMODATION AND FOOD SERVICE ACTIVITIES',
      code: 'I',
      selectable: false,
    },
    {
      id: 831,
      name: 'Accommodation',
      code: '55',
      selectable: false,
      parentId: 830,
    },
    {
      id: 832,
      name: 'Hotels and similar accommodation',
      code: '55.1',
      selectable: false,
      parentId: 831,
    },
    {
      id: 833,
      name: 'Hotels and similar accommodation',
      code: '55.10',
      selectable: true,
      parentId: 832,
    },
    {
      id: 834,
      name: 'Holiday and other short-stay accommodation',
      code: '55.2',
      selectable: false,
      parentId: 831,
    },
    {
      id: 835,
      name: 'Holiday and other short-stay accommodation',
      code: '55.20',
      selectable: true,
      parentId: 834,
    },
    {
      id: 836,
      name: 'Activities of vacation homes',
      code: '55.20.10',
      selectable: true,
      parentId: 834,
    },
    {
      id: 837,
      name: 'Activities of children’s camps',
      code: '55.20.20',
      selectable: true,
      parentId: 834,
    },
    {
      id: 838,
      name: 'Camping grounds, recreational vehicle parks and trailer parks',
      code: '55.3',
      selectable: false,
      parentId: 831,
    },
    {
      id: 839,
      name: 'Camping grounds, recreational vehicle parks and trailer parks',
      code: '55.30',
      selectable: true,
      parentId: 838,
    },
    {
      id: 841,
      name: 'Other accommodation',
      code: '55.90',
      selectable: true,
      parentId: 839,
    },
    {
      id: 842,
      name: 'Food and beverage service activities',
      code: '56',
      selectable: false,
      parentId: 830,
    },
    {
      id: 843,
      name: 'Restaurants and mobile food service activities',
      code: '56.1',
      selectable: false,
      parentId: 842,
    },
    {
      id: 844,
      name: 'Restaurants and mobile food service activities',
      code: '56.10',
      selectable: true,
      parentId: 843,
    },
    {
      id: 845,
      name: 'Event catering and other food service activities',
      code: '56.2',
      selectable: false,
      parentId: 842,
    },
    {
      id: 846,
      name: 'Event catering activities',
      code: '56.21',
      selectable: true,
      parentId: 845,
    },
    {
      id: 847,
      name: 'Other food service activities',
      code: '56.29',
      selectable: true,
      parentId: 845,
    },
    {
      id: 848,
      name: 'Beverage serving activities',
      code: '56.3',
      selectable: false,
      parentId: 842,
    },
    {
      id: 849,
      name: 'Beverage serving activities',
      code: '56.30',
      selectable: true,
      parentId: 848,
    },
    {
      id: 850,
      name: 'INFORMATION AND COMMUNICATION',
      code: 'J',
      selectable: false,
    },
    {
      id: 851,
      name: 'Publishing activities',
      code: '58',
      selectable: false,
      parentId: 850,
    },
    {
      id: 852,
      name: 'Publishing of books, periodicals and other publishing activities',
      code: '58.1',
      selectable: false,
      parentId: 851,
    },
    {
      id: 853,
      name: 'Book publishing',
      code: '58.11',
      selectable: true,
      parentId: 852,
    },
    {
      id: 854,
      name: 'Publishing of directories and mailing lists',
      code: '58.12',
      selectable: true,
      parentId: 852,
    },
    {
      id: 855,
      name: 'Publishing of newspapers',
      code: '58.13',
      selectable: true,
      parentId: 852,
    },
    {
      id: 856,
      name: 'Publishing of journals and periodicals',
      code: '58.14',
      selectable: true,
      parentId: 852,
    },
    {
      id: 857,
      name: 'Other publishing activities',
      code: '58.19',
      selectable: true,
      parentId: 852,
    },
    {
      id: 858,
      name: 'Software publishing',
      code: '58.2',
      selectable: false,
      parentId: 851,
    },
    {
      id: 859,
      name: 'Publishing of computer games',
      code: '58.21',
      selectable: true,
      parentId: 858,
    },
    {
      id: 860,
      name: 'Other software publishing',
      code: '58.29',
      selectable: true,
      parentId: 858,
    },
    {
      id: 861,
      name: 'Motion picture, video and television programme production, sound recording and music publishing activities',
      code: '59',
      selectable: false,
      parentId: 850,
    },
    {
      id: 862,
      name: 'Motion picture, video and television programme activities',
      code: '59.1',
      selectable: false,
      parentId: 861,
    },
    {
      id: 863,
      name: 'Motion picture, video and television programme production activities',
      code: '59.11',
      selectable: true,
      parentId: 862,
    },
    {
      id: 864,
      name: 'Motion picture, video and television programme post-production activities',
      code: '59.12',
      selectable: true,
      parentId: 862,
    },
    {
      id: 865,
      name: 'Motion picture, video and television programme distribution activities',
      code: '59.13',
      selectable: true,
      parentId: 862,
    },
    {
      id: 866,
      name: 'Motion picture projection activities',
      code: '59.14',
      selectable: true,
      parentId: 862,
    },
    {
      id: 867,
      name: 'Sound recording and music publishing activities',
      code: '59.2',
      selectable: false,
      parentId: 861,
    },
    {
      id: 868,
      name: 'Sound recording and music publishing activities',
      code: '59.20',
      selectable: true,
      parentId: 867,
    },
    {
      id: 869,
      name: 'Programming and broadcasting activities',
      code: '60',
      selectable: false,
      parentId: 850,
    },
    {
      id: 870,
      name: 'Radio broadcasting',
      code: '60.1',
      selectable: false,
      parentId: 869,
    },
    {
      id: 871,
      name: 'Radio broadcasting',
      code: '60.10',
      selectable: true,
      parentId: 870,
    },
    {
      id: 872,
      name: 'Television programming and broadcasting activities',
      code: '60.2',
      selectable: false,
      parentId: 869,
    },
    {
      id: 873,
      name: 'Television programming and broadcasting activities',
      code: '60.20',
      selectable: true,
      parentId: 872,
    },
    {
      id: 874,
      name: 'Telecommunications',
      code: '61',
      selectable: false,
      parentId: 850,
    },
    {
      id: 875,
      name: 'Wired telecommunications activities',
      code: '61.1',
      selectable: false,
      parentId: 874,
    },
    {
      id: 876,
      name: 'Wired telecommunications activities',
      code: '61.10',
      selectable: true,
      parentId: 875,
    },
    {
      id: 877,
      name: 'Wireless telecommunications activities',
      code: '61.2',
      selectable: false,
      parentId: 874,
    },
    {
      id: 878,
      name: 'Wireless telecommunications activities',
      code: '61.20',
      selectable: true,
      parentId: 877,
    },
    {
      id: 879,
      name: 'Satellite telecommunications activities',
      code: '61.3',
      selectable: false,
      parentId: 874,
    },
    {
      id: 880,
      name: 'Satellite telecommunications activities',
      code: '61.30',
      selectable: true,
      parentId: 879,
    },
    {
      id: 881,
      name: 'Other telecommunications activities',
      code: '61.9',
      selectable: false,
      parentId: 874,
    },
    {
      id: 882,
      name: 'Other telecommunications activities',
      code: '61.90',
      selectable: true,
      parentId: 881,
    },
    {
      id: 883,
      name: 'Computer programming, consultancy and related activities',
      code: '62',
      selectable: false,
      parentId: 850,
    },
    {
      id: 884,
      name: 'Computer programming, consultancy and related activities',
      code: '62.0',
      selectable: false,
      parentId: 883,
    },
    {
      id: 885,
      name: 'Computer programming activities',
      code: '62.01',
      selectable: true,
      parentId: 884,
    },
    {
      id: 886,
      name: 'Computer consultancy activities',
      code: '62.02',
      selectable: true,
      parentId: 884,
    },
    {
      id: 887,
      name: 'Computer facilities management activities',
      code: '62.03',
      selectable: true,
      parentId: 884,
    },
    {
      id: 888,
      name: 'Other information technology and computer service activities',
      code: '62.09',
      selectable: true,
      parentId: 884,
    },
    {
      id: 889,
      name: 'Information service activities',
      code: '63',
      selectable: false,
      parentId: 850,
    },
    {
      id: 890,
      name: 'Data processing, hosting and related activities; web portals',
      code: '63.1',
      selectable: false,
      parentId: 889,
    },
    {
      id: 891,
      name: 'Data processing, hosting and related activities',
      code: '63.11',
      selectable: true,
      parentId: 890,
    },
    {
      id: 892,
      name: 'Web portals',
      code: '63.12',
      selectable: true,
      parentId: 890,
    },
    {
      id: 893,
      name: 'Other information service activities',
      code: '63.9',
      selectable: false,
      parentId: 889,
    },
    {
      id: 894,
      name: 'News agency activities',
      code: '63.91',
      selectable: true,
      parentId: 893,
    },
    {
      id: 895,
      name: 'Other information service activities n.e.c.',
      code: '63.99',
      selectable: true,
      parentId: 893,
    },
    {
      id: 896,
      name: 'FINANCIAL AND INSURANCE ACTIVITIES',
      code: 'K',
      selectable: false,
    },
    {
      id: 897,
      name: 'Financial service activities, except insurance and pension funding',
      code: '64',
      selectable: false,
      parentId: 896,
    },
    {
      id: 898,
      name: 'Monetary intermediation',
      code: '64.1',
      selectable: false,
      parentId: 897,
    },
    {
      id: 899,
      name: 'Central banking',
      code: '64.11',
      selectable: true,
      parentId: 898,
    },
    {
      id: 900,
      name: 'Other monetary intermediation',
      code: '64.19',
      selectable: true,
      parentId: 898,
    },
    {
      id: 901,
      name: 'Activities of holding companies',
      code: '64.2',
      selectable: false,
      parentId: 897,
    },
    {
      id: 902,
      name: 'Activities of holding companies',
      code: '64.20',
      selectable: true,
      parentId: 901,
    },
    {
      id: 903,
      name: 'Trusts, funds and similar financial entities',
      code: '64.3',
      selectable: false,
      parentId: 897,
    },
    {
      id: 904,
      name: 'Trusts, funds and similar financial entities',
      code: '64.30',
      selectable: true,
      parentId: 903,
    },
    {
      id: 905,
      name: 'Other financial service activities, except insurance and pension funding',
      code: '64.9',
      selectable: false,
      parentId: 897,
    },
    {
      id: 906,
      name: 'Financial leasing',
      code: '64.91',
      selectable: true,
      parentId: 905,
    },
    {
      id: 907,
      name: 'Other credit granting',
      code: '64.92',
      selectable: true,
      parentId: 905,
    },
    {
      id: 908,
      name: 'Other financial service activities, except insurance and pension funding n.e.c.',
      code: '64.99',
      selectable: true,
      parentId: 905,
    },
    {
      id: 909,
      name: 'Insurance, reinsurance and pension funding, except compulsory social security',
      code: '65',
      selectable: false,
      parentId: 896,
    },
    {
      id: 910,
      name: 'Insurance',
      code: '65.1',
      selectable: false,
      parentId: 909,
    },
    {
      id: 911,
      name: 'Life insurance',
      code: '65.11',
      selectable: true,
      parentId: 910,
    },
    {
      id: 912,
      name: 'Non-life insurance',
      code: '65.12',
      selectable: true,
      parentId: 910,
    },
    {
      id: 913,
      name: 'Travel insurance',
      code: '65.12.10',
      selectable: true,
      parentId: 910,
    },
    {
      id: 914,
      name: 'Reinsurance',
      code: '65.2',
      selectable: false,
      parentId: 909,
    },
    {
      id: 915,
      name: 'Reinsurance',
      code: '65.20',
      selectable: true,
      parentId: 914,
    },
    {
      id: 916,
      name: 'Pension funding',
      code: '65.3',
      selectable: false,
      parentId: 909,
    },
    {
      id: 917,
      name: 'Pension funding',
      code: '65.30',
      selectable: true,
      parentId: 916,
    },
    {
      id: 918,
      name: 'Activities auxiliary to financial services and insurance activities',
      code: '66',
      selectable: false,
      parentId: 896,
    },
    {
      id: 919,
      name: 'Activities auxiliary to financial services, except insurance and pension funding',
      code: '66.1',
      selectable: false,
      parentId: 918,
    },
    {
      id: 920,
      name: 'Administration of financial markets',
      code: '66.11',
      selectable: true,
      parentId: 919,
    },
    {
      id: 921,
      name: 'Security and commodity contracts brokerage',
      code: '66.12',
      selectable: true,
      parentId: 919,
    },
    {
      id: 922,
      name: 'Other activities auxiliary to financial services, except insurance and pension funding',
      code: '66.19',
      selectable: true,
      parentId: 919,
    },
    {
      id: 923,
      name: 'Activities auxiliary to insurance and pension funding',
      code: '66.2',
      selectable: false,
      parentId: 918,
    },
    {
      id: 924,
      name: 'Risk and damage evaluation',
      code: '66.21',
      selectable: true,
      parentId: 922,
    },
    {
      id: 925,
      name: 'Activities of insurance agents and brokers',
      code: '66.22',
      selectable: true,
      parentId: 922,
    },
    {
      id: 926,
      name: 'Other activities auxiliary to insurance and pension funding',
      code: '66.29',
      selectable: true,
      parentId: 922,
    },
    {
      id: 927,
      name: 'Fund management activities',
      code: '66.3',
      selectable: false,
      parentId: 918,
    },
    {
      id: 928,
      name: 'Fund management activities',
      code: '66.30',
      selectable: true,
      parentId: 927,
    },
    {
      id: 929,
      name: 'REAL ESTATE ACTIVITIES',
      code: 'L',
      selectable: false,
    },
    {
      id: 930,
      name: 'Real estate activities',
      code: '68',
      selectable: false,
      parentId: 929,
    },
    {
      id: 931,
      name: 'Buying and selling of own real estate',
      code: '68.1',
      selectable: false,
      parentId: 930,
    },
    {
      id: 932,
      name: 'Buying and selling of own real estate',
      code: '68.10',
      selectable: true,
      parentId: 931,
    },
    {
      id: 933,
      name: 'Renting and operating of own or leased real estate',
      code: '68.2',
      selectable: false,
      parentId: 930,
    },
    {
      id: 934,
      name: 'Renting and operating of own or leased real estate',
      code: '68.20',
      selectable: true,
      parentId: 933,
    },
    {
      id: 935,
      name: 'Real estate activities on a fee or contract basis',
      code: '68.3',
      selectable: true,
      parentId: 930,
    },
    {
      id: 936,
      name: 'Real estate agencies',
      code: '68.31',
      selectable: true,
      parentId: 930,
    },
    {
      id: 937,
      name: 'Assessment of real estate',
      code: '68.31.10',
      selectable: true,
      parentId: 930,
    },
    {
      id: 938,
      name: 'Management of real estate on a fee or contract basis',
      code: '68.32',
      selectable: true,
      parentId: 930,
    },
    {
      id: 939,
      name: 'PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES',
      code: 'M',
      selectable: false,
    },
    {
      id: 940,
      name: 'Legal and accounting activities',
      code: '69',
      selectable: false,
      parentId: 939,
    },
    {
      id: 941,
      name: 'Legal activities',
      code: '69.1',
      selectable: false,
      parentId: 940,
    },
    {
      id: 942,
      name: 'Legal activities',
      code: '69.10',
      selectable: true,
      parentId: 941,
    },
    {
      id: 943,
      name: 'Consultancies and legal representation in civil and criminal cases',
      code: '69.10.10',
      selectable: true,
      parentId: 941,
    },
    {
      id: 944,
      name: 'Consultancies and representation in employment disputes',
      code: '69.10.20',
      selectable: true,
      parentId: 941,
    },
    {
      id: 945,
      name: 'General advice and consultancies, preparation of legal documents',
      code: '69.10.30',
      selectable: true,
      parentId: 941,
    },
    {
      id: 946,
      name: 'Accounting, bookkeeping and auditing activities; tax consultancy',
      code: '69.2',
      selectable: false,
      parentId: 940,
    },
    {
      id: 947,
      name: 'Accounting, bookkeeping and auditing activities; tax consultancy',
      code: '69.20',
      selectable: true,
      parentId: 946,
    },
    {
      id: 948,
      name: 'Accounting and audit',
      code: '69.20.10',
      selectable: true,
      parentId: 946,
    },
    {
      id: 949,
      name: 'Preparation of financial accounts',
      code: '69.20.20',
      selectable: true,
      parentId: 946,
    },
    {
      id: 950,
      name: 'Managing of accounting of commercial operations of firms and other companies',
      code: '69.20.30',
      selectable: true,
      parentId: 946,
    },
    {
      id: 951,
      name: 'Tax consulting',
      code: '69.20.40',
      selectable: true,
      parentId: 946,
    },
    {
      id: 952,
      name: 'Activities of head offices; management consultancy activities',
      code: '70',
      selectable: false,
      parentId: 939,
    },
    {
      id: 953,
      name: 'Activities of head offices',
      code: '70.1',
      selectable: false,
      parentId: 952,
    },
    {
      id: 954,
      name: 'Activities of head offices',
      code: '70.10',
      selectable: true,
      parentId: 953,
    },
    {
      id: 955,
      name: 'Management consultancy activities',
      code: '70.2',
      selectable: true,
      parentId: 952,
    },
    {
      id: 956,
      name: 'Public relations and communication activities',
      code: '70.21',
      selectable: true,
      parentId: 952,
    },
    {
      id: 957,
      name: 'Business and other management consultancy activities',
      code: '70.22',
      selectable: true,
      parentId: 952,
    },
    {
      id: 958,
      name: 'Architectural and engineering activities; technical testing and analysis',
      code: '71',
      selectable: false,
      parentId: 939,
    },
    {
      id: 959,
      name: 'Architectural and engineering activities and related technical consultancy',
      code: '71.1',
      selectable: false,
      parentId: 958,
    },
    {
      id: 960,
      name: 'Architectural activities',
      code: '71.11',
      selectable: true,
      parentId: 959,
    },
    {
      id: 961,
      name: 'Engineering activities and related technical consultancy',
      code: '71.12',
      selectable: true,
      parentId: 959,
    },
    {
      id: 962,
      name: 'Engineering and technological activities',
      code: '71.12.10',
      selectable: true,
      parentId: 959,
    },
    {
      id: 963,
      name: 'Design and construction activities',
      code: '71.12.20',
      selectable: true,
      parentId: 959,
    },
    {
      id: 964,
      name: 'Street traffic design',
      code: '71.12.30',
      selectable: true,
      parentId: 959,
    },
    {
      id: 965,
      name: 'Geodesic activities',
      code: '71.12.40',
      selectable: true,
      parentId: 959,
    },
    {
      id: 966,
      name: 'Geological exploration and prospecting',
      code: '71.12.50',
      selectable: true,
      parentId: 959,
    },
    {
      id: 967,
      name: 'Forest and land management',
      code: '71.12.60',
      selectable: true,
      parentId: 959,
    },
    {
      id: 968,
      name: 'Technical testing and analysis',
      code: '71.2',
      selectable: false,
      parentId: 958,
    },
    {
      id: 969,
      name: 'Technical testing and analysis',
      code: '71.20',
      selectable: true,
      parentId: 968,
    },
    {
      id: 970,
      name: 'Environmental control, ecological monitoring',
      code: '71.20.10',
      selectable: true,
      parentId: 968,
    },
    {
      id: 971,
      name: 'Testing and analysis of materials',
      code: '71.20.20',
      selectable: true,
      parentId: 968,
    },
    {
      id: 972,
      name: 'Production quality testing and analysis',
      code: '71.20.30',
      selectable: true,
      parentId: 968,
    },
    {
      id: 973,
      name: 'Testing activities in the field of food hygiene, including veterinary testing and control in relation to food production',
      code: '71.20.40',
      selectable: true,
      parentId: 968,
    },
    {
      id: 974,
      name: 'Periodical inspection of traffic safety of motor vehicles',
      code: '71.20.50',
      selectable: true,
      parentId: 968,
    },
    {
      id: 975,
      name: 'Testing and calibration of measuring instruments',
      code: '71.20.60',
      selectable: true,
      parentId: 968,
    },
    {
      id: 976,
      name: 'Certification of products, control of requirements in their normative documents',
      code: '71.20.70',
      selectable: true,
      parentId: 968,
    },
    {
      id: 977,
      name: 'Scientific research and development',
      code: '72',
      selectable: false,
      parentId: 939,
    },
    {
      id: 978,
      name: 'Research and experimental development on natural sciences and engineering',
      code: '72.1',
      selectable: false,
      parentId: 977,
    },
    {
      id: 979,
      name: 'Research and experimental development on biotechnology',
      code: '72.11',
      selectable: true,
      parentId: 978,
    },
    {
      id: 980,
      name: 'Other research and experimental development on natural sciences and engineering',
      code: '72.19',
      selectable: true,
      parentId: 978,
    },
    {
      id: 981,
      name: 'Research and development in agriculture',
      code: '72.19.10',
      selectable: true,
      parentId: 978,
    },
    {
      id: 982,
      name: 'Research and development in natural sciences',
      code: '72.19.20',
      selectable: true,
      parentId: 978,
    },
    {
      id: 983,
      name: 'Research and development in mathematics',
      code: '72.19.30',
      selectable: true,
      parentId: 978,
    },
    {
      id: 984,
      name: 'Research and development in medical sciences',
      code: '72.19.40',
      selectable: true,
      parentId: 978,
    },
    {
      id: 985,
      name: 'Research and development in technical sciences',
      code: '72.19.50',
      selectable: true,
      parentId: 978,
    },
    {
      id: 986,
      name: 'Research and experimental development on social sciences and humanities',
      code: '72.2',
      selectable: false,
      parentId: 977,
    },
    {
      id: 987,
      name: 'Research and experimental development on social sciences and humanities',
      code: '72.20',
      selectable: true,
      parentId: 986,
    },
    {
      id: 988,
      name: 'Research and development in humanitarian sciences',
      code: '72.20.10',
      selectable: true,
      parentId: 986,
    },
    {
      id: 989,
      name: 'Research and development in social sciences',
      code: '72.20.20',
      selectable: true,
      parentId: 986,
    },
    {
      id: 990,
      name: 'Research and development in theological sciences',
      code: '72.20.30',
      selectable: true,
      parentId: 986,
    },
    {
      id: 991,
      name: 'Advertising and market research',
      code: '73',
      selectable: false,
      parentId: 939,
    },
    {
      id: 992,
      name: 'Advertising',
      code: '73.1',
      selectable: true,
      parentId: 991,
    },
    {
      id: 993,
      name: 'Advertising agencies',
      code: '73.11',
      selectable: true,
      parentId: 991,
    },
    {
      id: 994,
      name: 'Media representation',
      code: '73.12',
      selectable: true,
      parentId: 991,
    },
    {
      id: 995,
      name: 'Market research and public opinion polling',
      code: '73.2',
      selectable: false,
      parentId: 991,
    },
    {
      id: 996,
      name: 'Market research and public opinion polling',
      code: '73.20',
      selectable: true,
      parentId: 995,
    },
    {
      id: 997,
      name: 'Other professional, scientific and technical activities',
      code: '74',
      selectable: false,
      parentId: 939,
    },
    {
      id: 998,
      name: 'Specialised design activities',
      code: '74.1',
      selectable: false,
      parentId: 997,
    },
    {
      id: 999,
      name: 'Specialised design activities',
      code: '74.10',
      selectable: true,
      parentId: 998,
    },
    {
      id: 1000,
      name: 'Photographic activities',
      code: '74.2',
      selectable: false,
      parentId: 997,
    },
    {
      id: 1001,
      name: 'Photographic activities',
      code: '74.20',
      selectable: true,
      parentId: 1000,
    },
    {
      id: 1002,
      name: 'Translation and interpretation activities',
      code: '74.3',
      selectable: false,
      parentId: 997,
    },
    {
      id: 1003,
      name: 'Translation and interpretation activities',
      code: '74.30',
      selectable: true,
      parentId: 1002,
    },
    {
      id: 1004,
      name: 'Other professional, scientific and technical activities n.e.c.',
      code: '74.9',
      selectable: false,
      parentId: 997,
    },
    {
      id: 1005,
      name: 'Other professional, scientific and technical activities n.e.c.',
      code: '74.90',
      selectable: true,
      parentId: 1004,
    },
    {
      id: 1006,
      name: 'Veterinary activities',
      code: '75',
      selectable: false,
      parentId: 939,
    },
    {
      id: 1007,
      name: 'Veterinary activities',
      code: '75.0',
      selectable: false,
      parentId: 1006,
    },
    {
      id: 1008,
      name: 'Veterinary activities',
      code: '75.00',
      selectable: true,
      parentId: 1007,
    },
    {
      id: 1009,
      name: 'Inpatient veterinary care',
      code: '75.00.10',
      selectable: true,
      parentId: 1007,
    },
    {
      id: 1010,
      name: 'Outpatient veterinary care',
      code: '75.00.20',
      selectable: true,
      parentId: 1007,
    },
    {
      id: 1011,
      name: 'Veterinary diagnostic activities',
      code: '75.00.30',
      selectable: true,
      parentId: 1007,
    },
    {
      id: 1012,
      name: 'Veterinary laboratory activities',
      code: '75.00.40',
      selectable: true,
      parentId: 1007,
    },
    {
      id: 1013,
      name: 'ADMINISTRATIVE AND SUPPORT SERVICE ACTIVITIES',
      code: 'N',
      selectable: false,
    },
    {
      id: 1014,
      name: 'Rental and leasing activities',
      code: '77',
      selectable: false,
      parentId: 1013,
    },
    {
      id: 1015,
      name: 'Renting and leasing of motor vehicles',
      code: '77.1',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1016,
      name: 'Renting and leasing of cars and light motor vehicles',
      code: '77.11',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1017,
      name: 'Renting and leasing of trucks',
      code: '77.12',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1018,
      name: 'Renting of trucks',
      code: '77.12.10',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1019,
      name: 'Renting of tourist buses',
      code: '77.12.20',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1020,
      name: 'Renting and leasing of personal and household goods',
      code: '77.2',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1021,
      name: 'Renting and leasing of recreational and sports goods',
      code: '77.21',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1022,
      name: 'Renting of boats for recreational purposes',
      code: '77.21.10',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1023,
      name: 'Renting of riding horses',
      code: '77.21.20',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1024,
      name: 'Renting of bicycles',
      code: '77.21.30',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1025,
      name: 'Renting of sports equipment',
      code: '77.21.40',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1026,
      name: 'Other renting of tourism equipment',
      code: '77.21.50',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1027,
      name: 'Renting of video tapes and disks',
      code: '77.22',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1028,
      name: 'Renting and leasing of other personal and household goods',
      code: '77.29',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1029,
      name: 'Renting of textiles, wearing apparel and footwear',
      code: '77.29.10',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1030,
      name: 'Renting of furniture and other personal goods',
      code: '77.29.20',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1031,
      name: 'Renting of musical instruments, scenery and costumes',
      code: '77.29.30',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1032,
      name: 'Renting and leasing of other machinery, equipment and tangible goods',
      code: '77.3',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1033,
      name: 'Renting and leasing of agricultural machinery and equipment',
      code: '77.31',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1034,
      name: 'Renting and leasing of construction and civil engineering machinery and equipment',
      code: '77.32',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1035,
      name: 'Renting and leasing of office machinery and equipment (including computers)',
      code: '77.33',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1036,
      name: 'Renting of computers',
      code: '77.33.10',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1037,
      name: 'Renting of photocopiers, printers, and word-processing machines',
      code: '77.33.20',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1038,
      name: 'Renting of cash registers',
      code: '77.33.30',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1039,
      name: 'Renting and leasing of water transport equipment',
      code: '77.34',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1040,
      name: 'Renting and leasing of air transport equipment',
      code: '77.35',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1041,
      name: 'Renting and leasing of other machinery, equipment and tangible goods n.e.c.',
      code: '77.39',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1042,
      name: 'Renting of railway transport facilities',
      code: '77.39.10',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1043,
      name: 'Renting of motorbikes',
      code: '77.39.20',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1044,
      name: 'Renting of camper vans',
      code: '77.39.30',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1045,
      name: 'Renting of containers, mobile platforms and trays',
      code: '77.39.40',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1046,
      name: 'Renting of industrial electrical and non-electrical machinery',
      code: '77.39.50',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1047,
      name: 'Renting of radio and television equipment',
      code: '77.39.60',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1048,
      name: 'Renting of communication equipment',
      code: '77.39.70',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1049,
      name: 'Renting of measurement and control equipment',
      code: '77.39.80',
      selectable: true,
      parentId: 1014,
    },
    {
      id: 1050,
      name: 'Leasing of intellectual property and similar products, except copyrighted works',
      code: '77.4',
      selectable: false,
      parentId: 1014,
    },
    {
      id: 1051,
      name: 'Leasing of intellectual property and similar products, except copyrighted works',
      code: '77.40',
      selectable: true,
      parentId: 1050,
    },
    {
      id: 1052,
      name: 'Employment activities',
      code: '78',
      selectable: false,
      parentId: 1013,
    },
    {
      id: 1053,
      name: 'Activities of employment placement agencies',
      code: '78.1',
      selectable: false,
      parentId: 1052,
    },
    {
      id: 1054,
      name: 'Activities of employment placement agencies',
      code: '78.10',
      selectable: true,
      parentId: 1053,
    },
    {
      id: 1055,
      name: 'Temporary employment agency activities',
      code: '78.2',
      selectable: false,
      parentId: 1052,
    },
    {
      id: 1056,
      name: 'Temporary employment agency activities',
      code: '78.20',
      selectable: true,
      parentId: 1055,
    },
    {
      id: 1057,
      name: 'Other human resources provision',
      code: '78.3',
      selectable: false,
      parentId: 1052,
    },
    {
      id: 1058,
      name: 'Other human resources provision',
      code: '78.30',
      selectable: true,
      parentId: 1057,
    },
    {
      id: 1059,
      name: 'Travel agency, tour operator reservation service and related activities',
      code: '79',
      selectable: false,
      parentId: 1013,
    },
    {
      id: 1060,
      name: 'Travel agency and tour operator activities',
      code: '79.1',
      selectable: false,
      parentId: 1059,
    },
    {
      id: 1061,
      name: 'Travel agency activities',
      code: '79.11',
      selectable: true,
      parentId: 1060,
    },
    {
      id: 1062,
      name: 'Tour operator activities',
      code: '79.12',
      selectable: true,
      parentId: 1060,
    },
    {
      id: 1063,
      name: 'Other reservation service and related activities',
      code: '79.9',
      selectable: false,
      parentId: 1059,
    },
    {
      id: 1064,
      name: 'Other reservation service and related activities',
      code: '79.90',
      selectable: true,
      parentId: 1063,
    },
    {
      id: 1065,
      name: 'Security and investigation activities',
      code: '80',
      selectable: false,
      parentId: 1013,
    },
    {
      id: 1066,
      name: 'Private security activities',
      code: '80.1',
      selectable: false,
      parentId: 1065,
    },
    {
      id: 1067,
      name: 'Private security activities',
      code: '80.10',
      selectable: true,
      parentId: 1066,
    },
    {
      id: 1068,
      name: 'Security systems service activities',
      code: '80.2',
      selectable: false,
      parentId: 1065,
    },
    {
      id: 1069,
      name: 'Security systems service activities',
      code: '80.20',
      selectable: true,
      parentId: 1068,
    },
    {
      id: 1070,
      name: 'Investigation activities',
      code: '80.3',
      selectable: false,
      parentId: 1065,
    },
    {
      id: 1071,
      name: 'Investigation activities',
      code: '80.30',
      selectable: true,
      parentId: 1070,
    },
    {
      id: 1072,
      name: 'Services to buildings and landscape activities',
      code: '81',
      selectable: false,
      parentId: 1013,
    },
    {
      id: 1073,
      name: 'Combined facilities support activities',
      code: '81.1',
      selectable: false,
      parentId: 1072,
    },
    {
      id: 1074,
      name: 'Combined facilities support activities',
      code: '81.10',
      selectable: true,
      parentId: 1073,
    },
    {
      id: 1075,
      name: 'Cleaning activities',
      code: '81.2',
      selectable: false,
      parentId: 1072,
    },
    {
      id: 1076,
      name: 'General cleaning of buildings',
      code: '81.21',
      selectable: true,
      parentId: 1075,
    },
    {
      id: 1077,
      name: 'Other building and industrial cleaning activities',
      code: '81.22',
      selectable: true,
      parentId: 1075,
    },
    {
      id: 1078,
      name: 'Other cleaning activities',
      code: '81.29',
      selectable: true,
      parentId: 1075,
    },
    {
      id: 1079,
      name: 'Landscape service activities',
      code: '81.3',
      selectable: false,
      parentId: 1072,
    },
    {
      id: 1080,
      name: 'Landscape service activities',
      code: '81.30',
      selectable: true,
      parentId: 1079,
    },
    {
      id: 1081,
      name: 'Office administrative, office support and other business support activities',
      code: '82',
      selectable: false,
      parentId: 1013,
    },
    {
      id: 1082,
      name: 'Office administrative and support activities',
      code: '82.1',
      selectable: true,
      parentId: 1081,
    },
    {
      id: 1083,
      name: 'Combined office administrative service activities',
      code: '82.11',
      selectable: true,
      parentId: 1081,
    },
    {
      id: 1084,
      name: 'Photocopying, document preparation and other specialised office support activities',
      code: '82.19',
      selectable: true,
      parentId: 1081,
    },
    {
      id: 1085,
      name: 'Activities of call centres',
      code: '82.2',
      selectable: false,
      parentId: 1081,
    },
    {
      id: 1086,
      name: 'Activities of call centres',
      code: '82.20',
      selectable: true,
      parentId: 1085,
    },
    {
      id: 1087,
      name: 'Organisation of conventions and trade shows',
      code: '82.3',
      selectable: false,
      parentId: 1081,
    },
    {
      id: 1088,
      name: 'Organisation of conventions and trade shows',
      code: '82.30',
      selectable: true,
      parentId: 1087,
    },
    {
      id: 1089,
      name: 'Business support service activities n.e.c.',
      code: '82.9',
      selectable: false,
      parentId: 1081,
    },
    {
      id: 1090,
      name: 'Activities of collection agencies and credit bureaus',
      code: '82.91',
      selectable: true,
      parentId: 1089,
    },
    {
      id: 1091,
      name: 'Packaging activities',
      code: '82.92',
      selectable: true,
      parentId: 1089,
    },
    {
      id: 1092,
      name: 'Other business support service activities n.e.c.',
      code: '82.99',
      selectable: true,
      parentId: 1089,
    },
    {
      id: 1093,
      name: 'PUBLIC ADMINISTRATION AND DEFENCE; COMPULSORY SOCIAL SECURITY',
      code: 'O',
      selectable: false,
    },
    {
      id: 1094,
      name: 'Public administration and defence; compulsory social security',
      code: '84',
      selectable: false,
      parentId: 1093,
    },
    {
      id: 1095,
      name: 'Administration of the State and the economic and social policy of the community',
      code: '84.1',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1096,
      name: 'General public administration activities',
      code: '84.11',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1097,
      name: 'Activities of counties of the Republic of Lithuania',
      code: '84.11.10',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1098,
      name: 'Activities of municipalities of the Republic of Lithuania',
      code: '84.11.20',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1099,
      name: 'Activities of wards of the Republic of Lithuania',
      code: '84.11.30',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1100,
      name: 'Treasury management and control',
      code: '84.11.40',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1101,
      name: 'Budget implementation and management of public funds',
      code: '84.11.50',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1102,
      name: 'Legislative and executive activities of central administrative bodies',
      code: '84.11.60',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1103,
      name: 'Management of customs services and activities thereof',
      code: '84.11.70',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1104,
      name: 'Management of statistical and sociological services at various public administration levels and activities thereof',
      code: '84.11.80',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1105,
      name: 'Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security',
      code: '84.12',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1106,
      name: 'Regulation of and contribution to more efficient operation of businesses',
      code: '84.13',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1107,
      name: 'Provision of services to the community as a whole',
      code: '84.2',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1108,
      name: 'Foreign affairs',
      code: '84.21',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1109,
      name: 'Defence activities',
      code: '84.22',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1110,
      name: 'Justice and judicial activities',
      code: '84.23',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1111,
      name: 'Public order and safety activities',
      code: '84.24',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1112,
      name: 'Fire service activities',
      code: '84.25',
      selectable: true,
      parentId: 1094,
    },
    {
      id: 1113,
      name: 'Compulsory social security activities',
      code: '84.3',
      selectable: false,
      parentId: 1094,
    },
    {
      id: 1114,
      name: 'Compulsory social security activities',
      code: '84.30',
      selectable: true,
      parentId: 1113,
    },
    {
      id: 1115,
      name: 'EDUCATION',
      code: 'P',
      selectable: false,
    },
    {
      id: 1116,
      name: 'Education',
      code: '85',
      selectable: false,
      parentId: 1115,
    },
    {
      id: 1117,
      name: 'Pre-primary education',
      code: '85.1',
      selectable: false,
      parentId: 1116,
    },
    {
      id: 1118,
      name: 'Pre-primary education',
      code: '85.10',
      selectable: false,
      parentId: 1117,
    },
    {
      id: 1119,
      name: 'Preschool education',
      code: '85.10.10',
      selectable: true,
      parentId: 1117,
    },
    {
      id: 1120,
      name: 'Pre-primary education',
      code: '85.10.20',
      selectable: true,
      parentId: 1117,
    },
    {
      id: 1121,
      name: 'Primary education',
      code: '85.2',
      selectable: false,
      parentId: 1116,
    },
    {
      id: 1122,
      name: 'Primary education',
      code: '85.20',
      selectable: true,
      parentId: 1121,
    },
    {
      id: 1123,
      name: 'Secondary education',
      code: '85.3',
      selectable: true,
      parentId: 1116,
    },
    {
      id: 1124,
      name: 'General secondary education',
      code: '85.31',
      selectable: false,
      parentId: 1116,
    },
    {
      id: 1125,
      name: 'General lower secondary education',
      code: '85.31.10',
      selectable: true,
      parentId: 1124,
    },
    {
      id: 1126,
      name: 'General upper secondary education',
      code: '85.31.20',
      selectable: true,
      parentId: 1124,
    },
    {
      id: 1127,
      name: 'Technical and vocational secondary education',
      code: '85.32',
      selectable: true,
      parentId: 1116,
    },
    {
      id: 1128,
      name: 'Higher education',
      code: '85.4',
      selectable: true,
      parentId: 1116,
    },
    {
      id: 1129,
      name: 'Post-secondary non-tertiary education',
      code: '85.41',
      selectable: true,
      parentId: 1116,
    },
    {
      id: 1130,
      name: 'Tertiary education',
      code: '85.42',
      selectable: false,
      parentId: 1116,
    },
    {
      id: 1131,
      name: 'Higher non-university education',
      code: '85.42.10',
      selectable: true,
      parentId: 1130,
    },
    {
      id: 1132,
      name: 'Higher university education',
      code: '85.42.20',
      selectable: true,
      parentId: 1130,
    },
    {
      id: 1133,
      name: 'Doctoral studies',
      code: '85.42.30',
      selectable: true,
      parentId: 1130,
    },
    {
      id: 1134,
      name: 'Other education',
      code: '85.5',
      selectable: false,
      parentId: 1116,
    },
    {
      id: 1135,
      name: 'Sports and recreation education',
      code: '85.51',
      selectable: true,
      parentId: 1134,
    },
    {
      id: 1136,
      name: 'Cultural education',
      code: '85.52',
      selectable: true,
      parentId: 1134,
    },
    {
      id: 1137,
      name: 'Driving school activities',
      code: '85.53',
      selectable: true,
      parentId: 1134,
    },
    {
      id: 1138,
      name: 'Other education n.e.c.',
      code: '85.59',
      selectable: true,
      parentId: 1134,
    },
    {
      id: 1139,
      name: 'Educational support activities',
      code: '85.6',
      selectable: false,
      parentId: 1116,
    },
    {
      id: 1140,
      name: 'Educational support activities',
      code: '85.60',
      selectable: true,
      parentId: 1139,
    },
    {
      id: 1141,
      name: 'HUMAN HEALTH AND SOCIAL WORK ACTIVITIES',
      code: 'Q',
      selectable: false,
    },
    {
      id: 1142,
      name: 'Human health activities',
      code: '86',
      selectable: false,
      parentId: 1141,
    },
    {
      id: 1143,
      name: 'Hospital activities',
      code: '86.1',
      selectable: false,
      parentId: 1142,
    },
    {
      id: 1144,
      name: 'Hospital activities',
      code: '86.10',
      selectable: true,
      parentId: 1143,
    },
    {
      id: 1145,
      name: 'Activities of general hospitals',
      code: '86.10.10',
      selectable: true,
      parentId: 1143,
    },
    {
      id: 1146,
      name: 'Activities of specialised hospitals',
      code: '86.10.20',
      selectable: true,
      parentId: 1143,
    },
    {
      id: 1147,
      name: 'Activities of rehabilitation hospitals',
      code: '86.10.30',
      selectable: true,
      parentId: 1143,
    },
    {
      id: 1148,
      name: 'Activities of hospitals for nursing',
      code: '86.10.40',
      selectable: true,
      parentId: 1143,
    },
    {
      id: 1149,
      name: 'Medical and dental practice activities',
      code: '86.2',
      selectable: false,
      parentId: 1142,
    },
    {
      id: 1150,
      name: 'General medical practice activities',
      code: '86.21',
      selectable: true,
      parentId: 1149,
    },
    {
      id: 1151,
      name: 'Specialist medical practice activities',
      code: '86.22',
      selectable: true,
      parentId: 1149,
    },
    {
      id: 1152,
      name: 'Dental practice activities',
      code: '86.23',
      selectable: true,
      parentId: 1149,
    },
    {
      id: 1153,
      name: 'Other human health activities',
      code: '86.9',
      selectable: false,
      parentId: 1142,
    },
    {
      id: 1154,
      name: 'Other human health activities',
      code: '86.90',
      selectable: true,
      parentId: 1153,
    },
    {
      id: 1155,
      name: 'Services of paramedical staff to patients outside hospitals',
      code: '86.90.10',
      selectable: true,
      parentId: 1153,
    },
    {
      id: 1156,
      name: 'Activities of blood transfusion blood transfusion establishments and blood banks',
      code: '86.90.20',
      selectable: true,
      parentId: 1153,
    },
    {
      id: 1157,
      name: 'Activities of medical laboratories',
      code: '86.90.30',
      selectable: true,
      parentId: 1153,
    },
    {
      id: 1158,
      name: 'Ambulance services',
      code: '86.90.40',
      selectable: true,
      parentId: 1153,
    },
    {
      id: 1159,
      name: 'Residential care activities',
      code: '87',
      selectable: false,
      parentId: 1141,
    },
    {
      id: 1160,
      name: 'Residential nursing care activities',
      code: '87.1',
      selectable: false,
      parentId: 1159,
    },
    {
      id: 1161,
      name: 'Residential nursing care activities',
      code: '87.10',
      selectable: true,
      parentId: 1160,
    },
    {
      id: 1162,
      name: 'Residential care activities for mental retardation, mental health and substance abuse',
      code: '87.2',
      selectable: false,
      parentId: 1159,
    },
    {
      id: 1163,
      name: 'Residential care activities for mental retardation, mental health and substance abuse',
      code: '87.20',
      selectable: true,
      parentId: 1162,
    },
    {
      id: 1164,
      name: 'Residential care activities for the elderly and disabled',
      code: '87.3',
      selectable: false,
      parentId: 1159,
    },
    {
      id: 1165,
      name: 'Residential care activities for the elderly and disabled',
      code: '87.30',
      selectable: true,
      parentId: 1164,
    },
    {
      id: 1166,
      name: 'Other residential care activities',
      code: '87.9',
      selectable: false,
      parentId: 1159,
    },
    {
      id: 1167,
      name: 'Other residential care activities',
      code: '87.90',
      selectable: true,
      parentId: 1166,
    },
    {
      id: 1168,
      name: 'Social work activities without accommodation',
      code: '88',
      selectable: false,
      parentId: 1141,
    },
    {
      id: 1169,
      name: 'Social work activities without accommodation for the elderly and disabled',
      code: '88.1',
      selectable: false,
      parentId: 1168,
    },
    {
      id: 1170,
      name: 'Social work activities without accommodation for the elderly and disabled',
      code: '88.10',
      selectable: true,
      parentId: 1169,
    },
    {
      id: 1171,
      name: 'Other social work activities without accommodation',
      code: '88.9',
      selectable: false,
      parentId: 1168,
    },
    {
      id: 1172,
      name: 'Child day-care activities',
      code: '88.91',
      selectable: true,
      parentId: 1171,
    },
    {
      id: 1173,
      name: 'Other social work activities without accommodation n.e.c.',
      code: '88.99',
      selectable: true,
      parentId: 1171,
    },
    {
      id: 1174,
      name: 'ARTS, ENTERTAINMENT AND RECREATION',
      code: 'R',
      selectable: false,
    },
    {
      id: 1175,
      name: 'Creative, arts and entertainment activities',
      code: '90',
      selectable: false,
      parentId: 1174,
    },
    {
      id: 1176,
      name: 'Creative, arts and entertainment activities',
      code: '90.0',
      selectable: true,
      parentId: 1175,
    },
    {
      id: 1177,
      name: 'Performing arts',
      code: '90.01',
      selectable: true,
      parentId: 1175,
    },
    {
      id: 1178,
      name: 'Support activities to performing arts',
      code: '90.02',
      selectable: true,
      parentId: 1175,
    },
    {
      id: 1179,
      name: 'Artistic creation',
      code: '90.03',
      selectable: true,
      parentId: 1175,
    },
    {
      id: 1180,
      name: 'Operation of arts facilities',
      code: '90.04',
      selectable: true,
      parentId: 1175,
    },
    {
      id: 1181,
      name: 'Libraries, archives, museums and other cultural activities',
      code: '91',
      selectable: false,
      parentId: 1174,
    },
    {
      id: 1182,
      name: 'Libraries, archives, museums and other cultural activities',
      code: '91.0',
      selectable: true,
      parentId: 1181,
    },
    {
      id: 1183,
      name: 'Library and archives activities',
      code: '91.01',
      selectable: true,
      parentId: 1181,
    },
    {
      id: 1184,
      name: 'Museums activities',
      code: '91.02',
      selectable: true,
      parentId: 1181,
    },
    {
      id: 1185,
      name: 'Operation of historical sites and buildings and similar visitor attractions',
      code: '91.03',
      selectable: true,
      parentId: 1181,
    },
    {
      id: 1186,
      name: 'Botanical and zoological gardens and nature reserves activities',
      code: '91.04',
      selectable: true,
      parentId: 1181,
    },
    {
      id: 1187,
      name: 'Activities of zoological gardens',
      code: '91.04.10',
      selectable: true,
      parentId: 1181,
    },
    {
      id: 1188,
      name: 'Activities of botanical gardens',
      code: '91.04.20',
      selectable: true,
      parentId: 1181,
    },
    {
      id: 1189,
      name: 'Activities of nature reserves and conservation areas',
      code: '91.04.30',
      selectable: true,
      parentId: 1181,
    },
    {
      id: 1190,
      name: 'Gambling and betting activities',
      code: '92',
      selectable: false,
      parentId: 1174,
    },
    {
      id: 1191,
      name: 'Gambling and betting activities',
      code: '92.0',
      selectable: false,
      parentId: 1190,
    },
    {
      id: 1192,
      name: 'Gambling and betting activities',
      code: '92.00',
      selectable: true,
      parentId: 1191,
    },
    {
      id: 1193,
      name: 'Lottery activities',
      code: '92.00.10',
      selectable: true,
      parentId: 1191,
    },
    {
      id: 1194,
      name: 'Organisation of gambling games and betting',
      code: '92.00.20',
      selectable: true,
      parentId: 1191,
    },
    {
      id: 1195,
      name: 'Organisation of other games of chance',
      code: '92.00.30',
      selectable: true,
      parentId: 1191,
    },
    {
      id: 1196,
      name: 'Sports activities and amusement and recreation activities',
      code: '93',
      selectable: false,
      parentId: 1174,
    },
    {
      id: 1197,
      name: 'Sports activities',
      code: '93.1',
      selectable: false,
      parentId: 1196,
    },
    {
      id: 1198,
      name: 'Operation of sports facilities',
      code: '93.11',
      selectable: true,
      parentId: 1197,
    },
    {
      id: 1199,
      name: 'Activities of sport clubs',
      code: '93.12',
      selectable: true,
      parentId: 1197,
    },
    {
      id: 1200,
      name: 'Fitness facilities',
      code: '93.13',
      selectable: true,
      parentId: 1197,
    },
    {
      id: 1201,
      name: 'Other sports activities',
      code: '93.19',
      selectable: true,
      parentId: 1197,
    },
    {
      id: 1202,
      name: 'Sports or amateur hunting and related activities',
      code: '93.19.10',
      selectable: true,
      parentId: 1197,
    },
    {
      id: 1203,
      name: 'Sports or amateur fishing and related activities',
      code: '93.19.20',
      selectable: true,
      parentId: 1197,
    },
    {
      id: 1204,
      name: 'Amusement and recreation activities',
      code: '93.2',
      selectable: false,
      parentId: 1196,
    },
    {
      id: 1205,
      name: 'Activities of amusement parks and theme parks',
      code: '93.21',
      selectable: true,
      parentId: 1204,
    },
    {
      id: 1206,
      name: 'Other amusement and recreation activities',
      code: '93.29',
      selectable: true,
      parentId: 1204,
    },
    {
      id: 1207,
      name: 'OTHER SERVICE ACTIVITIES',
      code: 'S',
      selectable: false,
    },
    {
      id: 1208,
      name: 'Activities of membership organisations',
      code: '94',
      selectable: false,
      parentId: 1207,
    },
    {
      id: 1209,
      name: 'Activities of business, employers and professional membership organisations',
      code: '94.1',
      selectable: false,
      parentId: 1208,
    },
    {
      id: 1210,
      name: 'Activities of business and employers membership organisations',
      code: '94.11',
      selectable: true,
      parentId: 1209,
    },
    {
      id: 1211,
      name: 'Activities of professional membership organisations',
      code: '94.12',
      selectable: true,
      parentId: 1209,
    },
    {
      id: 1212,
      name: 'Activities of trade unions',
      code: '94.2',
      selectable: false,
      parentId: 1208,
    },
    {
      id: 1213,
      name: 'Activities of trade unions',
      code: '94.20',
      selectable: true,
      parentId: 1212,
    },
    {
      id: 1214,
      name: 'Activities of other membership organisations',
      code: '94.9',
      selectable: false,
      parentId: 1208,
    },
    {
      id: 1215,
      name: 'Activities of religious organisations',
      code: '94.91',
      selectable: true,
      parentId: 1214,
    },
    {
      id: 1216,
      name: 'Activities of religious communities',
      code: '94.91.10',
      selectable: true,
      parentId: 1214,
    },
    {
      id: 1217,
      name: 'Activities of monasteries and convents',
      code: '94.91.20',
      selectable: true,
      parentId: 1214,
    },
    {
      id: 1218,
      name: 'Activities of political organisations',
      code: '94.92',
      selectable: true,
      parentId: 1214,
    },
    {
      id: 1219,
      name: 'Activities of other membership organisations n.e.c.',
      code: '94.99',
      selectable: true,
      parentId: 1214,
    },
    {
      id: 1220,
      name: 'Repair of computers and personal and household goods',
      code: '95',
      selectable: false,
      parentId: 1207,
    },
    {
      id: 1221,
      name: 'Repair of computers and communication equipment',
      code: '95.1',
      selectable: false,
      parentId: 1220,
    },
    {
      id: 1222,
      name: 'Repair of computers and peripheral equipment',
      code: '95.11',
      selectable: true,
      parentId: 1221,
    },
    {
      id: 1223,
      name: 'Repair of communication equipment',
      code: '95.12',
      selectable: true,
      parentId: 1221,
    },
    {
      id: 1224,
      name: 'Technical support of communication systems',
      code: '95.12.10',
      selectable: true,
      parentId: 1221,
    },
    {
      id: 1225,
      name: 'Repair of personal and household goods',
      code: '95.2',
      selectable: false,
      parentId: 1220,
    },
    {
      id: 1226,
      name: 'Repair of consumer electronics',
      code: '95.21',
      selectable: true,
      parentId: 1225,
    },
    {
      id: 1227,
      name: 'Repair of household appliances and home and garden equipment',
      code: '95.22',
      selectable: true,
      parentId: 1225,
    },
    {
      id: 1228,
      name: 'Repair of footwear and leather goods',
      code: '95.23',
      selectable: true,
      parentId: 1225,
    },
    {
      id: 1229,
      name: 'Repair of furniture and home furnishings',
      code: '95.24',
      selectable: true,
      parentId: 1225,
    },
    {
      id: 1230,
      name: 'Repair of watches, clocks and jewellery',
      code: '95.25',
      selectable: true,
      parentId: 1225,
    },
    {
      id: 1231,
      name: 'Repair of other personal and household goods',
      code: '95.29',
      selectable: true,
      parentId: 1225,
    },
    {
      id: 1232,
      name: 'Other personal service activities',
      code: '96',
      selectable: false,
      parentId: 1207,
    },
    {
      id: 1233,
      name: 'Other personal service activities',
      code: '96.0',
      selectable: false,
      parentId: 1232,
    },
    {
      id: 1234,
      name: 'Washing and (dry-)cleaning of textile and fur products',
      code: '96.01',
      selectable: true,
      parentId: 1233,
    },
    {
      id: 1235,
      name: 'Hairdressing and other beauty treatment',
      code: '96.02',
      selectable: true,
      parentId: 1233,
    },
    {
      id: 1236,
      name: 'Funeral and related activities',
      code: '96.03',
      selectable: true,
      parentId: 1233,
    },
    {
      id: 1237,
      name: 'Physical well-being activities',
      code: '96.04',
      selectable: true,
      parentId: 1233,
    },
    {
      id: 1238,
      name: 'Other personal service activities n.e.c.',
      code: '96.09',
      selectable: true,
      parentId: 1233,
    },
    {
      id: 1239,
      name: 'ACTIVITIES OF HOUSEHOLDS AS EMPLOYERS; UNDIFFERENTIATED GOODS- AND SERVICES-PRODUCING ACTIVITIES OF HOUSEHOLDS FOR OWN USE',
      code: 'T',
      selectable: false,
    },
    {
      id: 1240,
      name: 'Activities of households as employers of domestic personnel',
      code: '97',
      selectable: false,
      parentId: 1239,
    },
    {
      id: 1241,
      name: 'Activities of households as employers of domestic personnel',
      code: '97.0',
      selectable: false,
      parentId: 1240,
    },
    {
      id: 1242,
      name: 'Activities of households as employers of domestic personnel',
      code: '97.00',
      selectable: true,
      parentId: 1241,
    },
    {
      id: 1243,
      name: 'Undifferentiated goods- and services-producing activities of private households for own use',
      code: '98',
      selectable: false,
      parentId: 1239,
    },
    {
      id: 1244,
      name: 'Undifferentiated goods-producing activities of private households for own use',
      code: '98.1',
      selectable: false,
      parentId: 1243,
    },
    {
      id: 1245,
      name: 'Undifferentiated goods-producing activities of private households for own use',
      code: '98.10',
      selectable: true,
      parentId: 1244,
    },
    {
      id: 1246,
      name: 'Undifferentiated service-producing activities of private households for own use',
      code: '98.2',
      selectable: false,
      parentId: 1245,
    },
    {
      id: 1247,
      name: 'Undifferentiated service-producing activities of private households for own use',
      code: '98.20',
      selectable: true,
      parentId: 1246,
    },
    {
      id: 1248,
      name: 'ACTIVITIES OF EXTRATERRITORIAL ORGANISATIONS AND BODIES',
      code: 'U',
      selectable: false,
    },
    {
      id: 1249,
      name: 'Activities of extraterritorial organisations and bodies',
      code: '99',
      selectable: false,
      parentId: 1248,
    },
    {
      id: 1250,
      name: 'Activities of extraterritorial organisations and bodies',
      code: '99.0',
      selectable: false,
      parentId: 1249,
    },
    {
      id: 1251,
      name: 'Activities of extraterritorial organisations and bodies',
      code: '99.00',
      selectable: true,
      parentId: 1250,
    },
  ],
}
