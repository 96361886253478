import { Typography, styled } from '@mui/material'

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
  fontSize: 18,
  textAlign: 'start',
  marginBottom: 0,
  lineHeight: 1.5,
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(0.5),
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 1.5,
  },
}))
