import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx'

export const scrollbarSx: SystemStyleObject = {
  overflowY: 'overlay',
  '&::-webkit-scrollbar': {
    width: 6,
    height: 6,
    background: 'transparent',
  },
  '&::-webkit-scrollbar-track-piece': {
    marginTop: '20px',
    marginBottom: '20px',
  },
  '&::-webkit-scrollbar-thumb': {
    width: 6,
    height: 6,
    borderRadius: '100px',
    background: '#002433',
    visibility: 'hidden',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    visibility: 'visible',
    background: '#002433',
    width: 6,
    height: 6,
  },
}
