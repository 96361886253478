import { styled } from '@mui/material'

import { grey } from '@shared/constants'

export const ProgressBarWrapper = styled('div')(({ theme }) => ({
  width: 'calc(100% + 48px)',
  position: 'relative',
  left: -24,
  background: grey[50],
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: '8px',
  margin: '0 auto',
  [theme.breakpoints.up('sm')]: {
    width: '90%',
    left: 0,
  },
  [theme.breakpoints.up('md')]: {
    borderRadius: 4,
    width: '100%',
  },
}))
