import { styled } from '@mui/material'

export const StyledPercentageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    gap: '16px',
  },
}))
