export const queryKeys = {
  walletList: 'walletList',
  virtualAccounts: 'virtualAccounts',
  exchangeRate: 'exchangeRate',
  exchangeRateList: 'exchangeRateList',
  exchangeCryptoBuy: 'exchangeCryptoBuy',
  exchangeCryptoSell: 'exchangeCryptoSell',
  topUpInfo: 'topUpInfo',
  exchangeHistory: 'exchangeHistory',
  cryptoTransactions: 'cryptoTransactions',
  withdrawTransactions: 'withdrawTransactions',
  applications: 'applications',
  exchangeFee: 'exchangeFee',
  withdrawTransactionDetails: 'withdrawTransactionDetails',
  cryptoTransactionDetails: 'cryptoTransactionDetails',
} as const
