import { styled } from '@mui/material'

import { AnimationWrapper } from './animation-wrapper'

export const LocalLoaderAnimationWrapper = styled(AnimationWrapper)(
  ({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.6)',
    zIndex: 2,
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-50%, -50%) ',
    },
  })
)
