import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { IVirtualAccount } from '../types'

interface IWalletState {
  virtualAccount: IVirtualAccount | null
  getAccountName: () => string
  setVirtualAccount: (virtualAccount: IVirtualAccount | null) => void
}

export const useVirtualAccountStore = create<IWalletState>()(
  persist(
    devtools(
      (set, get) => ({
        virtualAccount: null,
        getAccountName: () => {
          const account = get().virtualAccount

          if (account?.corporateName) {
            return account.corporateName
          }

          return `${account?.firstName} ${account?.lastName}`
        },
        setVirtualAccount: (virtualAccount) => set({ virtualAccount }),
      }),
      {
        name: 'virtualAccountStore',
      }
    ),
    {
      name: 'virtualAccountStore',
    }
  )
)
