import { Typography, styled } from '@mui/material'

export const StyledTypography = styled(Typography)(({ theme }) => ({
  maxWidth: 700,
  whiteSpace: 'pre-line',
  textAlign: 'center',
  marginBottom: 56,
  [theme.breakpoints.up('md')]: {
    marginBottom: 72,
  },

  '& p': {
    fontSize: 18,
    margin: 0,
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
}))
