export const deepBlue = {
  50: '#E5F4FF',
  100: '#CCEAFF',
  200: '#99D5FF',
  300: '#66BFFF',
  400: '#33AAFF',
  500: '#0065AE',
  600: '#0077CC',
  700: '#005999',
  800: '#003C66',
  900: '#001E33',
  950: '#000F1A',
}
