import { InfoOutlined } from '@mui/icons-material'
import { Box, Collapse, Grid, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  IVirtualAccount,
  useVirtualAccountList,
  useGetApplications,
} from '@entities/virtual-account'

import { TAccountType } from '@shared/types'

import { ReactComponent as BusinessIcon } from '../../assets/business.svg'
import { ReactComponent as PersonalIcon } from '../../assets/personal.svg'
import { cardsContainerPaddingX } from '../constants'

import { ChooseButton } from './choose-button'

interface IProps {
  accountType: TAccountType | null
  setAccountType: (value: TAccountType | null) => void
  businessDir: 'auth' | 'dir' | null
  setBusinessDir: (value: 'auth' | 'dir' | null) => void
}

export function ChooseAccount({
  accountType,
  setAccountType,
  businessDir,
  setBusinessDir,
}: IProps) {
  const { t } = useTranslation('features')
  const virtualAccounts = useVirtualAccountList().data

  const [isEnabledGetApplications, setIsEnabledGetApplications] =
    useState<boolean>(false)

  const getAccountName = (type: IVirtualAccount['type']) => {
    const currentAccount = virtualAccounts?.find(
      (account) => account.type === type
    )

    if (!currentAccount) {
      return
    }

    const name =
      type === 'corporate'
        ? currentAccount.corporateName
        : `${currentAccount.firstName} ${currentAccount.lastName}`

    return name
  }

  const individualAccountName = useMemo(
    () => getAccountName('individual'),
    [virtualAccounts]
  )

  const corporateAccountName = useMemo(
    () => getAccountName('corporate'),
    [virtualAccounts]
  )

  useEffect(() => {
    if (!individualAccountName || !corporateAccountName) {
      setIsEnabledGetApplications(true)
    }
  }, [individualAccountName, corporateAccountName])

  useEffect(() => {
    setBusinessDir(null)
  }, [accountType])

  const { data, isLoading } = useGetApplications({
    status: 'received',
    enabled: isEnabledGetApplications,
  })

  const isInReviewIndividualButton = useMemo(
    () =>
      data &&
      data.list.filter(
        (application) => application.accountType === 'individual'
      ).length > 0,

    [data]
  )

  const isInReviewCorporateButton = useMemo(
    () =>
      data &&
      data.list.filter((application) => application.accountType === 'corporate')
        .length > 0,
    [data]
  )

  const checkIsLoading = (accountName?: string) => {
    return accountName === undefined ? isLoading : false
  }

  return (
    <Box py={2.5}>
      <Typography variant="body3" mb={2}>
        {t('create wallet.choose account.title')}
      </Typography>

      <Box px={cardsContainerPaddingX}>
        <Grid
          container
          spacing={2}
          justifyContent={{ xs: 'space-between', md: 'center' }}
        >
          <Grid item xs={6}>
            <ChooseButton
              icon={<PersonalIcon />}
              text={t('create wallet.choose account.personal')}
              active={accountType === 'individual'}
              onClick={() => setAccountType('individual')}
              accountName={individualAccountName}
              inReview={isInReviewIndividualButton}
              loading={checkIsLoading(individualAccountName)}
            />
          </Grid>

          <Grid item xs={6}>
            <ChooseButton
              icon={<BusinessIcon />}
              text={t('create wallet.choose account.business')}
              active={accountType === 'corporate'}
              onClick={() => setAccountType('corporate')}
              accountName={corporateAccountName}
              inReview={isInReviewCorporateButton}
              loading={checkIsLoading(corporateAccountName)}
            />
          </Grid>
        </Grid>
      </Box>

      <Collapse in={accountType === 'corporate'}>
        <Box px={cardsContainerPaddingX} pt={2}>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12} md={6}>
              <ChooseButton
                icon={<InfoOutlined sx={{ width: 16, height: 16 }} />}
                text={t('create wallet.choose account.authorized person')}
                active={businessDir === 'auth'}
                disabled
                onClick={() => setBusinessDir('auth')}
                selectType={'accountDir'}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ChooseButton
                icon={<InfoOutlined sx={{ width: 16, height: 16 }} />}
                text={t('create wallet.choose account.authorized director')}
                selectType={'accountDir'}
                active={businessDir === 'dir'}
                onClick={() => setBusinessDir('dir')}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  )
}
