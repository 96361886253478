import { styled } from '@mui/material'

export const StyledAbsoluteMobileWrapper = styled('div')(({ theme }) => ({
  width: '100vw',
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)',
  [theme.breakpoints.up('md')]: {
    width: 'initial',
    position: 'initial',
    transform: 'initial',
  },
}))
