import { SwapVert } from '@mui/icons-material'
import { Skeleton, useTheme } from '@mui/material'
import { useMemo, useState } from 'react'

import {
  IVirtualAccount,
  useVirtualAccountList,
  useVirtualAccountStore,
} from '@entities/virtual-account'

import { AccountSwitcherStyledButton } from './styled'

export function AccountSwitcherButton() {
  const theme = useTheme()
  const [rotate, setRotate] = useState(true)
  const { data, isLoading } = useVirtualAccountList()
  const virtualAccount = useVirtualAccountStore((state) => state.virtualAccount)
  const setVirtualAccount = useVirtualAccountStore((s) => s.setVirtualAccount)

  const switchAccountHandler = () => {
    if (data && virtualAccount) {
      const account = data.find(
        (element) => element.id !== virtualAccount.id
      ) as IVirtualAccount

      setVirtualAccount(account)

      setRotate((state) => !state)
    }
  }

  const isDisabled = useMemo(() => !(data && data.length > 1), [data])

  if (isLoading) {
    return <Skeleton sx={{ width: 32, height: 32 }} />
  }

  return (
    <AccountSwitcherStyledButton
      disabled={isDisabled}
      onClick={switchAccountHandler}
    >
      <SwapVert
        sx={{
          transform: `rotate(${rotate ? 0 : 180}deg)`,
          transition: theme.transitions.create(['transform']),
        }}
      />
    </AccountSwitcherStyledButton>
  )
}
