import { Grid, styled } from '@mui/material'

export const StyledGridRow = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),

  '&:last-child': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(4.5),
    paddingRight: theme.spacing(4.5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))
