import { Popper, Stack, SxProps, TypographyOwnProps } from '@mui/material'
import { useState, useRef, ReactNode } from 'react'

import { StyledTypography } from './styled'

interface IHiddenPartComponent {
  value?: string | ReactNode | null
  hiddenValue?: string | null
  isActive?: boolean
  variant?: TypographyOwnProps['variant']
  sx?: SxProps
  overflow?: TypographyOwnProps['overflow']
}

export const HiddenPartComponent = ({
  value,
  hiddenValue,
  isActive,
  variant = 'body9',
  overflow,
  sx,
}: IHiddenPartComponent) => {
  const anchorRef = useRef<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)

  const handleMouseOver = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  return (
    <Stack
      direction={'row'}
      alignItems={'fex-end'}
      sx={{ width: { xs: '70%', md: '78%' }, ...sx }}
    >
      {value && (
        <StyledTypography
          overflow={overflow}
          isActive={isActive}
          variant={variant}
          ref={anchorRef}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseOver}
        >
          {value}
        </StyledTypography>
      )}

      {hiddenValue && (
        <Popper open={open} anchorEl={anchorRef.current} sx={{ zIndex: 10 }}>
          {hiddenValue}
        </Popper>
      )}
    </Stack>
  )
}
