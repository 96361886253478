import { Button, styled } from '@mui/material'

export const NotificationBoxButton = styled(Button)<{ target?: '_blank' }>(
  ({ theme }) => ({
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.common.white,
    padding: '0 8px',
    fontWeight: 400,
    marginBottom: -3,
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
      lineHeight: '16px',
      padding: '2px 8px 0px',
      position: 'absolute',
      bottom: 5,
      right: 40,
      marginBottom: 0,
    },
    [theme.breakpoints.down(420)]: {
      marginBottom: -2,
      fontSize: 12,
      lineHeight: '16px',
    },
    [theme.breakpoints.down(375)]: {
      display: 'none',
    },
    '&:hover': {
      borderColor: theme.palette.common.white,
      backgroundColor: theme.palette.error.dark,
    },
  })
)
