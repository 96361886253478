import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'

interface IProps {
  selectType?: 'accountDir' | 'coin'
  active?: boolean
}

export const StyledChooseButton = styled(LoadingButton, {
  shouldForwardProp: (prop) =>
    prop !== 'active' && prop !== 'selectType' && prop !== 'accountCreated',
})<IProps>(({ theme, selectType, disabled, active }) => ({
  width: '100%',
  height: '100%',
  padding: '8px',

  border: `1px solid ${theme.palette.primary.light}`,
  background: theme.palette.background.paper,
  borderRadius: 10,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: 6,
  columnGap: 8,

  position: 'relative',
  transition: theme.transitions.create(['padding']),

  [theme.breakpoints.up('md')]: {
    minHeight: 110,
    padding: '16px',
    rowGap: 8,
  },

  ...(selectType === 'accountDir' && {
    padding: 16,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textAlign: 'left',
    columnGap: 4,
    minHeight: '100% !important',
    [theme.breakpoints.down('md')]: {
      padding: 12,
    },
  }),

  ...(selectType === 'coin' && {
    padding: '20px 12px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: 0,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  }),

  ...(active && {
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.info.light,
    },
  }),

  ...(disabled && {
    opacity: 0.5,
  }),
}))
