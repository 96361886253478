import { api } from '@shared/http-instance'

import { IVirtualAccount } from '../model'

interface IResponse {
  currPage: number
  perPage: number
  totalPages: number
  list: IVirtualAccount[]
}

export async function fetchVirtualAccounts() {
  const url = '/accounts'

  const response = await api.get<IResponse>(url)

  const virtualAccounts = response.data.list

  return virtualAccounts.map((account) => ({
    ...account,
    balance: Math.abs(account.balance / 100),
  }))
}
