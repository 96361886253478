import { Button, styled } from '@mui/material'

export const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Inter',
  backgroundColor: '#FFF4F2',
  borderRadius: 6,
  border: `1px solid #FF7033`,
  color: '#FF7033',
  lineHeight: '16px',
  fontSize: 12,
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),

  '&.MuiButtonBase-root': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },

  '&:hover': {
    borderColor: '#e04e0f',
    backgroundColor: '#ffe1dd',
  },

  '&:active': {
    boxShadow: '0 0 0 2px #FF7033',
  },
}))
