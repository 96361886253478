import { Typography } from '@mui/material'
import { memo, forwardRef, useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { useExchangeFees } from '@features/exchange'

import { useVirtualAccountStore } from '@entities/virtual-account'

import { minTrxAmount } from '@shared/constants'
import {
  WrapperForSecondPartWalletFormPage,
  StyledSecondWarningBlock,
} from '@shared/ui'

interface IProps {
  isSell: boolean
}

export const ImportantInfoPart = memo(
  forwardRef<HTMLDivElement, IProps>(function ImportantInfoPart(
    { isSell },
    ref
  ) {
    const virtualAccount = useVirtualAccountStore((s) => s.virtualAccount)
    const { isLoading, data } = useExchangeFees()

    const { t } = useTranslation('pages')

    const currentPlan = useMemo(() => {
      if (!data || isLoading) {
        return []
      }

      const planTypeElement = data[virtualAccount?.type || 'individual']

      return planTypeElement?.plan || []
    }, [data, isLoading, virtualAccount])

    const valuesForTranslate = {
      minSum: currentPlan[0]?.min,
      maxSum: currentPlan[currentPlan.length - 1]?.max
        ? `${currentPlan[currentPlan.length - 1]?.max} EUR`
        : t('exchange.total-message'),
    }

    return (
      <WrapperForSecondPartWalletFormPage ref={ref}>
        <Trans
          i18nKey={'exchange.alert'}
          ns={'pages'}
          values={valuesForTranslate}
          components={{
            p: <Typography variant={'body2'} component={'p'} mb={2} />,
            strong: <Typography variant={'body1'} component={'span'} />,
            nowrap: <span style={{ whiteSpace: 'wrap' }} />,
          }}
        />

        {isSell && (
          <StyledSecondWarningBlock>
            <Trans
              i18nKey={`exchange.second-attention`}
              ns={'pages'}
              values={{ amount: minTrxAmount }}
              components={{
                p: <Typography variant={'body2'} component={'p'} mb={1} />,
                strong: (
                  <Typography
                    variant={'body1'}
                    component={'span'}
                    display={'inline'}
                  />
                ),
              }}
            />
          </StyledSecondWarningBlock>
        )}
      </WrapperForSecondPartWalletFormPage>
    )
  })
)
