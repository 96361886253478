import { useTranslation } from 'react-i18next'

import { useAuth } from '@shared/auth'
import { CircularTimer } from '@shared/ui'

export function ConfirmDescription() {
  const { logout } = useAuth()
  const { t } = useTranslation('entities')

  return (
    <CircularTimer
      text={t('inactive-notification.description')}
      timerValue={60 * 1000}
      action={() => {
        logout()
      }}
    />
  )
}
