import { Stack, SxProps, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { useVirtualAccountStore } from '@entities/virtual-account'

import { formatEurNumber } from '@shared/utils'

interface IProps {
  sx?: SxProps
  toCryptoButton?: ReactNode
}

export function VirtualAccountHeaderData({ toCryptoButton, sx }: IProps) {
  const virtualAccount = useVirtualAccountStore((state) => state.virtualAccount)
  const { t } = useTranslation('entities')

  return (
    <Stack gap={1} sx={sx}>
      <Typography variant={'h1'} fontSize={18} fontWeight={500}>
        {virtualAccount?.type === 'individual'
          ? t('virtual-account.title-personal')
          : t('virtual-account.title-business')}
      </Typography>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant={'numeric1'} fontSize={24} fontWeight={200}>
          {formatEurNumber(virtualAccount?.balance)} EUR
        </Typography>

        {toCryptoButton}
      </Stack>
    </Stack>
  )
}
