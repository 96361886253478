import { Typography, styled } from '@mui/material'

interface IProps {
  disabled?: boolean
  payload: {
    active?: boolean
  }
}

export const StyledTypography = styled(Typography)<IProps>(
  ({ theme, payload: { active }, disabled }) => ({
    color: active
      ? '#FFFFFF'
      : disabled
        ? '#7FCEEF'
        : theme.palette.primary.main,
    // transition: theme.transitions.create(['color'], {
    //   duration: theme.transitions.duration.standard,
    // }),
  })
)
