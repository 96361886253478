import { useTheme } from '@mui/material'

interface IGetProgressBarColorProps {
  totalPercentage: number
  isPercentageSumError: boolean
}

export function useGetProgressBarColor() {
  const theme = useTheme()

  const getProgressBarColor = ({
    totalPercentage,
    isPercentageSumError,
  }: IGetProgressBarColorProps) => {
    if (totalPercentage <= 99 && !isPercentageSumError) {
      return theme.palette.warning.light
    }

    if (totalPercentage <= 99 || totalPercentage > 100) {
      return theme.palette.warning.dark
    }

    return theme.palette.primary.main
  }

  return getProgressBarColor
}
