import { TCryptoCoinKey } from '@shared/constants'

import { ReactComponent as BTCERR } from '../icons/BTC-ERR.svg'
import { ReactComponent as BTC } from '../icons/BTC.svg'
import { ReactComponent as ETHERR } from '../icons/ETH-ERR.svg'
import { ReactComponent as ETH } from '../icons/ETH.svg'
import { ReactComponent as EURERR } from '../icons/EUR-ERR.svg'
import { ReactComponent as EUR } from '../icons/EUR.svg'
import { ReactComponent as TRXCOLOR } from '../icons/TRX-COLOR.svg'
import { ReactComponent as TRXERR } from '../icons/TRX-ERR.svg'
import { ReactComponent as TRX } from '../icons/TRX.svg'
import { ReactComponent as USDCCOLOR } from '../icons/USDC-COLOR.svg'
import { ReactComponent as USDCERR } from '../icons/USDC-ERR.svg'
import { ReactComponent as USDC } from '../icons/USDC.svg'
import { ReactComponent as USDTCOLOR } from '../icons/USDT-COLOR.svg'
import { ReactComponent as USDTERR } from '../icons/USDT-ERR.svg'
import { ReactComponent as USDT } from '../icons/USDT.svg'

interface CoinComponents {
  current: JSX.Element
  issue: JSX.Element
  color: JSX.Element
}

type CoinComponentMap = Record<TCryptoCoinKey, CoinComponents>

export const coinComponents: CoinComponentMap = {
  BTC: {
    current: <BTC />,
    issue: <BTCERR />,
    color: <BTCERR />,
  },
  ETH: {
    current: <ETH />,
    issue: <ETHERR />,
    color: <ETHERR />,
  },
  EUR: {
    current: <EUR />,
    issue: <EURERR />,
    color: <EUR />,
  },
  TRX: {
    current: <TRX />,
    issue: <TRXERR />,
    color: <TRXCOLOR />,
  },
  USDC: {
    current: <USDC />,
    issue: <USDCERR />,
    color: <USDCCOLOR />,
  },
  USDT: {
    current: <USDT />,
    issue: <USDTERR />,
    color: <USDTCOLOR />,
  },
}

export const defaultComponent: CoinComponents = {
  current: <USDT />,
  issue: <USDTERR />,
  color: <USDTCOLOR />,
}
