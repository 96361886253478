import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined'
import { Typography } from '@mui/material'
import { forwardRef, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { TQrReadStatus } from '../../model'

import { StyledCameraMessageBox } from './styled'

interface IProps {
  status: TQrReadStatus
}

export const CameraVideo = forwardRef(function CameraVideo(
  { status }: IProps,
  ref: React.ForwardedRef<HTMLVideoElement>
) {
  const { t } = useTranslation('features')

  return (
    <Fragment>
      <video
        ref={ref}
        style={{ borderRadius: '10px', width: 200, minHeight: 200 }}
        controls={false}
        autoPlay
        playsInline
        muted
        disablePictureInPicture
      ></video>

      {status === 'stopped' && (
        <StyledCameraMessageBox>
          <CameraAltOutlinedIcon sx={{ fontSize: 100 }} />

          <Typography variant={'subtitle3'} textAlign={'center'}>
            {t('qr read.Select camera')}
          </Typography>
        </StyledCameraMessageBox>
      )}

      {status === 'error' && (
        <StyledCameraMessageBox>
          <NoPhotographyOutlinedIcon sx={{ fontSize: 100 }} />

          <Typography variant={'subtitle3'} textAlign={'center'}>
            {t('qr read.Camera not found')}
          </Typography>
        </StyledCameraMessageBox>
      )}
    </Fragment>
  )
})
