export const red = {
  50: '#FBE9E9',
  100: '#F7D4D4',
  200: '#F0A8A8',
  300: '#E87D7D',
  400: '#E15151',
  500: '#C72323',
  600: '#AE1E1E',
  700: '#821717',
  800: '#570F0F',
  900: '#2B0808',
  950: '#160404',
}
