import axios from 'axios'

import { BASE_URL } from '@shared/constants'
import { sendError } from '@shared/error-handlers'
import keycloak from '@shared/keycloak'

const api = axios.create({
  baseURL: BASE_URL,
})

api.interceptors.request.use(
  async (config) => {
    // Do something before request is sent

    config.headers &&
      (config.headers.Authorization = 'Bearer ' + keycloak.token)

    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      'response' in error &&
      'status' in error.response &&
      (error.response.status < 401 || error.response.status > 403)
    ) {
      sendError({ message: JSON.stringify(error) })
    }

    if (
      (error && !error.response) ||
      (error &&
        error.response &&
        error.response.status > 401 &&
        error.response.status !== 403)
    ) {
      return Promise.reject(error || new Error('Bad request'))
    }

    const { response, config } = error

    const { status } = response

    try {
      if (status === 403) {
        await keycloak.logout()

        return Promise.reject(error)
      }

      if (status === 401) {
        const isTokenUpdated = await keycloak.updateToken()

        if (!isTokenUpdated) {
          keycloak.logout()

          return Promise.reject(error)
        }

        const secondRequestResponse = await axios.request({
          ...config,
          headers: {
            ...config.headers,
            Authorization: 'Bearer ' + keycloak.token,
          },
        })

        if (!secondRequestResponse) {
          keycloak.logout()

          return Promise.reject(error)
        }

        return secondRequestResponse
      } else {
        return Promise.reject(error)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return Promise.reject(error)
    }
  }
)

export { api }
