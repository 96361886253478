import { styled } from '@mui/material'

export const Wrapper = styled('div')(({ theme }) => ({
  margin: 0,
  padding: '8px 0 8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 24,
  borderRadius: 16,
  [theme.breakpoints.up('md')]: {
    padding: 0,
  },
}))
