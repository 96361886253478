import Lottie, { LottieOptions } from 'lottie-react'

import { searchAnimations } from './animations'

const animations = {
  searchAnimations,
} as const

interface IProps extends Omit<LottieOptions, 'animationData'> {
  animation: keyof typeof animations
}

export function AnimationViewer({ animation, ...lottieOptions }: IProps) {
  return <Lottie animationData={animations[animation]} {...lottieOptions} />
}
