import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const TopUpCryptoWalletRoute: RouteObject = {
  path: routeNamesPaths.receive,
  lazy: async () => {
    const { TopUpCryptoWallet } = await import('./ui')

    return { Component: TopUpCryptoWallet }
  },
}
