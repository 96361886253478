import Keycloak, { KeycloakConfig } from 'keycloak-js'

import {
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_DEACTIVATE_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
} from '@shared/constants'

const keycloakInitOptions: KeycloakConfig = {
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
}

const keycloak = new Keycloak(keycloakInitOptions)

const keycloakDeactivateInitOptions: KeycloakConfig = {
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_DEACTIVATE_CLIENT_ID,
}

const deactivateKeycloak = new Keycloak(keycloakDeactivateInitOptions)

export { keycloak, deactivateKeycloak }

export default keycloak
