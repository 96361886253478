import { Button, styled } from '@mui/material'

import { gapBetweenIconAndText } from '../../../../model'

export const UserMenuButton = styled(Button)(({ theme, disableRipple }) => ({
  justifyContent: 'flex-start',
  gap: gapBetweenIconAndText,
  borderRadius: theme.spacing(1.5),

  '&.MuiButtonBase-root': {
    padding: '12px 16px',
  },

  ...(disableRipple && {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
}))
