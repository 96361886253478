import { ReactKeycloakProvider } from '@react-keycloak/web'
import { PropsWithChildren } from 'react'

import keycloak from '@shared/keycloak'

export function KeycloakProvider({ children }: PropsWithChildren<object>) {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'login-required' }}
    >
      {children}
    </ReactKeycloakProvider>
  )
}
