import { styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IStyledDragNDropBox {
  isError?: boolean
}

export const StyledDragNDropBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isError',
})<IStyledDragNDropBox>(({ theme, isError }) => ({
  marginBottom: isError ? '4px' : '8px',
  backgroundColor: isError ? theme.palette.error.light : grey[50],
  padding: 0,
  border: isError
    ? `1px dashed ${theme.palette.error.dark}`
    : `1px dashed ${theme.palette.primary.dark}`,
  height: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  rowGap: '8px',
  borderRadius: '10px',
  cursor: 'pointer',
  '&:focus-visible': {
    boxShadow: theme.shadows[6],
  },
  [theme.breakpoints.up('md')]: {
    '& .cloud_icon path:last-child': {
      ...(isError && {
        fill: theme.palette.error.main,
      }),
    },
    padding: '4px',
    marginBottom: isError ? '4px' : '16px',
    ':hover': {
      backgroundColor: isError ? theme.palette.error.main : grey[100],
      border: isError
        ? `1px dashed ${theme.palette.error.dark}`
        : `1px solid ${theme.palette.primary.dark}`,
      '& .MuiPaper-root': {
        backgroundColor: isError
          ? theme.palette.error.dark
          : theme.palette.primary.dark,
      },
      '& .cloud_icon path:last-child': {
        ...(isError && {
          fill: theme.palette.error.dark,
        }),
      },
      '& .drop_file': {
        ...(isError && {
          color: theme.palette.error.dark,
        }),
      },
      '& + .error_message': {
        '& .warning_icon path': {
          ...(isError && {
            fill: theme.palette.error.dark,
          }),
        },
        '& p': {
          ...(isError && {
            color: theme.palette.error.dark,
          }),
        },
      },
    },
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.between(1248, 1360)]: {
    flexDirection: 'column',
  },
}))
