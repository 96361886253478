import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface IPostMessage {
  method: 'openLink'
  payload: string
}

interface IMobileWebviewStore {
  isMobile: boolean
  setIsMobile: (open: boolean) => void

  sendPostMessageLink: (link: string) => void
}

/**
 * This store only for using in the webview app on the
 * mobile devices
 */

export const useMobileWebviewStore = create<IMobileWebviewStore>()(
  devtools(
    (set) => ({
      isMobile: false,
      setIsMobile: (isMobile) => set(() => ({ isMobile })),

      sendPostMessageLink: (link) => {
        const body: IPostMessage = {
          method: 'openLink',
          payload: link,
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        window.ReactNativeWebView.postMessage(JSON.stringify(body))
      },
    }),
    {
      name: 'webview',
    }
  )
)
