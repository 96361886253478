import { LoadingButton } from '@mui/lab'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { routeNamesPaths } from '@shared/constants'
import { useNoInternet } from '@shared/hooks'

import { ReactComponent as NoInternetIllustration } from './assets/no-internet-illustration.svg'
import { StyledGridItem } from './styled'

export function NoInternetPage() {
  const { t } = useTranslation('pages')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const { isOnline } = useNoInternet()
  const isNoInternetPage = location.pathname === routeNamesPaths.noInternet
  const [loading, setLoading] = useState(false)

  const handleCheckConnection = () => {
    setLoading(true)

    setTimeout(() => {
      !isOnline &&
        isNoInternetPage &&
        toast.error(t('no-internet.error-message'))

      setLoading(false)
    }, 1000)
  }

  return (
    <>
      <Helmet
        encodeSpecialCharacters={true}
        defaultTitle={`Arbiex | ${t('titles.no-internet')}`}
      />

      <Box maxWidth={{ xs: 500, md: 'initial' }} mx="auto">
        <Grid container spacing={{ md: 4, lg: 8 }}>
          <StyledGridItem item xs={12} md={5}>
            <Typography
              variant={mdAndUp ? 'display1' : 'subtitle1'}
              mb={{ xs: 1, md: 2 }}
              textAlign={{ xs: 'center', md: 'initial' }}
            >
              {t('no-internet.title')}
            </Typography>

            <Typography
              variant="body2"
              mb={{ xs: 5.5, md: 5 }}
              textAlign={{ xs: 'center', md: 'initial' }}
            >
              {t('no-internet.description')}
            </Typography>

            <LoadingButton
              loading={loading}
              variant="contained"
              fullWidth
              onClick={handleCheckConnection}
              sx={{ maxWidth: 320, mx: { xs: 'auto', md: 'initial' } }}
            >
              {t('no-internet.button')}
            </LoadingButton>
          </StyledGridItem>

          <Grid item xs={12} md={7}>
            <NoInternetIllustration
              style={{ width: '100%', height: 'initial' }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
