import { styled } from '@mui/material'

export const StyledMaxButton = styled('button')(() => ({
  border: 0,
  background: 'none',
  cursor: 'pointer',
  padding: 0,
  fontSize: 18,
  fontWeight: 500,
}))
