import { Stack, styled } from '@mui/material'

import { grey } from '@shared/constants'

export const OperationInfoWrapper = styled(Stack)(({ theme }) => ({
  marginBottom: '24px',

  [theme.breakpoints.up('md')]: {
    padding: '28px 24px',

    backgroundColor: grey[50],
    border: '1px solid',
    borderColor: theme.palette.primary.dark,
    borderRadius: '16px',
  },
}))
