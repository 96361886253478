import { Box } from '@mui/material'

import { IMenuItem } from '../../../model'

import { UserMenuItem } from './user-menu-item'

interface IProps {
  handleClose: () => void
  menuList: IMenuItem[]
}

export function MenuList({ handleClose, menuList }: IProps) {
  return (
    <Box component="ul" sx={{ listStyle: 'none' }}>
      {menuList.map((item, index) => (
        <Box component="li" key={index} bgcolor="transparent !important">
          <UserMenuItem handleClose={handleClose} {...item} />
        </Box>
      ))}
    </Box>
  )
}
