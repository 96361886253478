import { styled } from '@mui/material'

interface IProps {
  selected: boolean
}

export const CustomCheckBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})<IProps>(({ theme, selected }) => ({
  width: 24,
  height: 24,
  borderRadius: '100%',
  border: '1px solid',
  borderColor: theme.palette.primary.dark,
  position: 'relative',

  '&:after': {
    content: "' '",
    display: 'block',
    width: 16,
    height: 16,
    borderRadius: '100%',
    backgroundColor: selected ? theme.palette.primary.dark : 'transparent',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}))
