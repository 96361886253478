import { styled, Typography } from '@mui/material'

import { grey, red } from '@shared/constants'

interface IProps {
  disabled?: boolean
  error?: boolean
}

export const TextFieldStyledLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'error',
})<IProps>(({ theme, disabled, error }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1.4,
  marginBottom: '6px',
  fontFeatureSettings: "'zero' on",
  color: disabled ? grey[100] : error ? red[500] : theme.palette.text.primary,
}))
