import { ErrorPage } from '@pages/error'

import { LayoutForErrorTechnicalPages } from './components'

export function ErrorElement() {
  return (
    <LayoutForErrorTechnicalPages>
      <ErrorPage />
    </LayoutForErrorTechnicalPages>
  )
}
