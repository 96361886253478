import { styled } from '@mui/material'

import { grey, mobileWalletsMarginTop } from '@shared/constants'

const additionalMarginTop = 2

export const WalletsWrapper = styled('div')(({ theme }) => ({
  height: 'max-content',
  backgroundColor: theme.palette.common.white,
  borderBottom: '1px solid',
  borderColor: grey[50],

  [theme.breakpoints.up('md')]: {
    border: 'none',
    backgroundColor: 'transparent',
  },

  '&:before': {
    content: '" "',
    display: 'block',

    height: mobileWalletsMarginTop + additionalMarginTop,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))
