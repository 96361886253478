import { Typography } from '@mui/material'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Image } from '../assets/Illustration.svg'
import { StyledStack } from '../styled'

export function NoWalletsMessage() {
  const { t } = useTranslation(['shared', 'pages'])

  return (
    <>
      <Helmet
        encodeSpecialCharacters={true}
        defaultTitle={`Arbiex | ${t('pages:titles.no-wallets')}`}
      />

      <StyledStack>
        <Image />
        <Typography variant={'subtitle4'}>{t('no-wallets-message')}</Typography>
      </StyledStack>
    </>
  )
}
