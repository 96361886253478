import { LoadingButton } from '@mui/lab'
import { Button, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IModalProps } from '../../model'

type IProps = Pick<
  IModalProps,
  | 'disabled'
  | 'loading'
  | 'onClose'
  | 'onConfirm'
  | 'singleBtn'
  | 'singleBtnCallback'
  | 'singleBtnText'
  | 'singleBtnVariant'
  | 'confirmBtnText'
  | 'cancelBtnText'
>

export const FooterBtns = ({
  disabled,
  loading,
  onClose,
  onConfirm,
  singleBtn,
  singleBtnCallback,
  singleBtnText,
  singleBtnVariant,
  confirmBtnText,
  cancelBtnText,
}: IProps) => {
  const { t } = useTranslation('shared')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      {singleBtn ? (
        <Stack alignItems={{ xs: 'center', md: 'flex-end' }}>
          <Button
            onClick={singleBtnCallback || onConfirm}
            type={'submit'}
            variant={singleBtnVariant || 'contained'}
            fullWidth={!mdAndUp}
            sx={{ width: { md: 184 } }}
            className="single-btn"
            disabled={disabled}
          >
            {singleBtnText || t('modal.OK')}
          </Button>
        </Stack>
      ) : (
        <Stack
          direction={{ sm: 'row', xs: 'column-reverse' }}
          gap={{ sm: 3.5, xs: 1.5 }}
        >
          <LoadingButton
            disabled={disabled}
            loading={loading}
            onClick={onClose}
            type={'submit'}
            variant={mdAndUp ? 'outlined' : 'text'}
            size="large"
            fullWidth
          >
            {cancelBtnText || t('modal.CANCEL')}
          </LoadingButton>

          <LoadingButton
            disabled={disabled}
            loading={loading}
            onClick={onConfirm}
            type={'submit'}
            variant={'contained'}
            size="large"
            fullWidth
          >
            {confirmBtnText || t('modal.CONFIRM')}
          </LoadingButton>
        </Stack>
      )}
    </>
  )
}
