import { styled } from '@mui/material'

interface IProps {
  variant?: 'form' | 'list'
  marginTop?: number
}

export const WrapperForOutletWithWallets = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'marginTop',
})<IProps>(({ theme, variant, marginTop = 0 }) => ({
  borderRadius: 0,
  paddingBottom: '12px',
  marginTop: marginTop,

  [theme.breakpoints.up('md')]: {
    marginTop: 0,

    borderRadius: '20px',
    ...(!variant && {
      padding: '36px 40px',
    }),
    ...(variant === 'form' && {
      padding: '16px 40px',
    }),
  },
}))
