import { Stack, styled } from '@mui/material'

import { grey } from '@shared/constants'

export const StyledStack = styled(Stack)(() => ({
  paddingTop: '8px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  minWidth: 'max-content',
  borderBottom: `${grey[100]} 1px solid`,
}))
