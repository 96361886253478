import { Stack, styled } from '@mui/material'

import { grey } from '@shared/constants'

export const IconWrapper = styled(Stack)(({ theme }) => ({
  '& svg': {
    width: 24,
    height: 24,
  },

  [theme.breakpoints.up('md')]: {
    padding: '6px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    backgroundColor: grey[50],

    '& svg': {
      width: 'initial',
      height: 'initial',
    },
  },
}))
