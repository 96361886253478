import { PaletteOptions } from '@mui/material'

import { blue, green, grey, red, orange, deepBlue } from '@shared/constants'

const common = {
  text: {
    primary: '#011217',
  },
  white: '#FFFFFF',
}

export const paletteOptions = {
  common: {
    white: common.white,
    black: '#021218',
  },
  primary: {
    dark: blue[700],
    main: blue[500],
    light: blue[50],
    contrastText: common.white,
  },
  secondary: {
    dark: '#e200ff',
    main: '#e200ff',
    light: '#e200ff',
    contrastText: '#e200ff',
  },
  text: {
    primary: common.text.primary,
    secondary: grey[500],
    disabled: '#C0C9D1',
  },
  success: {
    dark: green[800],
    main: green[500],
    light: green[50],
    contrastText: common.white,
  },
  error: {
    dark: red[800],
    main: red[500],
    light: red[50],
    contrastText: common.white,
  },
  warning: {
    dark: orange[700],
    main: orange[500],
    light: orange[50],
    contrastText: common.text.primary,
  },
  info: {
    dark: deepBlue[800],
    main: deepBlue[500],
    light: deepBlue[50],
    contrastText: common.white,
  },
  background: {
    default: '#F3F5F9',
  },
} satisfies PaletteOptions
