import { TableRow, styled } from '@mui/material'

import { grey } from '@shared/constants'

export const StyledTableHeaderRow = styled(TableRow)(({ theme }) => ({
  '& th': {
    ...theme.typography.subtitle5,
    color: grey[600],
    textTransform: 'uppercase',
    padding: '18px 32px',
    borderBottom: `1px solid ${theme.palette.common.white}`,
  },
}))
