import { api } from '@shared/http-instance'

import { IWallet } from '../model'

export async function fetchWalletList(updateBalance = true) {
  const url = `/crypto/wallets?updateBalance=${updateBalance}`

  const response = await api.get<IWallet[]>(url)

  return response.data
}
