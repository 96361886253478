import { Stack, styled } from '@mui/material'

export const StyledStack = styled(Stack)<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    width: '100%',
    height: 48,
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: disabled ? '#F2FBFF' : 'transparent',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    position: 'relative',
    cursor: disabled ? 'not-allowed' : 'pointer',
  })
)
