import { Grid, styled } from '@mui/material'

export const StyledGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
  },
}))
