import { styled } from '@mui/material'

export const StyledUploadLineNTextContainer = styled('div')(() => ({
  marginLeft: 10,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxHeight: 38,
  height: 'min-content',
  width: '100%',
}))
