import { SxProps } from '@mui/material'

export const titleWithIconSx: SxProps = {
  mr: 0.5,
  '& svg': {
    width: 24,
    height: 24,
    '& path': { stroke: 'black', strokeWidth: 1 },
  },
}
