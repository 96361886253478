import { Container, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AccountSwitcherButton,
  HelpCenterPopper,
  UserMenu,
} from '@features/app-bar'
import { NotificationBar } from '@features/notification-bar'
import { useProjectsSwitcherStore } from '@features/projects-switcher'

import { useVirtualAccountList } from '@entities/virtual-account'

import { externalLinks } from '@shared/constants'

import { Logo } from './components'
import { StyledAppBar, StyledToolbar } from './styled'

interface IProps {
  showNotificationBar?: boolean
}

export function AppBar({ showNotificationBar }: IProps) {
  const { t } = useTranslation('widgets')
  const theme = useTheme()
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'))
  const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'))
  const setAnchorEl = useProjectsSwitcherStore((state) => state.setAnchorEl)
  const { data: virtualAccounts } = useVirtualAccountList()

  const isShowAccountSwitcher = useMemo(
    () => virtualAccounts && virtualAccounts.length > 1,
    [virtualAccounts]
  )

  const notificationBarSx = useMemo(
    () => ({
      ...(!lgAndUp && { top: '-44px' }),
      transition: theme.transitions.create(['height', 'top', 'padding']),
    }),
    [lgAndUp]
  )

  return (
    <StyledAppBar position="sticky" onClick={() => setAnchorEl(null)}>
      <Container
        maxWidth="xl"
        sx={{ px: { xs: 0, lg: 3 }, position: 'relative' }}
      >
        <StyledToolbar
          sx={{ position: 'relative', zIndex: 3, py: 1 }}
          disableGutters={true}
        >
          <Logo />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent={{ xs: 'flex-end' }}
            maxWidth={{ sm: 380, md: 472 }}
            spacing={{ sm: 2 }}
            width="100%"
          >
            {smAndUp && <HelpCenterPopper />}

            <Stack
              direction="row"
              height="100%"
              alignItems="center"
              spacing={{ xs: 1 }}
            >
              {isShowAccountSwitcher && <AccountSwitcherButton />}
              <UserMenu />
            </Stack>
          </Stack>
        </StyledToolbar>

        {showNotificationBar && (
          <NotificationBar
            message={t('app bar.not verified.message')}
            buttonText={t('app bar.not verified.button')}
            buttonHref={externalLinks.idv}
            sx={notificationBarSx}
          />
        )}
      </Container>
    </StyledAppBar>
  )
}
