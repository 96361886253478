import * as dateFnsLocales from 'date-fns/locale'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import { initReactI18next } from 'react-i18next'

import { IS_DEV_MODE } from '@shared/constants'

import { sk, cz, en } from './translations'

export const languageLabels = {
  en: 'En',
  sk: 'Sk',
  cz: 'Cz',
} as const

export const languages = Object.keys(languageLabels) as TLanguage[]

export type TLanguage = keyof typeof languageLabels

interface Locales {
  [key: string]: Locale
}

export const getDateFnsLocale = (): Locale => {
  const locales: Locales = {
    en: dateFnsLocales.enGB,
    sk: dateFnsLocales.sk,
    cs: dateFnsLocales.cs,
  }

  return locales[i18n.language]
}

i18n
  // To define phrases expressing the number of items lies in a range.
  // learn more: https://www.i18next.com/translation-function/plurals
  .use(intervalPlural)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: IS_DEV_MODE,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en,
      sk,
      cz,
    },
    defaultNS: '',
    supportedLngs: ['en', 'sk', 'cz'],
  })

export default i18n
