import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const ErrorPageRoute: RouteObject = {
  path: routeNamesPaths.errorPage,
  lazy: async () => {
    const { ErrorPage } = await import('./ui')

    return { Component: ErrorPage }
  },
}
