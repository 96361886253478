import { SxProps, Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { gapBetweenIconAndText } from '../../../model'
import { ReactComponent as QuestionSing } from '../assets/question-sign.svg'

interface IProps {
  sx?: SxProps
}

export function TitleWithIcon({ sx }: IProps) {
  const { t } = useTranslation('features')

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={gapBetweenIconAndText}
      sx={sx}
    >
      <QuestionSing />
      <Typography variant="body4">{t('app-bar.help-center.help')}</Typography>
    </Stack>
  )
}
