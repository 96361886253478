import { Button, styled } from '@mui/material'

interface IProps {
  active: boolean
}

export const BuySellButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<IProps>(({ theme, active }) => ({
  position: 'relative',
  width: '50%',
  height: '100%',
  background: 'transparent',
  borderRadius: '10px',
  color: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: 'initial',
  },
  ...(active && {
    zIndex: 2,
    border: `1px solid ${theme.palette.primary.dark}`,
    background: theme.palette.primary.dark,
    color: theme.palette.common.white,

    '&:hover': {
      background: theme.palette.primary.main,
    },
  }),
}))
