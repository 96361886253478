import { Stack, styled } from '@mui/material'

export const StyledStack = styled(Stack)(({ theme }) => ({
  maxWidth: 608,
  margin: '0 auto',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    maxWidth: 'initial',
    flexDirection: 'row',
    gap: theme.spacing(5),
  },
}))
