import { styled } from '@mui/material'

export const StyledCameraMessageBox = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  maxWidth: 400,
  height: 200,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '24px',
}))
