import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useRouteError } from 'react-router-dom'

import { useAuthStore } from '@shared/auth'
import { routeNamesPaths } from '@shared/constants'
import { sendError } from '@shared/error-handlers'

import { ReactComponent as TechWorkIllustration } from './assets/tech-work-illustration.svg'
import { StyledGridItem } from './styled'

export function ErrorPage() {
  const { t } = useTranslation('pages')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const error = useRouteError()
  const user = useAuthStore((state) => state.user)

  useEffect(() => {
    if (!error) {
      return
    }

    const payload = {
      id: user?.userId,
      error: error,
      date: new Date().toISOString(),
    }

    sendError({
      message: JSON.stringify(payload),
    })
  }, [])

  return (
    <>
      <Helmet
        encodeSpecialCharacters={true}
        defaultTitle={`Arbiex | ${t('titles.error')}`}
      />

      <Box maxWidth={{ xs: 500, md: 'initial' }} mx="auto">
        <Grid container spacing={{ xs: 2.5, md: 4, lg: 8 }}>
          <Grid item xs={12} md={7}>
            <TechWorkIllustration
              style={{ width: '100%', height: 'initial' }}
            />
          </Grid>

          <StyledGridItem item xs={12} md={5}>
            <Typography
              variant={mdAndUp ? 'display1' : 'subtitle1'}
              mb={{ xs: 1, md: 2 }}
              textAlign={{ xs: 'center', md: 'initial' }}
            >
              {t('error.title')}
            </Typography>

            <Button
              variant="contained"
              fullWidth
              href={routeNamesPaths.balances}
              sx={{ maxWidth: 320, mx: { xs: 'auto', md: 'initial' } }}
            >
              {t('error.back-button')}
            </Button>
          </StyledGridItem>
        </Grid>
      </Box>
    </>
  )
}
