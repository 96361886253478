import { styled, Paper } from '@mui/material'

interface IProps {
  transparent?: boolean
  disableGutters?: boolean
}

export const PaperRounded = styled(Paper, {
  shouldForwardProp: (prop) =>
    prop !== 'transparent' && prop !== 'disableGutters',
})<IProps>(({ theme, transparent, disableGutters }) => ({
  borderRadius: 24,
  background: 'transparent',
  boxShadow: 'none',

  [theme.breakpoints.up('md')]: {
    padding: '32px 24px',
    background: theme.palette.background.paper,
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.08)',

    ...(transparent && {
      background: 'transparent',
      boxShadow: 'none',
    }),
  },

  ...(transparent === false && {
    background: theme.palette.background.paper,
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
  }),

  ...(disableGutters && {
    padding: 0,
  }),
}))
