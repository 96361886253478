import { Button, styled } from '@mui/material'

import { grey } from '@shared/constants'

interface IProps {
  selected: boolean
}

export const StyledListItemButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<IProps>(({ theme, selected }) => ({
  width: '100%',
  padding: '6px 16px',
  borderRadius: 10,
  justifyContent: 'flex-start',
  background: selected
    ? theme.palette.primary.dark
    : theme.palette.common.white,
  transition: theme.transitions.create('background'),

  ...(selected && {
    '&:hover': {
      background: theme.palette.primary.main,
    },
  }),

  '&:hover .MuiListItemIcon-root': {
    ...(!selected && {
      background: grey[100],
    }),
  },

  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: theme.spacing(1.5),

    '& svg': {
      width: 24,
      height: 24,

      '&.stroke path': {
        transition: theme.transitions.create('stroke'),
        ...(selected && {
          stroke: theme.palette.common.white,
        }),
      },

      '&.fill path': {
        transition: theme.transitions.create('fill'),
        ...(selected && {
          fill: theme.palette.common.white,
        }),
      },
    },
  },

  '& .MuiListItemText-root': {
    transition: theme.transitions.create('color'),
    textAlign: 'start',
    color: theme.palette.primary.dark,
    ...(selected && {
      color: theme.palette.common.white,
    }),
  },
}))
