import { Button, styled } from '@mui/material'

interface IProps {
  active?: boolean
  noBorderRadius?: boolean
  first?: boolean
  last?: boolean
}

export const CustomSecButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'active' &&
    prop !== 'noBorderRadius' &&
    prop !== 'first' &&
    prop !== 'last',
})<IProps>(({ theme, active, noBorderRadius, first, last }) => ({
  borderColor: theme.palette.primary.dark,
  color: theme.palette.primary.dark,
  borderRadius: 10,
  padding: '12px 16px',
  minWidth: '140px !important',
  lineHeight: 'normal',
  backgroundColor: theme.palette.common.white,

  [theme.breakpoints.up('md')]: {
    ...(noBorderRadius && {
      borderRadius: 0,
      ...(first && {
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderRightWidth: 0,
      }),
      ...(last && {
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        borderRightWidth: 1,
      }),
    }),
  },

  '&:hover': {
    borderColor: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    ...(first && {
      borderRightWidth: 0,
    }),
    ...(last && {
      borderRightWidth: 1,
    }),
  },

  ...(active && {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    '& .MuiButton-startIcon svg path': {
      fill: theme.palette.common.white,
    },

    '&:hover': {
      ...(first && {
        borderRight: 'none',
      }),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& .MuiButton-startIcon svg path': {
        fill: theme.palette.common.white,
      },
    },
  }),
}))
