import { Box, styled } from '@mui/material'

export const StyledAccordionTitleContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gridTemplateRows: '1fr 1fr',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    '&.with-shareholder': {
      gridTemplateColumns: '2fr 3fr 1fr 1fr',
    },
    gridTemplateColumns: '2fr 3fr 1fr',
    gridTemplateRows: '1fr',
  },
}))
