import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'

export const Button = styled(LoadingButton)(({ theme }) => ({
  padding: '16px 64px',
  fontSize: 20,
  fontWeight: 500,
  lineHeight: '120%',
  borderRadius: 8,
  ':hover': {
    backgroundColor: '#0155B7',
  },
  [theme.breakpoints.up('md')]: {
    padding: '12px 64px ',
  },
}))
