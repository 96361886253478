import { Box, IconButton, SxProps } from '@mui/material'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ReactComponent as CopyIconDisabled } from './assets/copy-icon-disabled.svg'
import { ReactComponent as CopyIcon } from './assets/copy-icon.svg'

interface IProps {
  value?: string | null
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  color?: string
  sx?: SxProps
  copiedElementName?: string
}

export function CopyButton({
  disabled = false,
  value,
  size = 'small',
  sx,
  copiedElementName,
}: IProps) {
  const { t } = useTranslation('shared')

  async function copyHandle(event: MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.stopPropagation()

    try {
      if (!value) {
        return
      }

      const input = document.createElement('input')

      input.value = value

      input.style.position = 'fixed'

      input.style.left = '-1000000000px'

      window.document.body.appendChild(input)

      input.focus()

      input.select()

      await navigator.clipboard.writeText(value)

      input.remove()

      if (copiedElementName) {
        toast.success(
          t('copy-button.This-copied-successfully', {
            this: copiedElementName,
          })
        )

        return
      }

      toast.success(t('copy-button.Copied successfully'))
    } catch (error) {
      if (copiedElementName) {
        toast.error(
          t('copy-button.Error-to-copy-this', {
            this: copiedElementName,
          })
        )

        return
      }

      toast.error(t('copy-button.Error to copy'))
    }
  }

  return (
    <IconButton
      className={'copyBtn'}
      sx={sx}
      size={size}
      onClick={copyHandle}
      disabled={disabled || !value}
    >
      <Box>{disabled ? <CopyIconDisabled /> : <CopyIcon />}</Box>
    </IconButton>
  )
}
