import { SxProps } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@shared/auth'

import { useInactiveNotificationStore } from '../model'

import { ConfirmDescription } from './components'

export function InactiveNotification() {
  const {
    showNotification: showInactiveNotification,
    setShowNotification: setShowInactiveNotification,
  } = useInactiveNotificationStore()

  const { t } = useTranslation('entities')

  const confirm = useConfirm()
  const { logout } = useAuth()

  const buttonSx: SxProps = { fontSize: 20 }

  async function showConfirmDialog() {
    await confirm({
      title: t('inactive-notification.title'),
      titleProps: { fontSize: 28 },
      description: <ConfirmDescription />,
      cancellationText: t('inactive-notification.sign out'),
      confirmationText: t('inactive-notification.continue'),
      cancellationButtonProps: { sx: buttonSx },
      confirmationButtonProps: { sx: buttonSx },
      dialogProps: {
        onKeyDown(event) {
          if (event.key === 'Escape') {
            setShowInactiveNotification(false)
          }
        },
        onBackdropClick() {
          setShowInactiveNotification(false)
        },
        onClose() {
          setShowInactiveNotification(false)
        },
      },
    })
      .then(() => {
        setShowInactiveNotification(false)
      })
      .catch(() => {
        logout()
      })
  }

  useEffect(() => {
    if (showInactiveNotification) {
      showConfirmDialog()
    }
  }, [showInactiveNotification])

  return <></>
}
