import { Dialog, styled } from '@mui/material'

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(39, 45, 52, 0.70)',
  },
  '& .MuiDialog-paper': {
    borderRadius: 16,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      minWidth: 760,
      padding: '40px 32px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 352,
    },

    overflowY: 'overlay',
    '&::-webkit-scrollbar': {
      width: 6,
      height: 6,
      background: 'transparent',
    },
    '&::-webkit-scrollbar-track-piece': {
      marginTop: '20px',
      marginBottom: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      width: 6,
      height: 6,
      borderRadius: '100px',
      background: theme.palette.primary.dark,
      visibility: 'hidden',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      visibility: 'visible',
      background: theme.palette.primary.dark,
      width: 6,
      height: 6,
    },
  },
}))
