import { styled, CircularProgress, CircularProgressProps } from '@mui/material'

interface IProps extends CircularProgressProps {
  disabled?: boolean
}

export const StyledMainProgress = styled(CircularProgress)<IProps>(({
  theme,
  disabled,
  value,
}) => {
  const setBackgroundColor = () => {
    if (disabled) {
      return 'transparent'
    }

    if (value === 100) {
      return theme.palette.primary.dark
    }

    return
  }

  return {
    color: disabled ? 'transparent' : theme.palette.primary.dark,
    position: 'absolute',
    left: 0,
    backgroundColor: setBackgroundColor(),
    borderRadius: '100%',
    transition: theme.transitions.create(['background-color', 'color'], {
      duration: theme.transitions.duration.standard,
    }),
  }
})
