import { DialogContent, useMediaQuery, useTheme } from '@mui/material'
import { useCallback } from 'react'

import { scrollbarSx } from '@shared/ui'

import { IModalProps } from '../model'

import { CrossBtn, ModalContent } from './components'
import { StyledDialog, StyledDrawer } from './styled'

export function Modal(props: IModalProps) {
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const { open, onClose, sx, loading } = props

  const handleClose = useCallback(!loading ? onClose : () => {}, [
    loading,
    onClose,
  ])

  return (
    <>
      {mdAndUp && (
        <StyledDialog
          open={open}
          onClose={handleClose}
          disableEscapeKeyDown={loading}
          sx={sx}
        >
          <CrossBtn onClose={onClose} disabled={loading} />

          <DialogContent sx={{ padding: '40px 32px 64px', ...scrollbarSx }}>
            <ModalContent {...props} />
          </DialogContent>
        </StyledDialog>
      )}

      {!mdAndUp && (
        <StyledDrawer
          disableEscapeKeyDown={loading}
          anchor="bottom"
          open={open}
          onClose={handleClose}
          ModalProps={{ BackdropProps: { onClick: handleClose } }}
          sx={sx}
        >
          <CrossBtn onClose={onClose} disabled={loading} />
          <ModalContent {...props} />
        </StyledDrawer>
      )}
    </>
  )
}
