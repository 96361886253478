import { Dialog, styled } from '@mui/material'

import { scrollbarSx } from '@shared/ui'

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root.MuiDialog-paper': {
    padding: 0,
    borderRadius: '16px',
    boxShadow: '0px -3px 8px 0px rgba(0, 0, 0, 0.08)',
    marginBottom: '32px',
    minWidth: 670,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',

    ...scrollbarSx,
  },
}))
