import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const TechnicalPageRoute: RouteObject = {
  path: routeNamesPaths.techWork,
  lazy: async () => {
    const { TechnicalWorkPage } = await import('./ui')

    return { Component: TechnicalWorkPage }
  },
}
