import {
  Box,
  ClickAwayListener,
  SxProps,
  Tooltip,
  useTheme,
} from '@mui/material'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useBusinessValidationStore,
  useGetProgressBarColor,
} from '@entities/business-account-application'

import { CustomLinearProgress } from '@shared/ui'

interface IProps {
  sx?: SxProps
}

export const PercentageLinearProgress = memo(function PercentageLinearProgress({
  sx,
}: IProps) {
  const { t } = useTranslation('widgets')
  const theme = useTheme()

  const totalPercentage = useBusinessValidationStore(
    (state) => state.totalPercentage
  )

  const isPercentageSumError = useBusinessValidationStore(
    (state) => state.isPercentageSumError
  )

  const getProgressBarColor = useGetProgressBarColor()

  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          title={t(
            'business account application.shareholder list.Total percentage'
          )}
          placement="top"
        >
          <Box
            width={212}
            ml="auto"
            onClick={handleTooltipOpen}
            onMouseOver={handleTooltipOpen}
            onMouseLeave={handleTooltipClose}
          >
            <CustomLinearProgress
              withPercentage
              stepCount={100}
              currentStep={totalPercentage}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                '& p': {
                  fontSize: 0,
                  order: 1,
                  '& span': {
                    fontSize: 12,
                    lineHeight: '120%',
                    fontWeight: 600,
                    color: theme.palette.common.black,
                  },
                },
                '& .MuiLinearProgress-root': {
                  height: 12,
                  '&.MuiLinearProgress-colorPrimary': {
                    backgroundColor: theme.palette.primary.light,
                  },
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: getProgressBarColor({
                      totalPercentage,
                      isPercentageSumError,
                    }),
                    ...(totalPercentage > 100 && {
                      transform: 'translate(0) !important',
                    }),
                  },
                },
                ...sx,
              }}
            />
          </Box>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  )
})
