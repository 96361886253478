import { Container, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import { AppBar } from '@widgets/app-bar'
import { Footer } from '@widgets/footer'

import { NotVerified } from '@features/not-verified'
import { WelcomeDialog } from '@features/welcome-dialog'

import { AppLoader, useAppLoaderStore } from '@entities/app-loader'

import { externalLinks } from '@shared/constants'
import { PaperRounded, WrapperForMainLayout } from '@shared/ui'

import { useMainLayout } from './hooks'

export function MainGeneralLayout() {
  const { t } = useTranslation('app')
  const theme = useTheme()
  const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'))
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const setShowLoader = useAppLoaderStore((state) => state.setShow)

  const { isVerified, userDetailLoaded } = useMainLayout()

  useEffect(() => {
    if (userDetailLoaded) {
      setShowLoader(false)
    }
  }, [userDetailLoaded])

  return (
    <WrapperForMainLayout>
      <ScrollRestoration />
      <AppBar showNotificationBar={!isVerified} />

      <Container
        disableGutters={!lgAndUp}
        maxWidth={'xl'}
        sx={{
          mt: { xs: 0, md: 3.5 },
          pb: 3,
          flex: 1,
          minHeight: 'max-content',
        }}
      >
        <Stack direction="row" gap={5} justifyContent={'space'}>
          <Container
            maxWidth="xl"
            disableGutters
            sx={{
              transition: theme.transitions.create(['width', 'height']),
            }}
          >
            {!isVerified && (
              <PaperRounded transparent={!mdAndUp} disableGutters={!mdAndUp}>
                <NotVerified
                  title={t('not verified.title')}
                  buttonText={t('not verified.button')}
                  description={t('not verified.description')}
                  href={externalLinks.idv}
                />
              </PaperRounded>
            )}

            {isVerified && <Outlet />}
          </Container>
        </Stack>
      </Container>

      <Footer />
      <AppLoader />
      <WelcomeDialog />
    </WrapperForMainLayout>
  )
}
