import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface IAuthStoreState {
  // State
  open: boolean

  //Mutations
  setOpen: (payload: boolean) => void
  closeHandler: () => void
  openHandler: () => void
}

export const useWelcomeDialogStore = create<IAuthStoreState>()(
  devtools(
    (set) => ({
      open: false,
      setOpen: (payload) => set(() => ({ open: payload })),
      closeHandler: () => set(() => ({ open: false })),
      openHandler: () => set(() => ({ open: true })),
    }),
    { name: 'AuthStore' }
  )
)
