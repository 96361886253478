import { apiErrorHandler } from '@shared/error-handlers'
import { api } from '@shared/http-instance'

import { IUserDetailInfo } from '../types'

export async function getUserAdditionalInfo() {
  try {
    const url = `/user/additionalInfo/recognized`

    const response = await api.get<IUserDetailInfo>(url)

    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    apiErrorHandler(error)
  }
}
