import { IOperationInfo } from '../../model'

import { OperationInfoItem } from './operation-info-item'

interface IProps {
  operationInfo: IOperationInfo[]
}

export function OperationInfoList({ operationInfo }: IProps) {
  return (
    <>
      {operationInfo.map((item, index) => (
        <OperationInfoItem
          keyName={item.keyName}
          value={item.value}
          key={index}
        />
      ))}
    </>
  )
}
