import { styled } from '@mui/material'

interface IStyledFileTitleWrap {
  isRemote?: boolean
}

export const StyledFileTitleWrap = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isRemote',
})<IStyledFileTitleWrap>(({ theme, isRemote }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: '100%',
  alignItems: 'center',
  gap: 8,
  justifyContent: 'flex-start',
  color: isRemote ? theme.palette.primary.dark : '',
  overflow: isRemote ? 'hidden' : 'initial',
  overflowX: isRemote ? 'initial' : 'hidden',
}))
