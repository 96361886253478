import { Select, styled } from '@mui/material'

export const StyledSelect = styled(Select)(({ theme }) => ({
  height: 36,
  width: 246,
  padding: 0,
  fontSize: 14,
  fontWeight: 400,
  background: 'white',
  border: '0px',

  '& .MuiSelect-select > .MuiListItemIcon-root': {
    display: 'none',
  },

  '&:has([aria-expanded="true"])': {
    borderRadius: '10px 10px 0px 0px',
  },

  '& fieldset': {
    border: `1px solid ${theme.palette.primary.dark} !important`,
  },
}))
