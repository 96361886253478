import { SxProps } from '@mui/material'

export const popoverPaperSx: SxProps = {
  width: 280,
  mt: 1,
  padding: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  '&.MuiPopover-paper': {
    borderRadius: '8px',
    '& li:hover': {
      borderRadius: 'initial',
    },
  },
}
