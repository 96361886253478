import { Button, styled } from '@mui/material'

export const StyledChooseButton = styled(Button)(({ theme }) => ({
  height: 90,
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1),
  zIndex: 1,
  paddingRight: theme.spacing(2.5),
  paddingLeft: theme.spacing(2.5),
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  background: theme.palette.background.paper,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08) !important',
  borderRadius: theme.spacing(1.25),
  textTransform: 'initial',
  cursor: 'pointer',
  userSelect: 'none',
  transition: theme.transitions.create(
    ['background', 'border-color', 'box-shadow'],
    {
      duration: theme.transitions.duration.standard,
    }
  ),
  '&:hover': {
    background: theme.palette.primary.light,
  },
  '&.active': {
    background: theme.palette.primary.light,
    boxShadow: 'none !important',
  },
  '&.disabled': {
    pointerEvents: 'initial',
    '&:hover': {
      backgroundColor: 'initial',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 2,
      background: theme.palette.common.white,
      opacity: 0.5,
      cursor: 'not-allowed',
      borderRadius: theme.spacing(2),
    },
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    width: 338,
    height: '100%',
    borderRadius: theme.spacing(2),
  },

  [theme.breakpoints.down('md')]: {
    '&.active': {
      border: `1px solid ${theme.palette.primary.main + '4d'}}`,
      background: theme.palette.common.white,
    },
  },
}))
