import { styled } from '@mui/material'

export const StyledConfirmationHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.text.secondary}`,
  [theme.breakpoints.up('md')]: {
    borderBottom: 'none',
  },
}))
