import { Button, styled } from '@mui/material'

import { deepBlue } from '@shared/constants'

export const StyledButton = styled(Button)(({ theme }) => ({
  width: 230,
  height: '100%',
  minHeight: 60,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  gap: theme.spacing(1),
  justifyContent: 'center',
  borderColor: theme.palette.primary.light + '!important',
  background: theme.palette.common.white,
  border: '2px solid',
  borderRadius: '12px',
  color: theme.palette.primary.dark,
  fontSize: 15,
  lineHeight: 1.2,

  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    color: theme.palette.primary.dark,
  },

  '&:hover': {
    background: deepBlue[50],
  },
}))
