import { SxProps, useMediaQuery, useTheme } from '@mui/material'
import { forwardRef } from 'react'

import { TGeneralTemplateType } from '@shared/types'

import { DesktopView, MobileView } from './components'
import { StyledPageTabSwitcherBox } from './styled'

interface IProps {
  sx?: SxProps
  template: TGeneralTemplateType
}

export const PageTabSwitcher = forwardRef<HTMLDivElement, IProps>(
  function PageTabSwitcher({ sx, template }, ref) {
    const theme = useTheme()
    const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

    return (
      <StyledPageTabSwitcherBox ref={ref} sx={sx}>
        {mdAndUp ? (
          <DesktopView template={template} />
        ) : (
          <MobileView template={template} />
        )}
      </StyledPageTabSwitcherBox>
    )
  }
)
