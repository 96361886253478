import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export function useNoInternet() {
  const navigate = useNavigate()
  const location = useLocation()
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const timerId = useRef<number>(0)

  useEffect(() => {
    const handleOnline = () => setIsOnline(true)
    const handleOffline = () => setIsOnline(false)

    window.addEventListener('online', handleOnline)

    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)

      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  useEffect(() => {
    clearTimeout(timerId.current)

    const isNoInternetPage = location.pathname === routeNamesPaths.noInternet

    if (!isOnline && !isNoInternetPage) {
      timerId.current = setTimeout(navigate, 1000, routeNamesPaths.noInternet)
    }

    if (isNoInternetPage && isOnline) {
      navigate(-1)
    }

    return () => clearTimeout(timerId.current)
  }, [isOnline])

  return { isOnline }
}
