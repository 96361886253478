import { styled } from '@mui/material'

import { PaperRounded } from '@shared/ui'

export const StyledPaperRounded = styled(PaperRounded)(() => ({
  padding: '48px 64px 28px !important',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))
