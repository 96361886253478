import { styled } from '@mui/material'

interface IProps {
  isMarked?: boolean
}

export const WrapperForWarningMessage = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMarked',
})<IProps>(({ theme, isMarked }) => ({
  backgroundColor: theme.palette.primary.dark,
  padding: '20px 24px',
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: 10,
  height: 'min-content',

  ...(isMarked && {
    borderLeftWidth: 8,
    paddingLeft: 14,
  }),

  [theme.breakpoints.up('md')]: {
    padding: '16px 20px',
    ...(isMarked && {
      paddingLeft: 12,
    }),
  },
}))
