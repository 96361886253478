import { Box, Button, SxProps, Typography } from '@mui/material'

import { StarsBox, RocketBox, Wrapper } from './styled'

interface IProps {
  description: string
  buttonText?: string
  title: string
  hideButton?: boolean
  href?: string
  sx?: SxProps
}

export function NotVerified({
  buttonText,
  description,
  title,
  href,
  hideButton,
  sx,
}: IProps) {
  return (
    <Wrapper justifyContent={hideButton ? 'center' : 'initial'} sx={sx}>
      <Box mb={1}>
        <Typography
          variant="h1"
          color={(theme) => theme.palette.primary.dark}
          mb={1.5}
          textAlign="center"
          fontSize={46}
        >
          {title}
        </Typography>

        <Typography fontSize={20} lineHeight="28px" textAlign="center">
          {description}
        </Typography>
      </Box>

      <Box
        position="relative"
        width="100%"
        height={124}
        overflow="hidden"
        mb={1.5}
      >
        <StarsBox />
        <RocketBox />
      </Box>

      {!hideButton && (
        <Button variant="contained" sx={{ px: { md: 12 } }} href={href}>
          {buttonText}
        </Button>
      )}
    </Wrapper>
  )
}
