import { AxiosError } from 'axios'

import { api } from '@shared/http-instance'
import keycloak from '@shared/keycloak'

import { IUser } from '../types'

export async function getUserRequest(): Promise<null | IUser> {
  try {
    const url = `/user`

    const response = await api.get<IUser>(url)

    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (
      (error as AxiosError)?.response?.status === 404 ||
      (error as AxiosError)?.response?.status === 403
    ) {
      await keycloak.logout()
    }

    return null
  }
}
