import { useAppLoaderStore } from '../model'

import { RocketInSpace } from './components'
import { Wrapper, AnimationWrapper } from './styled'

interface IProps {
  showManual?: boolean
}

export function AppLoader({ showManual }: IProps) {
  const { show } = useAppLoaderStore()

  if (!showManual && !show) {
    return null
  }

  return (
    <Wrapper>
      <AnimationWrapper>
        <RocketInSpace />
      </AnimationWrapper>
    </Wrapper>
  )
}
