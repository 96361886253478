import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useButtonsConfig } from '@widgets/page-tab-switcher'

import { TGeneralTemplateType } from '@shared/types'
import { Map } from '@shared/ui'

import { CustomButton, StyledStack } from '../styled'

interface IProps {
  template: TGeneralTemplateType
}

export function DesktopView({ template }: IProps) {
  const { t } = useTranslation('widgets')

  const { firstArrayButtons, secondArrayButtons, checkActivePath } =
    useButtonsConfig({ template })

  return (
    <StyledStack>
      <Stack direction={'row'} p={0}>
        <Map
          collection={firstArrayButtons}
          iterate={([key, data]) => (
            <CustomButton
              key={key}
              startIcon={data.icon}
              active={checkActivePath(data.href)}
              href={data.href}
              disableRipple
            >
              {t(`page-tab-switcher.${key}` as never)}
            </CustomButton>
          )}
        />
      </Stack>

      <Stack direction={'row'} p={0}>
        <Map
          collection={secondArrayButtons}
          iterate={([key, data], index) => (
            <CustomButton
              key={key}
              active={checkActivePath(data.href)}
              href={data.href}
              last={index === secondArrayButtons.length - 1}
              disableRipple
              endIcon={key === 'toCrypto' ? data.icon : undefined}
            >
              {t(`page-tab-switcher.${key}` as never)}
            </CustomButton>
          )}
        />
      </Stack>
    </StyledStack>
  )
}
