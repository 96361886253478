import { createTheme, LinkProps, Shadows, ThemeProvider } from '@mui/material'
import { forwardRef, ReactNode } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

import { grey, deepBlue, orange } from '@shared/constants'

import { defaultTheme } from './default-theme'
import { paletteOptions } from './palette-options'
import { typographyOptions } from './typography-options'

interface IProps {
  children: ReactNode
}

// eslint-disable-next-line react/display-name
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & {
    href: RouterLinkProps['to']
  }
>((props, ref) => {
  const { href, ...other } = props

  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

const important = ' !important'

// const shadows = [
//   'none', // 0
//   `2px 3px 0px 0px ${defaultTheme.palette.primary.main}`, // 1
//   `3px 1px 0px 0px ${defaultTheme.palette.primary.main}`, // 2
//   `3px 2px 0px 0px ${defaultTheme.palette.primary.main}`, // 3
//   `4px 2px 0px 0px ${defaultTheme.palette.primary.main}`, // 4
//   `3px 2px 0px 0px ${defaultTheme.palette.error.main}`, // 5 - 3 error
//   `0px 0px 0px 3px ${yellow[500]}`, // 5 for focus-visible
//   ...Array(19).fill('none'),
// ] as unknown as Shadows

const shadows = [
  'none', // 0
  `none`, // 1
  `none`, // 2
  `none`, // 3
  `none`, // 4
  `none`, // 5 - 3 error
  `0 0 0 3px ${orange[500]}`, // 6 for focus-visible
  ...Array(19).fill('none'),
] as unknown as Shadows

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      cp: 768, //compact breakpoint for tablets.
      md: 900,
      lg: 1248,
      xl: 1536,
    },
  },
  palette: paletteOptions,
  typography: typographyOptions,
  shadows: shadows,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          [defaultTheme.breakpoints.down('md')]: {
            webkitTouchCallout: 'none' /* iOS Safari */,
            webkitUserSelect: 'none' /* Safari */,
            khtmlUserSelect: 'none' /* Konqueror HTML */,
            mozUserSelect: 'none' /* Old versions of Firefox */,
            msUserSelect: 'none' /* Internet Explorer/Edge */,
            userSelect: 'none' /* Non-prefixed version, currently
              supported by Chrome, Edge, Opera and Firefox */,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '10px',
          textTransform: 'none',
          boxShadow: shadows[0],
          transition: 'none',
          '&.MuiLoadingButton-root': {
            transition: 'none',
          },
          '&:hover': {
            boxShadow: shadows[0],
            backgroundColor: paletteOptions.primary.main,
            borderColor: defaultTheme.palette.primary.main,
          },
          '&:focus-visible': {
            outline: 'none',
            boxShadow: shadows[6],
          },
          '&.Mui-disabled': {
            color: defaultTheme.palette.text.secondary,
          },
        },
        containedPrimary: {
          backgroundColor: paletteOptions.primary.dark,
        },
        sizeSmall: {
          ...defaultTheme.typography.button3,
          lineHeight: 'normal',
          padding: '4px 10px',

          [defaultTheme.breakpoints.up('md')]: {
            padding: '8px 16px',
          },
        },
        sizeMedium: {
          ...defaultTheme.typography.button2,
          lineHeight: 'normal',
          padding: '8px 24px',

          [defaultTheme.breakpoints.up('md')]: {
            padding: '12px 24px',
          },
        },
        sizeLarge: {
          ...defaultTheme.typography.button2,
          lineHeight: 'normal',
          padding: '12px 32px',

          [defaultTheme.breakpoints.up('md')]: {
            padding: '14px 32px',
          },
        },
        outlined: {
          borderColor: paletteOptions.primary.main,

          '&.Mui-disabled': {
            backgroundColor: grey[50],
            borderColor: grey[200],
          },
          '&:hover': {
            backgroundColor: deepBlue[50],
          },
          '& fieldset': {
            borderColor: defaultTheme.palette.primary.main + ' !important',
          },
        },
        contained: {
          border: '1px solid transparent',
          color: defaultTheme.palette.common.white,
          '&.Mui-disabled': {
            backgroundColor: grey[50],
          },
        },
        text: {
          color: defaultTheme.palette.text.primary,
          '&:hover': {
            backgroundColor: deepBlue[50],
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        autoComplete: 'off',
      },
      styleOverrides: {
        root: {
          borderColor: defaultTheme.palette.common.black,
          backgroundColor: 'white',
          padding: '0px 24px',
          gap: '4px',
          minHeight: 52,

          '&:hover': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: defaultTheme.palette.primary.main,
            },

            '&.MuiInputBase-root .adornment': {
              borderColor: defaultTheme.palette.primary.main,
            },
          },

          '& .MuiIconButton-root': {
            padding: '4px',
            color: paletteOptions.primary.dark,
          },

          '&.MuiOutlinedInput-root': {
            borderRadius: 12,
          },
          '&.MuiInputBase-multiline': {
            minHeight: 52,
            paddingRight: 24,
            paddingLeft: 24,
            '& textarea': {
              fontSize: typographyOptions.input2.fontSize,
              lineHeight: typographyOptions.input2.lineHeight,
              '::placeholder': {
                color: defaultTheme.palette.text.secondary,
                fontWeight: typographyOptions.input2.fontWeight + important,
                opacity: 1,
              },
            },
          },
          '&.Mui-disabled': {
            backgroundColor: grey[50],
            color: defaultTheme.palette.text.secondary,
            '& > fieldset': {
              borderColor: grey[100],
            },
          },
          '& > fieldset': {
            top: 0,
            borderColor: defaultTheme.palette.primary.light,
            '& > legend': {
              display: 'none',
            },
          },
          '&.Mui-focused': {
            backgroundColor: 'white',
            '& > fieldset': {
              borderWidth: '2px',
            },
            '& .adornment': {
              backgroundColor: 'white',
              borderWidth: '2px',
              borderColor: defaultTheme.palette.primary.main,
            },
          },
          '&.Mui-error': {
            backgroundColor: defaultTheme.palette.error.light + important,
            color: defaultTheme.palette.error.main,
            '& .adornment': {
              borderColor: defaultTheme.palette.error.main,
            },
            '&:hover': {
              '& fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor: defaultTheme.palette.error.dark,
              },

              '&.MuiInputBase-root .adornment': {
                borderColor: defaultTheme.palette.error.dark,
              },
            },
            '&.Mui-focused': {
              backgroundColor: defaultTheme.palette.error.light,
              '& .adornment': {
                backgroundColor: defaultTheme.palette.error.light,
              },
            },
            '&.Mui-focused > fieldset': {
              boxShadow: shadows[5],
            },
          },
          input: {
            padding: 0,
            fontSize: typographyOptions.input2.fontSize,
            lineHeight: typographyOptions.input2.lineHeight,
            '&:-webkit-autofill, &:-webkit-autofill:hover , &:-webkit-autofill:focus':
              {
                WebkitTextFillColor: 'currentColor',
                WebkitBoxShadow: '0 0 0px 1000px transparent inset',
                transition: 'background-color 5000s ease-in-out 0s',
              },
            '::placeholder': {
              color: paletteOptions.text?.secondary,
              opacity: 1,
            },
          },
          '&.MuiInputBase-adornedEnd': {
            paddingRight: 24,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: '0 10px',
          '& .MuiOutlinedInput-inputAdornedEnd': {
            paddingRight: '24px',
          },
          '&.Mui-focused:has([aria-expanded="true"])': {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            backgroundColor: 'white',
            '& > fieldset': {
              borderWidth: '1px',
              borderBottomColor: 'transparent',
              boxShadow: shadows[3],
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '& .MuiPopover-paper': {
            background: defaultTheme.palette.background.paper,
            border: `1px solid ${paletteOptions.primary.main}`,
            boxShadow: shadows[3],
            zIndex: 20,
            borderRadius: '10px',
            '& li:hover': {
              backgroundColor: grey[50],
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiList-root': {
            padding: '8px 5px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: '6px',
          ...defaultTheme.typography.subtitle5,
          color: defaultTheme.palette.text.primary,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingLeft: '8px',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: { shrink: true },
      styleOverrides: {
        root: {
          ...defaultTheme.typography.subtitle5,
          color: defaultTheme.palette.text.primary,
          '&.Mui-disabled': {
            color: grey[100],
          },
          '&.Mui-error': {
            color: defaultTheme.palette.error.main,
            '&:hover': {
              color: defaultTheme.palette.error.main,
            },
            '&.Mui-focused': {
              color: defaultTheme.palette.error.main,
            },
          },
          [defaultTheme.breakpoints.up('xs')]: {
            position: 'initial',
            transform: 'initial',
            fontFeatureSettings: "'zero' on",
            color: paletteOptions.text.primary,
          },
          '&.Mui-focused': {
            color: paletteOptions.text.primary,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: defaultTheme.palette.text.secondary,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormHelperText-root': {
            marginLeft: 0,
            marginRight: 0,
          },
          '& .MuiInputBase-root': {
            paddingTop: 10,
            paddingBottom: 10,
            '& .MuiInputBase-input': {
              paddingTop: 0,
              paddingBottom: 0,
              fontWeight: 500,
              '&::placeholder': {
                fontWeight: 400,
              },
            },
          },
          input: {
            '&[readonly]': {
              WebkitTextFillColor: defaultTheme.palette.text.primary,
            },
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            color: defaultTheme.palette.common.black,
          },
          '&.active-more-modals-item  span': {
            color: defaultTheme.palette.common.white,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          display: 'block',

          '& .colored': {
            color: defaultTheme.palette.primary.dark,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-popupIndicatorOpen': {
            transform: 'rotateX(180deg)',
          },
          '&:focus-visible': {
            outline: 'none',
            boxShadow: shadows[6],
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-endAdornment': {
            perspective: '10000px',
          },
          '& .MuiAutocomplete-popupIndicator > .MuiSvgIcon-root > path': {
            fill: paletteOptions.primary.dark,
          },
          '& .MuiInputBase-root': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: '24px',
            '& .MuiAutocomplete-input': {
              padding: 0,
            },
          },
        },
        popper: {
          boxShadow: shadows[3],
          '& .MuiPaper-root': {
            marginTop: '1px',
            marginBottom: '1px',
            padding: defaultTheme.spacing(0.75),
            boxShadow: shadows[0],
            borderRadius: defaultTheme.spacing(1.25),
            '& ul': {
              padding: 0,
              paddingLeft: defaultTheme.spacing(0.75) + '!important',
              overflowX: 'hidden',
              overflowY: 'overlay',
              borderRadius: defaultTheme.spacing(1),
              '&::-webkit-scrollbar': {
                width: 6,
                height: 6,
                background: 'transparent',
              },
              '&::-webkit-scrollbar-track-piece': {
                marginTop: '5px',
                marginBottom: '5px',
              },
              '&::-webkit-scrollbar-thumb': {
                width: 6,
                height: 6,
                borderRadius: '100px',
                background: defaultTheme.palette.primary.dark,
                visibility: 'hidden',
              },
              '&:hover::-webkit-scrollbar-thumb': {
                visibility: 'visible',
                background: defaultTheme.palette.primary.dark,
                width: 6,
                height: 6,
              },
              '& li': {
                borderRadius: defaultTheme.spacing(1),
                border: '1px solid transparent',
                paddingLeft: '24px !important',
                paddingRight: '24px !important',
                paddingTop: '18px !important',
                paddingBottom: '18px !important',
                fontWeight: 500,
                fontSize: '18px',
                '&.Mui-focused, &:hover': {
                  background: deepBlue[50] + '!important',
                },
              },
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecorationColor: 'transparent',
          textDecorationThickness: 1,

          ':hover': {
            textDecorationColor: 'inherit',
          },
        },
      },
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiPaper: {
      styleOverrides: {
        root: {},
      },
    },
    MuiContainer: {
      styleOverrides: {
        disableGutters: {
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
        },
        root: {
          paddingRight: 24,
          paddingLeft: 24,

          [defaultTheme.breakpoints.between('sm', 'lg')]: {
            paddingRight: 40,
            paddingLeft: 40,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid',
          borderColor: grey[100],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingRight: '18px',
          paddingLeft: '18px',
          '&:first-of-type': {
            paddingLeft: '24px',
          },
          '&:last-of-type': {
            paddingRight: '24px',
            textAlign: 'end',
          },
        },
        head: {
          paddingTop: '12px',
          paddingBottom: '12px',
          ...typographyOptions.h5,
        },
        body: {
          paddingTop: '16px',
          paddingBottom: '16px',
          ...typographyOptions.body2,
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          backgroundColor: defaultTheme.palette.common.white,
          border: '1px solid',
          borderColor: defaultTheme.palette.primary.main,
          borderRadius: 8,
          fontSize: 12,
          fontWeight: 500,
          lineHeight: '18px',
          letterSpacing: '0.005em',
          textAlign: 'left',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: 0,
          backgroundColor: 'transparent',
          color: defaultTheme.palette.text.primary,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...typographyOptions.body10,
          marginBottom: '-2px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: defaultTheme.palette.primary.dark,
          backgroundColor: defaultTheme.palette.common.white,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: 'initial',
        },
        labelSmall: {
          fontSize: defaultTheme.typography.body15.fontSize,
          lineHeight: defaultTheme.typography.body15.lineHeight,
          [defaultTheme.breakpoints.up('md')]: {
            fontSize: defaultTheme.typography.body10.fontSize,
            lineHeight: defaultTheme.typography.body10.lineHeight,
          },
        },
        iconSmall: {
          fontSize: 12,
          [defaultTheme.breakpoints.up('md')]: {
            fontSize: 16,
          },
        },
        deleteIconSmall: {
          fontSize: 12,
          [defaultTheme.breakpoints.up('md')]: {
            fontSize: 16,
          },
        },
        colorSuccess: {
          borderColor: defaultTheme.palette.success.main,
          '& .MuiChip-deleteIcon': {
            color: defaultTheme.palette.success.main,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          width: 34,
          height: 34,
          borderRadius: 8,
        },
      },
    },
  },
})

export default function LandingThemeProvider({ children }: IProps) {
  return <ThemeProvider theme={theme} children={children} />
}
