import { Navigate, RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const PersonalAccountApplicationRoute: RouteObject = {
  lazy: async () => {
    const { LoaderOrPersonalApplicationLayout } = await import('./ui')

    return { Component: LoaderOrPersonalApplicationLayout }
  },
  children: [
    {
      path: routeNamesPaths.personalApplication,
      element: <Navigate to={routeNamesPaths.paPersonalDetail} />,
    },
    {
      path: routeNamesPaths.paPersonalDetail,
      lazy: async () => {
        const { PersonalDetails } = await import('./ui')

        return { Component: PersonalDetails }
      },
    },
    {
      path: routeNamesPaths.paExpectedTurnover,
      lazy: async () => {
        const { ExpectedTurnover } = await import('./ui')

        return { Component: ExpectedTurnover }
      },
    },
    {
      path: routeNamesPaths.paIdentityDocument,
      lazy: async () => {
        const { IdentityDocument } = await import('./ui')

        return { Component: IdentityDocument }
      },
    },
    {
      path: routeNamesPaths.paHomeAddress,
      lazy: async () => {
        const { HomeAddress } = await import('./ui')

        return { Component: HomeAddress }
      },
    },
    {
      path: routeNamesPaths.paFinish,
      lazy: async () => {
        const { FinishPage } = await import('./ui')

        return { Component: FinishPage }
      },
    },
    {
      path: routeNamesPaths.paTerms,
      lazy: async () => {
        const { TermsAndConditions } = await import('./ui')

        return { Component: TermsAndConditions }
      },
    },
  ],
}
