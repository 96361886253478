import { RouteObject } from 'react-router-dom'

import { routeNamesPaths } from '@shared/constants'

export const ExchangeHistoryRoute: RouteObject = {
  path: routeNamesPaths.exchangeHistory,
  lazy: async () => {
    const { ExchangeHistory } = await import('./ui')

    return { Component: ExchangeHistory }
  },
}
