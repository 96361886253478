import { useState, KeyboardEvent, ChangeEvent } from 'react'

export function useFormAmountHelpers() {
  const [ctrlDown, setCtrlDown] = useState(false)

  const handleKeyUpAmount = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Control') {
      setCtrlDown(false)
    }
  }

  const handleKeyDownAmount = (e: KeyboardEvent<HTMLInputElement>) => {
    const regexNum = /\d/

    if (regexNum.test(e.key)) {
      return
    }

    if (e.key === 'Backspace') {
      return
    }

    if (e.key === 'Delete') {
      return
    }

    if (e.key === 'ArrowLeft') {
      return
    }

    if (e.key === 'ArrowRight') {
      return
    }

    if (e.key === '.') {
      return
    }

    if (e.key === ',') {
      return
    }

    if (e.key === 'Tab') {
      return
    }

    if (e.key === 'Control') {
      return setCtrlDown(true)
    }

    if (
      ctrlDown &&
      (e.key === 'v' ||
        e.key === 'V' ||
        e.key === 'c' ||
        e.key === 'C' ||
        e.key === 'a' ||
        e.key === 'A' ||
        e.key === 'x' ||
        e.key === 'X')
    ) {
      return
    }

    e.preventDefault()
  }

  const handleChangeAmount = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChangeCallback: (value: string) => void,
    toFixed = 2
  ) => {
    const regex = new RegExp(`^\\d{0,10}(\\.\\d{0,${toFixed}})?$`)
    // const regex = /^\d{0,5}(\.\d{0,2})?$/
    let { value } = e.target

    value = value.replaceAll(',', '.')

    if (regex.test(value) || value === '') {
      if (value[0] === '.') {
        value = '0' + value
      }

      if (
        value[0] === '0' &&
        value[1] !== undefined &&
        value[1] !== '.' &&
        value[1] !== ','
      ) {
        if (/\d/.test(value[1])) {
          value = value[1]
        } else {
          value = '0'
        }
      }

      onChangeCallback(value)
    }
  }

  return {
    handleKeyUpAmount,
    handleKeyDownAmount,
    handleChangeAmount,
  }
}
