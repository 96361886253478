import { styled } from '@mui/material'

import { modalContentWidth } from '../../constants'

export const SliderWrapper = styled('div')(() => ({
  width: modalContentWidth,
  height: '100%',
  margin: '0 auto',
  '& .splide__track': {
    paddingBottom: '8px',
  },
  '& .splide__slide.is-visible': {
    margin: '3px',
    marginRight: 0,
  },
}))
