import { InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { TCryptoCoinKey } from '@shared/constants'
import { useFormAmountHelpers } from '@shared/hooks'
import { CryptoCoinIcon } from '@shared/ui'

import { PercentageFromWalletAmount } from '../components'

interface IAmountWithPercentageSelectable {
  errorMessage?: string
  value: string
  onChange: (value: string | number) => void
  name?: TCryptoCoinKey
  fee?: string
  generalWalletSum?: string | number
  withEndIcon?: boolean
}

export const AmountWithPercentageSelectable = forwardRef<
  HTMLInputElement,
  IAmountWithPercentageSelectable
>(function AmountWithPercentageSelectable(
  {
    errorMessage,
    value,
    onChange,
    name,
    fee,
    generalWalletSum,
    withEndIcon = true,
  },
  ref
) {
  const { handleKeyDownAmount, handleChangeAmount } = useFormAmountHelpers()
  const { t } = useTranslation('shared')

  const getPercentageValue = (percentage: number) => {
    if (percentage === 100) {
      return onChange(generalWalletSum || 0)
    }

    return onChange(((+(generalWalletSum || 0) * percentage) / 100).toFixed(2))
  }

  return (
    <>
      <TextField
        value={value}
        inputRef={ref}
        sx={{ mb: errorMessage ? 0 : 0.5 }}
        label={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <>{t('withdraw-fiat.Amount')}</>

            <PercentageFromWalletAmount
              error={!!errorMessage}
              setChangeCurrPercentage={(percentage) =>
                getPercentageValue(percentage)
              }
            />
          </Stack>
        }
        InputProps={{
          endAdornment: (
            <>
              {withEndIcon && (
                <InputAdornment position={'end'}>
                  <CryptoCoinIcon name={name} error={!!errorMessage} />
                </InputAdornment>
              )}
            </>
          ),
        }}
        type={'tel'}
        error={!!errorMessage}
        helperText={errorMessage}
        onKeyDown={handleKeyDownAmount}
        onChange={(e) => {
          handleChangeAmount(e, (value) => onChange(value), 6)
        }}
      />

      {fee && (
        <Stack
          direction={'row'}
          gap={0.75}
          alignItems={'center'}
          justifyContent={'flex-end'}
        >
          <Typography variant={'body3'} color={'text'}>
            {t('withdraw-fiat.network-fee')}
          </Typography>

          <Typography variant={'body2'} color={'text'}>
            {fee}
          </Typography>
        </Stack>
      )}
    </>
  )
})
