import { LinearProgress, linearProgressClasses } from '@mui/material'
import { styled } from '@mui/material'

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  flexGrow: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 5,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#008FCC',
  },
}))
