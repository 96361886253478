import { Chip, styled } from '@mui/material'

export const StyledChip = styled(Chip)(({ theme, color }) => ({
  marginLeft: 'auto',
  padding: '0 8px',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  backgroundColor: 'transparent',

  ...(color === 'warning' && {
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
  }),

  '& .MuiChip-label': {
    padding: 0,
  },
  '& .MuiChip-icon': {
    marginLeft: 0,
  },
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    top: 8,
    right: 12,
  },
}))
