import { Typography } from '@mui/material'

import { IMenuItem } from '../../../model'

import { IconButtonWrapper, UserMenuButton } from './styled'

interface IProps extends IMenuItem {
  handleClose: () => void
}

export function UserMenuItem({
  handleClose,
  icon: Icon,
  title,
  disableClose,
  href,
  action,
}: IProps) {
  const handleClick = () => {
    !disableClose && handleClose()

    action && action()
  }

  return (
    <UserMenuButton
      href={href}
      fullWidth
      onClick={handleClick}
      disableRipple={disableClose}
    >
      {Icon && (
        <IconButtonWrapper>
          <Icon />
        </IconButtonWrapper>
      )}

      <Typography variant="body4">{title}</Typography>
    </UserMenuButton>
  )
}
