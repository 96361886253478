import { Box, styled } from '@mui/material'

export const StyledFileUploadListItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  maxHeight: 38,
  height: 28,
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.standard,
  }),
}))
