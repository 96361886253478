import lodash from 'lodash'
import { Fragment, ReactNode } from 'react'

interface IProps<T> {
  collection: T[]
  iterate: (element: T, index: number, array?: T[]) => ReactNode
}

export function Map<T>({ collection, iterate }: IProps<T>) {
  return <Fragment>{lodash.map(collection, iterate)}</Fragment>
}
