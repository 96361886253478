import { Rocket } from './rocket'
import { SpaceBox, StarsBox } from './styled'

export function RocketInSpace() {
  return (
    <SpaceBox>
      <StarsBox />
      <Rocket />
    </SpaceBox>
  )
}
