export const blue = {
  50: '#E5F7FF',
  100: '#CCF0FF',
  200: '#99E0FF',
  300: '#66D1FF',
  400: '#33C2FF',
  500: '#009DE0',
  600: '#008FCC',
  700: '#006B99',
  800: '#004766',
  900: '#002433',
  950: '#002433',
}
