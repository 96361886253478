import { styled } from '@mui/material'

import { AttentionMessage } from '@shared/ui'

export const StyledAttentionMessage = styled(AttentionMessage)(({ theme }) => ({
  flexDirection: 'row-reverse',
  [theme.breakpoints.down('md')]: {
    display: 'none',
    position: 'absolute',
    top: 0,
    right: 20,
  },
}))
